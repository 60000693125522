import React from "react";
import {Space, TimePicker, Select, Tag} from 'antd';
// import { red } from '@ant-design/colors';
import dayjs from 'dayjs';
import Card from 'react-bootstrap/Card';

const { Option } = Select;

import MoneyLine from "../../Users/Profile/UserBot/MoneyLine";
import backend from "../../../chunks/backend";
import validation from "./modalValidation";
import PendingBetSportsComponent from "../../../components/PendingBetSportsComponent";
import RegularForkSportsComponent from "../../../components/RegularForkSportsComponent";
import WaitingForkSportsComponent from "../../../components/WaitingForkSportsComponent";
import MoneyLineOutcomesComponent from "../../../components/MoneyLineOutcomesComponent";
import TotalsOutcomesComponent from "../../../components/TotalsOutcomesComponent";
import IndTotalsOutcomesComponent from "../../../components/IndTotalsOutcomesComponent";
import HandicapsOutcomesComponent from "../../../components/HandicapsOutcomesComponent";
import WinnerOutcomesComponent from "../../../components/WinnerOutcomesComponent";
import AdditionalsOutcomesComponent from "../../../components/AdditionalsOutcomesComponent";
// const formatTime = 'HH:mm:ss';
const formatTime = 'HH';

class AddBotModal extends React.Component {
  constructor(props) {
    super(props);

    let betting_type = null;
    const mirror = (() => {
      const host = window.location.host;

      if (host === "eurobet.billy-lg.com") {
        return "https://eurobet.it";
      }
      else if (host === "parimatch.billy-lg.com") {
        return false;
      }
      else if (host === "billy-lg.com" || host === "bet365-point.billy-lg.com") {
        betting_type = "next_goal";
        return "https://bet365.com";
      }

      return "https://bet365.com";
    })();

    this.state = {
      name: {
        value: "",
        hasError: false,
      },
      description: {
        value: "",
        hasError: false,
      },
      usernameFirst: {
        value: "",
        hasError: false,
      },
      passwordFirst: {
        value: "",
        hasError: false,
      },
      usernameSecond: {
        value: "",
        hasError: false,
      },
      passwordSecond: {
        value: "",
        hasError: false,
      },

      proxyName1: {
        value: '',
        hasError: false,
      },
      proxyName2: {
        value: '',
        hasError: false,
      },
      proxyIp1: {
        value: '',
        hasError: false,
      },
      proxyIp2: {
        value: '',
        hasError: false,
      },
      proxyPort1: {
        value: '',
        hasError: false,
      },
      proxyPort2: {
        value: '',
        hasError: false,
      },
      proxyUsername1: {
        value: '',
        hasError: false,
      },
      proxyUsername2: {
        value: '',
        hasError: false,
      },
      proxyPassword1: {
        value: '',
        hasError: false,
      },
      proxyPassword2: {
        value: '',
        hasError: false,
      },
      colorClient: "#fafafa",
      proxyProtocol1: 'socks5',
      proxyProtocol2: 'socks5',
      tokenOctoBrowser: {
        value: '',
        hasError: false,
      },
      ipOctoBrowser: {
        value: '',
        hasError: false,
      },
      portOctoBrowser: {
        value: '58888',
        hasError: false,
      },

      apiKeyFirst: {
        value: "",
        hasError: false,
      },
      apiKeySecond: {
        value: "",
        hasError: false,
      },
      maxSumBets1: {
        value: "",
        hasError: false,
      },
      maxSumBets2: {
        value: "",
        hasError: false,
      },
      maxBalance1: {
        value: "",
        hasError: false,
      },
      maxBalance2: {
        value: "",
        hasError: false,
      },
      isBetsApi1: false,
      isBetsApi2: false,
      isProxy1: false,
      isProxy2: false,
      isIncludedOverUnderBasket: false,
      isOverUnderBasket: false,
      isNotPutUnder: false,
      isNotPutOver: false,
      isPutOnlyUnder: false,
      isPutOnlyOver: false,

      isEnableReplay: true,
      isAutologinFirst: false,
      isAutologinSecond: false,
      isAmericanCoef: false,
      isMirrorBets: false,
      isBetMaxAmount: false,
      isFirstBotStopTimeInterval: false,
      firstBotStopTimeInterval: ['', ''],
      isSecondBotStopTimeInterval: false,
      secondBotStopTimeInterval: ['', ''],

      isPutOneSecondShoulder: false,
      isNotOverlapSecondShoulder: false,
      isNotOverlapTwoShoulders: false,

      isDisableCornersFootball: false,
      isDisableOneHalfFootball: false,
      isPlaceOnlyPlusHandicaps: false,
      isDisableIntegerHandicap: false,
      isDisableQuarterHandicap: false,
      isOddsFirstBookOver: false,
      isOddsFirstBookUnder: false,
      isSandbox: false,
      bookmakerFirst: 'pinnacle',
      bookmakerSecond: 'pinnacle',
      currencyFirst: 'USD',
      currencySecond: 'USD',
      currencyTypeFirst: 'USDT',
      currencyTypeSecond: 'USDT',
      mirrorFirst: 'https://bet365.com',
      mirrorSecond: 'https://bet365.com',
      mirrorLottolandFirst: 'https://www.lottoland.co.uk',
      mirrorLottolandSecond: 'https://www.lottoland.co.uk',
      waitingForkSports: [],
      waitingForkSportsEmpty: false,
      pendingBetSports: [],
      pendingBetSportsEmpty: false,

      oneShoulderForkSports: [],
      oneShoulderForkSportsEmpty: false,
      moneyLineOutcomesSF: [],
      totalsOutcomesSF: [],
      indTotalsOutcomesSF: [],
      handicapsOutcomesSF: [],
      winnerOutcomesSF: [],
      additionalsOutcomesSF: [],
      maxNumberBetsOneTimeSF: {
        value: '1',
        hasError: false,
      },
      maxNumberBetsOneGameSF: {
        value: '2',
        hasError: false,
      },
      maxNumberBetsOneOutcomeSF: {
        value: '1',
        hasError: false,
      },
      // maxNumberSentSimilarForkSF: {
      //   value: '',
      //   hasError: false,
      // },
      minLifetimeForkSF: {
        value: '',
        hasError: false,
      },
      maxLifetimeForkSF: {
        value: '',
        hasError: false,
      },
      minPercentInitForkSF: {
        value: '',
        hasError: false,
      },
      maxPercentInitForkSF: {
        value: '',
        hasError: false,
      },
      minCoefOneShoulderFork: {
        value: '',
        hasError: false,
      },
      maxCoefOneShoulderFork: {
        value: '',
        hasError: false,
      },
      // moneyOneShoulderFork: {
      //   value: '',
      //   hasError: false,
      // },


      minPercentFork1SF: {
        value: '',
        hasError: false,
      },
      maxPercentFork1SF: {
        value: '',
        hasError: false,
      },
      minCoefFork1SF: {
        value: '',
        hasError: false,
      },
      maxCoefFork1SF: {
        value: '',
        hasError: false,
      },
      minPercentFork2SF: {
        value: '',
        hasError: false,
      },
      maxPercentFork2SF: {
        value: '',
        hasError: false,
      },
      minCoefFork2SF: {
        value: '',
        hasError: false,
      },
      maxCoefFork2SF: {
        value: '',
        hasError: false,
      },

      regularForkSports: [],
      regularForkSportsEmpty: false,
      moneyLineOutcomesRF: [],
      totalsOutcomesRF: [],
      indTotalsOutcomesRF: [],
      handicapsOutcomesRF: [],
      winnerOutcomesRF: [],
      additionalsOutcomesRF: [],
      maxNumberSentSimilarForkRF: {
        value: '',
        hasError: false,
      },
      minLifetimeForkRF: {
        value: '',
        hasError: false,
      },
      maxLifetimeForkRF: {
        value: '',
        hasError: false,
      },

      minCoefForkRF1: {
        value: '',
        hasError: false,
      },
      maxCoefForkRF1: {
        value: '',
        hasError: false,
      },

      minCoefForkRF2: {
        value: '',
        hasError: false,
      },
      maxCoefForkRF2: {
        value: '',
        hasError: false,
      },

      minCoefForkRF: {
        value: '',
        hasError: false,
      },
      maxCoefForkRF: {
        value: '',
        hasError: false,
      },

      minPercentInitForkRF: {
        value: '',
        hasError: false,
      },
      maxPercentInitForkRF: {
        value: '',
        hasError: false,
      },
      moneyRegularFork: {
        value: '',
        hasError: false,
      },
      maxTimeCloseFork: {
        value: '',
        hasError: false,
      },
      minRangePercentCloseForkRF: {
        value: '',
        hasError: false,
      },

      workStatus: "on work",
      moneyStatus: "",
      gameTime: 0,
      mirror,
      betting_type,
      global_money: {
        hasError: false,
        value: "",
      },
      rangeName: {
        hasError: false,
        value: "",
      },
      deposit1: 0,
      deposit2: 0,
      strategyName: '',
      flatName: '',
      flat: [],
      isFlat: false,
      strategyIsPlaceBet: true,
      betStrategies: [],
      strategyConditions: [],
      oneShoulderForksRangeName: {
        hasError: false,
        value: "",
      },
      oneShoulderForksRanges: [],
      oneShoulderForksOddsRanges: [],
      globalMoneyOSF: {
        hasError: false,
        value: "",
      },
      // oneShoulderForksOddsRanges: [{coef: "", money: "", hasError: false, hasCoefError: false, hasMoneyError: false}],
      ranges: [],
      oddsRanges: [],
      // oddsRanges: [{coef: "", money: "", hasError: false, hasCoefError: false, hasMoneyError: false}],
      sports: ["football-120"],
      sportsEmpty: false,
      requireSports: window.location.host !== "parimatch.billy-lg.com",
      strategy: "",
      strategies: [],
      hasStrategies: (() => {
        return ["bet365-point.billy-lg.com"].indexOf(window.location.host) !== -1;
      })(),

      minLifetimeFork: {
        value: '',
        hasError: false,
      },
      maxLifetimeFork: {
        value: '',
        hasError: false,
      },
      minCoefWaitingFork: {
        value: '',
        hasError: false,
      },
      maxCoefWaitingFork: {
        value: '',
        hasError: false,
      },
      minPercentForkWaitingFork: {
        value: '',
        hasError: false,
      },
      maxPercentForkWaitingFork: {
        value: '',
        hasError: false,
      },
      takeProfit: {
        value: '',
        hasError: false,
      },
      stopLoss: {
        value: '',
        hasError: false,
      },
      minusStepTrailingStop: {
        value: '',
        hasError: false,
      },
      plusStepTrailingStop: {
        value: '',
        hasError: false,
      },
      moneyWaitingFork: {
        value: '',
        hasError: false,
      },
      // minCoefficientInitFork: {
      //   value: '',
      //   hasError: false,
      // },
      // maxCoefficientInitFork: {
      //   value: '',
      //   hasError: false,
      // },
      minPercentInitFork: {
        value: '',
        hasError: false,
      },
      maxPercentInitFork: {
        value: '',
        hasError: false,
      },
      delayedBetTime: {
        value: '',
        hasError: false,
      },
      minPercentFinalFork: {
        value: '',
        hasError: false,
      },
      maxPercentFinalFork: {
        value: '',
        hasError: false,
      },
      maxNumberSentSimilarFork: {
        value: '',
        hasError: false,
      },
      delayBeforeSending: {
        value: '',
        hasError: false,
      },
      botType: 'regular_forks',
      singleExpressBetType: 'single_bet',
      moneyLineOutcomes: [],
      totalsOutcomes: [],
      indTotalsOutcomes: [],
      handicapsOutcomes: [],
      winnerOutcomes: [],
      additionalsOutcomes: [],
      isTrailingStop: false,
      withdrawnMoneySum1: 0,
      withdrawnMoneySum2: 0,
      lastBetTime1: "",
      lastBetTime2: "",
      lastFailedBetTime1: "",
      lastFailedBetTime2: "",
      numberFailedBets1: 0,
      numberFailedBets2: 0,
      lastErrorMessage1: "",
      lastErrorMessage2: "",
      lastErrorTime1: "",
      lastErrorTime2: "",
      balance1: 0,
      balance2: 0,
      sumBets1: 0,
      sumBets2: 0,
      cashout1: 0,
      cashout2: 0,
      // numberSuccessBets1: 0,
      // numberSuccessBets2: 0,
    };

    this.changeStrategyName = this.changeStrategyName.bind(this);
    this.changeFlatName = this.changeFlatName.bind(this);
    this.changeIsFlat = this.changeIsFlat.bind(this);
    this.setOneShoulderForkSports = this.setOneShoulderForkSports.bind(this);
    this.setMoneyLineOutcomesSF = this.setMoneyLineOutcomesSF.bind(this);
    this.setTotalsOutcomesSF = this.setTotalsOutcomesSF.bind(this);
    this.setIndTotalsOutcomesSF = this.setIndTotalsOutcomesSF.bind(this);
    this.setHandicapsOutcomesSF = this.setHandicapsOutcomesSF.bind(this);
    this.setWinnerOutcomesSF = this.setWinnerOutcomesSF.bind(this);
    this.setAdditionalsOutcomesSF = this.setAdditionalsOutcomesSF.bind(this);
    // this.changeMaxNumberSentSimilarForkSF = this.changeMaxNumberSentSimilarForkSF.bind(this);
    this.changeMaxNumberBetsOneTimeSF = this.changeMaxNumberBetsOneTimeSF.bind(this);
    this.changeMaxNumberBetsOneGameSF = this.changeMaxNumberBetsOneGameSF.bind(this);
    this.changeMaxNumberBetsOneOutcomeSF = this.changeMaxNumberBetsOneOutcomeSF.bind(this);
    this.changeMinLifetimeForkSF = this.changeMinLifetimeForkSF.bind(this);
    this.changeMaxLifetimeForkSF = this.changeMaxLifetimeForkSF.bind(this);
    this.changeMinPercentInitForkSF = this.changeMinPercentInitForkSF.bind(this);
    this.changeMaxPercentInitForkSF = this.changeMaxPercentInitForkSF.bind(this);
    this.changeMinCoefOneShoulderFork = this.changeMinCoefOneShoulderFork.bind(this);
    this.changeMaxCoefOneShoulderFork = this.changeMaxCoefOneShoulderFork.bind(this);
    // this.changeMoneyOneShoulderFork = this.changeMoneyOneShoulderFork.bind(this);
    this.changeMinPercentFork1SF = this.changeMinPercentFork1SF.bind(this);
    this.changeMaxPercentFork1SF = this.changeMaxPercentFork1SF.bind(this);
    this.changeMinCoefFork1SF = this.changeMinCoefFork1SF.bind(this);
    this.changeMaxCoefFork1SF = this.changeMaxCoefFork1SF.bind(this);
    this.changeMinPercentFork2SF = this.changeMinPercentFork2SF.bind(this);
    this.changeMaxPercentFork2SF = this.changeMaxPercentFork2SF.bind(this);
    this.changeMinCoefFork2SF = this.changeMinCoefFork2SF.bind(this);
    this.changeMaxCoefFork2SF = this.changeMaxCoefFork2SF.bind(this);

    this.setRegularForkSports = this.setRegularForkSports.bind(this);
    this.setMoneyLineOutcomesRF = this.setMoneyLineOutcomesRF.bind(this);
    this.setTotalsOutcomesRF = this.setTotalsOutcomesRF.bind(this);
    this.setIndTotalsOutcomesRF = this.setIndTotalsOutcomesRF.bind(this);
    this.setHandicapsOutcomesRF = this.setHandicapsOutcomesRF.bind(this);
    this.setWinnerOutcomesRF = this.setWinnerOutcomesRF.bind(this);
    this.setAdditionalsOutcomesRF = this.setAdditionalsOutcomesRF.bind(this);
    this.changeMaxNumberSentSimilarForkRF = this.changeMaxNumberSentSimilarForkRF.bind(this);
    this.changeMinLifetimeForkRF = this.changeMinLifetimeForkRF.bind(this);
    this.changeMaxLifetimeForkRF = this.changeMaxLifetimeForkRF.bind(this);

    this.changeMinCoefForkRF1 = this.changeMinCoefForkRF1.bind(this);
    this.changeMaxCoefForkRF1 = this.changeMaxCoefForkRF1.bind(this);
    this.changeMinCoefForkRF2 = this.changeMinCoefForkRF2.bind(this);
    this.changeMaxCoefForkRF2 = this.changeMaxCoefForkRF2.bind(this);

    this.changeMinCoefForkRF = this.changeMinCoefForkRF.bind(this);
    this.changeMaxCoefForkRF = this.changeMaxCoefForkRF.bind(this);

    this.changeMinPercentInitForkRF = this.changeMinPercentInitForkRF.bind(this);
    this.changeMaxPercentInitForkRF = this.changeMaxPercentInitForkRF.bind(this);
    this.changeMoneyRegularFork = this.changeMoneyRegularFork.bind(this);
    this.changeMaxTimeCloseFork = this.changeMaxTimeCloseFork.bind(this);
    this.changeMinRangePercentCloseForkRF = this.changeMinRangePercentCloseForkRF.bind(this);
    this.changeNameBot = this.changeNameBot.bind(this);
    this.changeUsernameFirst = this.changeUsernameFirst.bind(this);
    this.changePasswordFirst = this.changePasswordFirst.bind(this);
    this.changeUsernameSecond = this.changeUsernameSecond.bind(this);
    this.changePasswordSecond = this.changePasswordSecond.bind(this);
    this.changeIsBetsApi1 = this.changeIsBetsApi1.bind(this);
    this.changeIsBetsApi2 = this.changeIsBetsApi2.bind(this);
    this.changeIsProxy1 = this.changeIsProxy1.bind(this);
    this.changeIsProxy2 = this.changeIsProxy2.bind(this);
    this.changeIsEnableReplay = this.changeIsEnableReplay.bind(this);
    this.changeIsIncludedOverUnderBasket = this.changeIsIncludedOverUnderBasket.bind(this);
    this.changeIsOverUnderBasket = this.changeIsOverUnderBasket.bind(this);
    this.changeIsNotPutUnder = this.changeIsNotPutUnder.bind(this);
    this.changeIsNotPutOver = this.changeIsNotPutOver.bind(this);
    this.changeIsPutOnlyUnder = this.changeIsPutOnlyUnder.bind(this);
    this.changeIsPutOnlyOver = this.changeIsPutOnlyOver.bind(this);
    this.changeIsAutologinFirst = this.changeIsAutologinFirst.bind(this);
    this.changeIsAutologinSecond = this.changeIsAutologinSecond.bind(this);
    this.changeIsAmericanCoef = this.changeIsAmericanCoef.bind(this);
    this.changeIsMirrorBets = this.changeIsMirrorBets.bind(this);
    this.changeIsBetMaxAmount = this.changeIsBetMaxAmount.bind(this);
    this.changeIsFirstBotStopTimeInterval = this.changeIsFirstBotStopTimeInterval.bind(this);
    this.changeFirstBotStopTimeInterval = this.changeFirstBotStopTimeInterval.bind(this);
    this.changeIsSecondBotStopTimeInterval = this.changeIsSecondBotStopTimeInterval.bind(this);
    this.changeSecondBotStopTimeInterval = this.changeSecondBotStopTimeInterval.bind(this);
    this.changeIsPutOneSecondShoulder = this.changeIsPutOneSecondShoulder.bind(this);
    this.changeIsNotOverlapSecondShoulder = this.changeIsNotOverlapSecondShoulder.bind(this);
    this.changeIsNotOverlapTwoShoulders = this.changeIsNotOverlapTwoShoulders.bind(this);
    this.changeIsDisableCornersFootball = this.changeIsDisableCornersFootball.bind(this);
    this.changeIsDisableOneHalfFootball = this.changeIsDisableOneHalfFootball.bind(this);
    this.changeIsPlaceOnlyPlusHandicaps = this.changeIsPlaceOnlyPlusHandicaps.bind(this);
    this.changeIsDisableIntegerHandicap = this.changeIsDisableIntegerHandicap.bind(this);
    this.changeIsDisableQuarterHandicap = this.changeIsDisableQuarterHandicap.bind(this);
    this.changeIsOddsFirstBookOver = this.changeIsOddsFirstBookOver.bind(this);
    this.changeIsOddsFirstBookUnder = this.changeIsOddsFirstBookUnder.bind(this);
    this.changeBookmakerFirst = this.changeBookmakerFirst.bind(this);
    this.changeBookmakerSecond = this.changeBookmakerSecond.bind(this);
    this.changeMirrorFirst = this.changeMirrorFirst.bind(this);
    this.changeMirrorSecond = this.changeMirrorSecond.bind(this);
    this.changeMirrorLottolandFirst = this.changeMirrorLottolandFirst.bind(this);
    this.changeMirrorLottolandSecond = this.changeMirrorLottolandSecond.bind(this);
    this.setWaitingForkSports = this.setWaitingForkSports.bind(this);
    this.setPendingBetSports = this.setPendingBetSports.bind(this);
    this.changeApiKeyFirst = this.changeApiKeyFirst.bind(this);
    this.changeApiKeySecond = this.changeApiKeySecond.bind(this);
    this.changeCurrencyFirst = this.changeCurrencyFirst.bind(this);
    this.changeCurrencySecond = this.changeCurrencySecond.bind(this);
    this.changeCurrencyTypeFirst = this.changeCurrencyTypeFirst.bind(this);
    this.changeCurrencyTypeSecond = this.changeCurrencyTypeSecond.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeColor = this.changeColor.bind(this);

    this.changeProxyName1 = this.changeProxyName1.bind(this);
    this.changeProxyName2 = this.changeProxyName2.bind(this);
    this.changeProxyIp1 = this.changeProxyIp1.bind(this);
    this.changeProxyIp2 = this.changeProxyIp2.bind(this);
    this.changeProxyPort1 = this.changeProxyPort1.bind(this);
    this.changeProxyPort2 = this.changeProxyPort2.bind(this);
    this.changeProxyUsername1 = this.changeProxyUsername1.bind(this);
    this.changeProxyUsername2 = this.changeProxyUsername2.bind(this);
    this.changeProxyPassword1 = this.changeProxyPassword1.bind(this);
    this.changeProxyPassword2 = this.changeProxyPassword2.bind(this);
    this.changeProxyProtocol1 = this.changeProxyProtocol1.bind(this);
    this.changeProxyProtocol2 = this.changeProxyProtocol2.bind(this);
    this.changeTokenOctoBrowser = this.changeTokenOctoBrowser.bind(this);
    this.changeIpOctoBrowser = this.changeIpOctoBrowser.bind(this);
    this.changePortOctoBrowser = this.changePortOctoBrowser.bind(this);

    this.changeGlobalMoney = this.changeGlobalMoney.bind(this);
    this.changeMirror = this.changeMirror.bind(this);
    this.changeCoef = this.changeCoef.bind(this);
    this.changeMoney = this.changeMoney.bind(this);
    this.changeRangeName = this.changeRangeName.bind(this);
    this.addMoneyRange = this.addMoneyRange.bind(this);
    this.removeMoneyLinePosition = this.removeMoneyLinePosition.bind(this);
    this.changeOneShoulderForksRangeName = this.changeOneShoulderForksRangeName.bind(this);
    this.addMoneyRangeOSF = this.addMoneyRangeOSF.bind(this);
    this.removeMoneyLinePositionOSF = this.removeMoneyLinePositionOSF.bind(this);
    this.changeCoefOSF = this.changeCoefOSF.bind(this);
    this.changeMoneyOSF = this.changeMoneyOSF.bind(this);
    this.changeGlobalMoneyOSF = this.changeGlobalMoneyOSF.bind(this);
    this.sendCreateForm = this.sendCreateForm.bind(this);
    this.changeDeposit1 = this.changeDeposit1.bind(this);
    this.changeDeposit2 = this.changeDeposit2.bind(this);
    this.setSports = this.setSports.bind(this);
    this.changeStrategy = this.changeStrategy.bind(this);
    this.changeBetValue = this.changeBetValue.bind(this);
    this.changeDelayedBetTime = this.changeDelayedBetTime.bind(this);
    this.changeMinPercentFinalFork = this.changeMinPercentFinalFork.bind(this);
    this.changeMaxPercentFinalFork = this.changeMaxPercentFinalFork.bind(this);
    this.changeMinPercentInitFork = this.changeMinPercentInitFork.bind(this);
    this.changeMaxPercentInitFork = this.changeMaxPercentInitFork.bind(this);
    this.changeMinCoefWaitingFork = this.changeMinCoefWaitingFork.bind(this);
    this.changeMaxCoefWaitingFork = this.changeMaxCoefWaitingFork.bind(this);
    this.changeMinPercentForkWaitingFork = this.changeMinPercentForkWaitingFork.bind(this);
    this.changeMaxPercentForkWaitingFork = this.changeMaxPercentForkWaitingFork.bind(this);
    this.changeMoneyWaitingFork = this.changeMoneyWaitingFork.bind(this);
    // this.changeMinCoefficientInitFork = this.changeMinCoefficientInitFork.bind(this);
    // this.changeMaxCoefficientInitFork = this.changeMaxCoefficientInitFork.bind(this);
    this.changeMinLifetimeFork = this.changeMinLifetimeFork.bind(this);
    this.changeMaxLifetimeFork = this.changeMaxLifetimeFork.bind(this);
    this.changeMaxNumberSentSimilarFork = this.changeMaxNumberSentSimilarFork.bind(this);
    this.setMoneyLineOutcomes = this.setMoneyLineOutcomes.bind(this);
    this.setTotalsOutcomes = this.setTotalsOutcomes.bind(this);
    this.setIndTotalsOutcomes = this.setIndTotalsOutcomes.bind(this);
    this.setHandicapsOutcomes = this.setHandicapsOutcomes.bind(this);
    this.setWinnerOutcomes = this.setWinnerOutcomes.bind(this);
    this.setAdditionalsOutcomes = this.setAdditionalsOutcomes.bind(this);
    this.changeDelayBeforeSending = this.changeDelayBeforeSending.bind(this);
    this.changeBotType = this.changeBotType.bind(this);
    this.changeIsTrailingStop = this.changeIsTrailingStop.bind(this);
    this.changeTakeProfit = this.changeTakeProfit.bind(this);
    this.changeStopLoss = this.changeStopLoss.bind(this);
    this.changeMinusStepTrailingStop = this.changeMinusStepTrailingStop.bind(this);
    this.changePlusStepTrailingStop = this.changePlusStepTrailingStop.bind(this);
    this.changeMaxSumBets1 = this.changeMaxSumBets1.bind(this);
    this.changeMaxSumBets2 = this.changeMaxSumBets2.bind(this);
    this.changeMaxBalance1 = this.changeMaxBalance1.bind(this);
    this.changeMaxBalance2 = this.changeMaxBalance2.bind(this);
    this.changeSingleExpressBetType = this.changeSingleExpressBetType.bind(this);
  }

  changeStrategy(e) {
    this.setState({
      strategy: e.target.value.trim(),
    });
  }

  loadStrategies() {
    if (this.state.hasStrategies) {
      backend
        .get("/strategies")
        .then(({data}) => {
          if (data.success) {
            this.setState({
              strategies: data.strategies,
            });
          }
        })
        .catch(console.log);
    }
  }

  loadFlat() {
    backend
      .get("/flat")
      .then(({data}) => {
        if (data.success) {
          this.setState({flat: data.strategies});
        }
      })
      .catch(console.log);
  }

  loadBetStrategies() {
    backend
      .get("/bet-strategies")
      .then(({data}) => {
        if (data.success) {
          this.setState({betStrategies: data.strategies});
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.loadStrategies();
    this.loadBetStrategies();
    this.loadFlat();

    backend
      .get(`/money-ranges`)
      .then(({data}) => {
        if (data.success) {
          data.ranges.map((e) => {
            e.oddsRanges.map((i) => {
              i.hasError = false;
              i.hasCoefError = false;
              i.hasMoneyError = false;
              return i;
            });
            return e;
          });

          this.setState({ranges: data.ranges});
          this.setState({oneShoulderForksRanges: JSON.parse(JSON.stringify(data.ranges))});
        }
      })
      .catch(console.log)
      .finally(() => {
        this.setState({
          loaded: true,
        });
      });
  }

  addMoneyRange() {
    const oddsRanges = this.state.oddsRanges;
    oddsRanges.push({coef: "", money: "", hasError: false, hasCoefError: false, hasMoneyError: false});
    this.setState({oddsRanges});
  }

  removeMoneyLinePosition(e) {
    const {oddsRanges} = this.state;
    const i = e.target.getAttribute("data-index");
    oddsRanges.splice(i, 1);
    this.setState({oddsRanges});
  }

  addMoneyRangeOSF() {
    const oneShoulderForksOddsRanges = this.state.oneShoulderForksOddsRanges;
    oneShoulderForksOddsRanges.push({coef: "", money: "", hasError: false, hasCoefError: false, hasMoneyError: false});
    this.setState({oneShoulderForksOddsRanges});
  }

  removeMoneyLinePositionOSF(e) {
    const {oneShoulderForksOddsRanges} = this.state;
    const i = e.target.getAttribute("data-index");
    oneShoulderForksOddsRanges.splice(i, 1);
    this.setState({oneShoulderForksOddsRanges});
  }

  sendCreateForm(e) {
    e.preventDefault();

    const {
      name,
      usernameFirst,
      passwordFirst,
      usernameSecond,
      passwordSecond,
      apiKeyFirst,
      apiKeySecond,
      description,

      proxyName1,
      proxyName2,
      proxyIp1,
      proxyIp2,
      proxyPort1,
      proxyPort2,
      proxyUsername1,
      proxyUsername2,
      proxyPassword1,
      proxyPassword2,
      tokenOctoBrowser,
      ipOctoBrowser,
      portOctoBrowser,

      global_money,
      globalMoneyOSF,
      oddsRanges,
      oneShoulderForksOddsRanges,
      strategy,
      maxNumberSentSimilarFork,
      deposit1,
      deposit2,
      maxSumBets1,
      maxSumBets2,
      maxBalance1,
      maxBalance2,

      // maxNumberSentSimilarForkSF,
      maxNumberBetsOneGameSF,
      maxNumberBetsOneTimeSF,
      maxNumberBetsOneOutcomeSF,
      minLifetimeForkSF,
      maxLifetimeForkSF,
      minPercentInitForkSF,
      maxPercentInitForkSF,
      minCoefOneShoulderFork,
      maxCoefOneShoulderFork,
      // moneyOneShoulderFork,

      minPercentFork1SF,
      maxPercentFork1SF,
      minCoefFork1SF,
      maxCoefFork1SF,
      minPercentFork2SF,
      maxPercentFork2SF,
      minCoefFork2SF,
      maxCoefFork2SF,

      maxNumberSentSimilarForkRF,
      minLifetimeForkRF,
      maxLifetimeForkRF,

      minCoefForkRF1,
      maxCoefForkRF1,
      minCoefForkRF2,
      maxCoefForkRF2,

      // minCoefForkRF,
      // maxCoefForkRF,

      minPercentInitForkRF,
      maxPercentInitForkRF,
      moneyRegularFork,
      maxTimeCloseFork,
      minRangePercentCloseForkRF,

      delayBeforeSending,
      minLifetimeFork,
      maxLifetimeFork,
      minCoefWaitingFork,
      maxCoefWaitingFork,
      minPercentForkWaitingFork,
      maxPercentForkWaitingFork,
      takeProfit,
      stopLoss,
      minusStepTrailingStop,
      plusStepTrailingStop,
      moneyWaitingFork,
      // minCoefficientInitFork,
      // maxCoefficientInitFork,
      minPercentInitFork,
      maxPercentInitFork,
      delayedBetTime,
      minPercentFinalFork,
      maxPercentFinalFork
    } = this.state;

    let formWithErrors = false;
    let waitingForkSportsEmpty = this.state.waitingForkSportsEmpty;
    let pendingBetSportsEmpty = this.state.pendingBetSportsEmpty;
    let regularForkSportsEmpty = this.state.regularForkSportsEmpty;
    let oneShoulderForkSportsEmpty = this.state.oneShoulderForkSportsEmpty;

    if (!validation.name(name.value)) {
      name.hasError = true;
      formWithErrors = true;
    }
    if (!validation.description(description.value)) {
      description.hasError = true;
      formWithErrors = true;
    }
    if (!validation.globalMoney(maxSumBets1.value)) {
      maxSumBets1.hasError = true;
      formWithErrors = true;
    }
    if (!validation.globalMoney(maxSumBets2.value)) {
      maxSumBets2.hasError = true;
      formWithErrors = true;
    }
    if (!validation.globalMoney(maxBalance1.value)) {
      maxBalance1.hasError = true;
      formWithErrors = true;
    }
    if (!validation.globalMoney(maxBalance2.value)) {
      maxBalance2.hasError = true;
      formWithErrors = true;
    }

    // if (!validation.description(proxyName1.value)) {
    //   proxyName1.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyName2.value)) {
    //   proxyName2.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyIp1.value)) {
    //   proxyIp1.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyIp2.value)) {
    //   proxyIp2.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyPort1.value)) {
    //   proxyPort1.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyPort2.value)) {
    //   proxyPort2.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyUsername1.value)) {
    //   proxyUsername1.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyUsername2.value)) {
    //   proxyUsername2.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyPassword1.value)) {
    //   proxyPassword1.hasError = true;
    //   formWithErrors = true;
    // }
    // if (!validation.description(proxyPassword2.value)) {
    //   proxyPassword2.hasError = true;
    //   formWithErrors = true;
    // }

    if (!validation.description(tokenOctoBrowser.value)) {
      tokenOctoBrowser.hasError = true;
      formWithErrors = true;
    }
    if (!validation.description(ipOctoBrowser.value)) {
      ipOctoBrowser.hasError = true;
      formWithErrors = true;
    }
    if (!validation.description(portOctoBrowser.value)) {
      portOctoBrowser.hasError = true;
      formWithErrors = true;
    }

    if (!validation.globalMoney(delayBeforeSending.value)) {
      delayBeforeSending.hasError = true;
      formWithErrors = true;
    }

    if (this.state.bookmakerFirst === 'cloudbet' || this.state.bookmakerFirst === '1win') {
      if (!validation.description(apiKeyFirst.value)) {
        apiKeyFirst.hasError = true;
        formWithErrors = true;
      }
    }
    else {
      if (!validation.name(usernameFirst.value)) {
        usernameFirst.hasError = true;
        formWithErrors = true;
      }
      if (!validation.password(passwordFirst.value)) {
        passwordFirst.hasError = true;
        formWithErrors = true;
      }
    }

    if (this.state.bookmakerSecond === 'cloudbet') {
      if (!validation.description(apiKeySecond.value)) {
        apiKeySecond.hasError = true;
        formWithErrors = true;
      }
    }
    else {
      if (!validation.name(usernameSecond.value)) {
        usernameSecond.hasError = true;
        formWithErrors = true;
      }
      if (!validation.password(passwordSecond.value)) {
        passwordSecond.hasError = true;
        formWithErrors = true;
      }
    }

    if (this.state.bookmakerFirst !== 'cloudbet') {
      apiKeyFirst.hasError = false;
    }

    if (this.state.bookmakerSecond !== 'cloudbet') {
      apiKeySecond.hasError = false;
    }


    if (this.state.botType === 'one_shoulder_forks') {
      waitingForkSportsEmpty = false;
      minPercentForkWaitingFork.hasError = false;
      maxPercentForkWaitingFork.hasError = false;
      minCoefWaitingFork.hasError = false;
      maxCoefWaitingFork.hasError = false;
      moneyWaitingFork.hasError = false;
      takeProfit.hasError = false;
      stopLoss.hasError = false;
      minusStepTrailingStop.hasError = false;
      plusStepTrailingStop.hasError = false;

      pendingBetSportsEmpty = false;
      delayedBetTime.hasError = false;
      maxNumberSentSimilarFork.hasError = false;
      minLifetimeFork.hasError = false;
      maxLifetimeFork.hasError = false;
      // minCoefficientInitFork.hasError = false;
      // maxCoefficientInitFork.hasError = false;
      minPercentFinalFork.hasError = false;
      maxPercentFinalFork.hasError = false;
      global_money.hasError = false;
      minPercentInitFork.hasError = false;
      maxPercentInitFork.hasError = false;

      regularForkSportsEmpty = false;
      // maxNumberSentSimilarForkRF.hasError = false;
      minLifetimeForkRF.hasError = false;
      maxLifetimeForkRF.hasError = false;

      minCoefForkRF1.hasError = false;
      maxCoefForkRF1.hasError = false;
      minCoefForkRF2.hasError = false;
      maxCoefForkRF2.hasError = false;

      minCoefForkRF.hasError = false;
      maxCoefForkRF.hasError = false;

      minPercentInitForkRF.hasError = false;
      maxPercentInitForkRF.hasError = false;
      moneyRegularFork.hasError = false;
      maxTimeCloseFork.hasError = false;
      minRangePercentCloseForkRF.hasError = false;

      if (this.state.oneShoulderForkSports.length === 0) {
        formWithErrors = true;
        oneShoulderForkSportsEmpty = true;
      }
      // if (!validation.globalMoney(maxNumberSentSimilarForkSF.value)) {
      //   maxNumberSentSimilarForkSF.hasError = true;
      //   formWithErrors = true;
      // }
      // if (!validation.globalMoney(maxNumberBetsOneGameSF.value)) {
      //   maxNumberBetsOneGameSF.hasError = true;
      //   formWithErrors = true;
      // }
      // if (!validation.globalMoney(maxNumberBetsOneOutcomeSF.value)) {
      //   maxNumberBetsOneOutcomeSF.hasError = true;
      //   formWithErrors = true;
      // }
      if (!validation.globalMoney(minLifetimeForkSF.value)) {
        minLifetimeForkSF.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxLifetimeForkSF.value)) {
        maxLifetimeForkSF.hasError = true;
        formWithErrors = true;
      }

      if (this.state.singleExpressBetType === 'single_bet') {
        if (!validation.description(minPercentInitForkSF.value)) {
          minPercentInitForkSF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxPercentInitForkSF.value)) {
          maxPercentInitForkSF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(minCoefOneShoulderFork.value)) {
          minCoefOneShoulderFork.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxCoefOneShoulderFork.value)) {
          maxCoefOneShoulderFork.hasError = true;
          formWithErrors = true;
        }

        minPercentFork1SF.hasError = false;
        maxPercentFork1SF.hasError = false;
        minCoefFork1SF.hasError = false;
        maxCoefFork1SF.hasError = false;
        minPercentFork2SF.hasError = false;
        maxPercentFork2SF.hasError = false;
        minCoefFork2SF.hasError = false;
        maxCoefFork2SF.hasError = false;

      }
      else if (this.state.singleExpressBetType === 'single_express_bet') {
        if (!validation.description(minPercentFork1SF.value)) {
          minPercentFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxPercentFork1SF.value)) {
          maxPercentFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(minCoefFork1SF.value)) {
          minCoefFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxCoefFork1SF.value)) {
          maxCoefFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.description(minPercentFork2SF.value)) {
          minPercentFork2SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxPercentFork2SF.value)) {
          maxPercentFork2SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(minCoefFork2SF.value)) {
          minCoefFork2SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxCoefFork2SF.value)) {
          maxCoefFork2SF.hasError = true;
          formWithErrors = true;
        }


        minPercentInitForkSF.hasError = false;
        maxPercentInitForkSF.hasError = false;
        minCoefOneShoulderFork.hasError = false;
        maxCoefOneShoulderFork.hasError = false;

      }
      else if (this.state.singleExpressBetType === 'express_bet') {
        if (!validation.description(minPercentFork1SF.value)) {
          minPercentFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxPercentFork1SF.value)) {
          maxPercentFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(minCoefFork1SF.value)) {
          minCoefFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxCoefFork1SF.value)) {
          maxCoefFork1SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.description(minPercentFork2SF.value)) {
          minPercentFork2SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxPercentFork2SF.value)) {
          maxPercentFork2SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(minCoefFork2SF.value)) {
          minCoefFork2SF.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(maxCoefFork2SF.value)) {
          maxCoefFork2SF.hasError = true;
          formWithErrors = true;
        }

        minPercentInitForkSF.hasError = false;
        maxPercentInitForkSF.hasError = false;
        minCoefOneShoulderFork.hasError = false;
        maxCoefOneShoulderFork.hasError = false;
      }

      if (this.state.isFlat) {
        globalMoneyOSF.hasError = false;
      }
      else {
        if (!validation.globalMoney(globalMoneyOSF.value)) {
          globalMoneyOSF.hasError = true;
          formWithErrors = true;
        }
      }

      for (let i = 0; i < oneShoulderForksOddsRanges.length; i -= -1) {
        const oddsRange = oneShoulderForksOddsRanges[i];

        if (oddsRange.coef.trim() === "" && oddsRange.money.trim() === "") {
          continue;
        }

        if (
          (oddsRange.coef.trim() === "" && oddsRange.money.trim() !== "") ||
          (oddsRange.coef.trim() !== "" && oddsRange.money.trim() === "")
        ) {
          oddsRange.hasError = true;
        }

        if (!validation.oddsRanges.coef(oddsRange.coef)) {
          oddsRange.hasError = true;
          oddsRange.hasCoefError = true;
        }

        if (!validation.oddsRanges.range(oddsRange.money)) {
          oddsRange.hasError = true;
          oddsRange.hasMoneyError = true;
        }

        if (oddsRange.hasError) {
          oneShoulderForksOddsRanges[i] = oddsRange;
        }
      }

    }


    if (this.state.botType === 'regular_forks') {
      waitingForkSportsEmpty = false;
      minPercentForkWaitingFork.hasError = false;
      maxPercentForkWaitingFork.hasError = false;
      minCoefWaitingFork.hasError = false;
      maxCoefWaitingFork.hasError = false;
      moneyWaitingFork.hasError = false;
      takeProfit.hasError = false;
      stopLoss.hasError = false;
      minusStepTrailingStop.hasError = false;
      plusStepTrailingStop.hasError = false;

      pendingBetSportsEmpty = false;
      delayedBetTime.hasError = false;
      maxNumberSentSimilarFork.hasError = false;
      minLifetimeFork.hasError = false;
      maxLifetimeFork.hasError = false;
      // minCoefficientInitFork.hasError = false;
      // maxCoefficientInitFork.hasError = false;
      minPercentFinalFork.hasError = false;
      maxPercentFinalFork.hasError = false;
      global_money.hasError = false;
      minPercentInitFork.hasError = false;
      maxPercentInitFork.hasError = false;

      oneShoulderForkSportsEmpty = false;
      // maxNumberSentSimilarForkSF.hasError = false;
      // maxNumberBetsOneGameSF.hasError = false;
      // maxNumberBetsOneOutcomeSF.hasError = false;
      minLifetimeForkSF.hasError = false;
      maxLifetimeForkSF.hasError = false;
      minPercentInitForkSF.hasError = false;
      maxPercentInitForkSF.hasError = false;
      minCoefOneShoulderFork.hasError = false;
      maxCoefOneShoulderFork.hasError = false;
      // moneyOneShoulderFork.hasError = false;
      globalMoneyOSF.hasError = false;

      minPercentFork1SF.hasError = false;
      maxPercentFork1SF.hasError = false;
      minCoefFork1SF.hasError = false;
      maxCoefFork1SF.hasError = false;
      minPercentFork2SF.hasError = false;
      maxPercentFork2SF.hasError = false;
      minCoefFork2SF.hasError = false;
      maxCoefFork2SF.hasError = false;

      if (this.state.regularForkSports.length === 0) {
        formWithErrors = true;
        regularForkSportsEmpty = true;
      }
      if (!validation.globalMoney(maxNumberBetsOneTimeSF.value)) {
        maxNumberBetsOneTimeSF.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxNumberBetsOneGameSF.value)) {
        maxNumberBetsOneGameSF.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxNumberBetsOneOutcomeSF.value)) {
        maxNumberBetsOneOutcomeSF.hasError = true;
        formWithErrors = true;
      }

      // if (!validation.globalMoney(maxNumberSentSimilarForkRF.value)) {
      //   maxNumberSentSimilarForkRF.hasError = true;
      //   formWithErrors = true;
      // }
      if (!validation.globalMoney(minLifetimeForkRF.value)) {
        minLifetimeForkRF.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxLifetimeForkRF.value)) {
        maxLifetimeForkRF.hasError = true;
        formWithErrors = true;
      }

      if (!validation.description(minCoefForkRF1.value)) {
        minCoefForkRF1.hasError = true;
        formWithErrors = true;
      }
      if (!validation.description(maxCoefForkRF1.value)) {
        maxCoefForkRF1.hasError = true;
        formWithErrors = true;
      }
      if (!validation.description(minCoefForkRF2.value)) {
        minCoefForkRF2.hasError = true;
        formWithErrors = true;
      }
      if (!validation.description(maxCoefForkRF2.value)) {
        maxCoefForkRF2.hasError = true;
        formWithErrors = true;
      }

      // if (!validation.description(minCoefForkRF.value)) {
      //   minCoefForkRF.hasError = true;
      //   formWithErrors = true;
      // }
      // if (!validation.description(maxCoefForkRF.value)) {
      //   maxCoefForkRF.hasError = true;
      //   formWithErrors = true;
      // }

      if (!validation.description(minPercentInitForkRF.value)) {
        minPercentInitForkRF.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxPercentInitForkRF.value)) {
        maxPercentInitForkRF.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(moneyRegularFork.value)) {
        moneyRegularFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxTimeCloseFork.value)) {
        maxTimeCloseFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.description(minRangePercentCloseForkRF.value)) {
        minRangePercentCloseForkRF.hasError = true;
        formWithErrors = true;
      }
    }


    if (this.state.botType === 'pending_bets') {
      waitingForkSportsEmpty = false;
      minPercentForkWaitingFork.hasError = false;
      maxPercentForkWaitingFork.hasError = false;
      minCoefWaitingFork.hasError = false;
      maxCoefWaitingFork.hasError = false;
      moneyWaitingFork.hasError = false;
      takeProfit.hasError = false;
      stopLoss.hasError = false;
      minusStepTrailingStop.hasError = false;
      plusStepTrailingStop.hasError = false;

      regularForkSportsEmpty = false;
      // maxNumberSentSimilarForkRF.hasError = false;
      minLifetimeForkRF.hasError = false;
      maxLifetimeForkRF.hasError = false;

      minCoefForkRF1.hasError = false;
      maxCoefForkRF1.hasError = false;
      minCoefForkRF2.hasError = false;
      maxCoefForkRF2.hasError = false;

      minCoefForkRF.hasError = false;
      maxCoefForkRF.hasError = false;

      minPercentInitForkRF.hasError = false;
      maxPercentInitForkRF.hasError = false;
      moneyRegularFork.hasError = false;
      maxTimeCloseFork.hasError = false;
      minRangePercentCloseForkRF.hasError = false;

      oneShoulderForkSportsEmpty = false;
      // maxNumberSentSimilarForkSF.hasError = false;
      maxNumberBetsOneTimeSF.hasError = false;
      maxNumberBetsOneGameSF.hasError = false;
      maxNumberBetsOneOutcomeSF.hasError = false;
      minLifetimeForkSF.hasError = false;
      maxLifetimeForkSF.hasError = false;
      minPercentInitForkSF.hasError = false;
      maxPercentInitForkSF.hasError = false;
      minCoefOneShoulderFork.hasError = false;
      maxCoefOneShoulderFork.hasError = false;
      // moneyOneShoulderFork.hasError = false;
      globalMoneyOSF.hasError = false;

      minPercentFork1SF.hasError = false;
      maxPercentFork1SF.hasError = false;
      minCoefFork1SF.hasError = false;
      maxCoefFork1SF.hasError = false;
      minPercentFork2SF.hasError = false;
      maxPercentFork2SF.hasError = false;
      minCoefFork2SF.hasError = false;
      maxCoefFork2SF.hasError = false;

      if (this.state.pendingBetSports.length === 0) {
        formWithErrors = true;
        pendingBetSportsEmpty = true;
      }
      if (!validation.globalMoney(delayedBetTime.value)) {
        delayedBetTime.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxNumberSentSimilarFork.value)) {
        maxNumberSentSimilarFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(minLifetimeFork.value)) {
        minLifetimeFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxLifetimeFork.value)) {
        maxLifetimeFork.hasError = true;
        formWithErrors = true;
      }
      // if (!validation.globalMoney(minCoefficientInitFork.value)) {
      //   console.log('6 minCoefficientInitFork --- ', minCoefficientInitFork.value)
      //   minCoefficientInitFork.hasError = true;
      //   formWithErrors = true;
      // }
      // if (!validation.globalMoney(maxCoefficientInitFork.value)) {
      //   console.log('7 maxCoefficientInitFork --- ', maxCoefficientInitFork.value)
      //   maxCoefficientInitFork.hasError = true;
      //   formWithErrors = true;
      // }
      if (!validation.globalMoney(minPercentFinalFork.value)) {
        minPercentFinalFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxPercentFinalFork.value)) {
        maxPercentFinalFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(global_money.value)) {
        global_money.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(minPercentInitFork.value)) {
        minPercentInitFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxPercentInitFork.value)) {
        maxPercentInitFork.hasError = true;
        formWithErrors = true;
      }

      for (let i = 0; i < oddsRanges.length; i -= -1) {
        const oddsRange = oddsRanges[i];

        if (oddsRange.coef.trim() === "" && oddsRange.money.trim() === "") {
          continue;
        }

        if (
          (oddsRange.coef.trim() === "" && oddsRange.money.trim() !== "") ||
          (oddsRange.coef.trim() !== "" && oddsRange.money.trim() === "")
        ) {
          oddsRange.hasError = true;
        }

        if (!validation.oddsRanges.coef(oddsRange.coef)) {
          oddsRange.hasError = true;
          oddsRange.hasCoefError = true;
        }

        if (!validation.oddsRanges.range(oddsRange.money)) {
          oddsRange.hasError = true;
          oddsRange.hasMoneyError = true;
        }

        if (oddsRange.hasError) {
          oddsRanges[i] = oddsRange;
        }
      }
    }


    if (this.state.botType === 'waiting_forks') {
      regularForkSportsEmpty = false;
      // maxNumberSentSimilarForkRF.hasError = false;
      minLifetimeForkRF.hasError = false;
      maxLifetimeForkRF.hasError = false;

      minCoefForkRF1.hasError = false;
      maxCoefForkRF1.hasError = false;
      minCoefForkRF2.hasError = false;
      maxCoefForkRF2.hasError = false;

      minCoefForkRF.hasError = false;
      maxCoefForkRF.hasError = false;

      minPercentInitForkRF.hasError = false;
      maxPercentInitForkRF.hasError = false;
      moneyRegularFork.hasError = false;
      maxTimeCloseFork.hasError = false;
      minRangePercentCloseForkRF.hasError = false;

      pendingBetSportsEmpty = false;
      delayedBetTime.hasError = false;
      maxNumberSentSimilarFork.hasError = false;
      minLifetimeFork.hasError = false;
      maxLifetimeFork.hasError = false;
      // minCoefficientInitFork.hasError = false;
      // maxCoefficientInitFork.hasError = false;
      minPercentFinalFork.hasError = false;
      maxPercentFinalFork.hasError = false;
      global_money.hasError = false;
      minPercentInitFork.hasError = false;
      maxPercentInitFork.hasError = false;

      oneShoulderForkSportsEmpty = false;
      // maxNumberSentSimilarForkSF.hasError = false;
      maxNumberBetsOneTimeSF.hasError = false;
      maxNumberBetsOneGameSF.hasError = false;
      maxNumberBetsOneOutcomeSF.hasError = false;
      minLifetimeForkSF.hasError = false;
      maxLifetimeForkSF.hasError = false;
      minPercentInitForkSF.hasError = false;
      maxPercentInitForkSF.hasError = false;
      minCoefOneShoulderFork.hasError = false;
      maxCoefOneShoulderFork.hasError = false;
      // moneyOneShoulderFork.hasError = false;
      globalMoneyOSF.hasError = false;

      minPercentFork1SF.hasError = false;
      maxPercentFork1SF.hasError = false;
      minCoefFork1SF.hasError = false;
      maxCoefFork1SF.hasError = false;
      minPercentFork2SF.hasError = false;
      maxPercentFork2SF.hasError = false;
      minCoefFork2SF.hasError = false;
      maxCoefFork2SF.hasError = false;

      if (this.state.waitingForkSports.length === 0) {
        formWithErrors = true;
        waitingForkSportsEmpty = true;
      }
      if (!validation.description(minPercentForkWaitingFork.value)) {
        minPercentForkWaitingFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxPercentForkWaitingFork.value)) {
        maxPercentForkWaitingFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(minCoefWaitingFork.value)) {
        minCoefWaitingFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(maxCoefWaitingFork.value)) {
        maxCoefWaitingFork.hasError = true;
        formWithErrors = true;
      }
      if (!validation.globalMoney(moneyWaitingFork.value)) {
        moneyWaitingFork.hasError = true;
        formWithErrors = true;
      }
      if (this.state.isTrailingStop) {
        if (!validation.globalMoney(minusStepTrailingStop.value)) {
          minusStepTrailingStop.hasError = true;
          formWithErrors = true;
        }
        if (!validation.globalMoney(plusStepTrailingStop.value)) {
          plusStepTrailingStop.hasError = true;
          formWithErrors = true;
        }
        takeProfit.hasError = false;
        stopLoss.hasError = false;
      }
      else {
        if (!validation.globalMoney(takeProfit.value)) {
          takeProfit.hasError = true;
          formWithErrors = true;
        }
        if (!validation.description(stopLoss.value)) {
          stopLoss.hasError = true;
          formWithErrors = true;
        }
        minusStepTrailingStop.hasError = false;
        plusStepTrailingStop.hasError = false;
      }
    }


    if (formWithErrors) {
      this.setState({
        name,
        usernameFirst,
        passwordFirst,
        usernameSecond,
        passwordSecond,
        waitingForkSportsEmpty,
        pendingBetSportsEmpty,
        maxSumBets1,
        maxSumBets2,
        maxBalance1,
        maxBalance2,

        oneShoulderForkSportsEmpty,
        // maxNumberSentSimilarForkSF,
        maxNumberBetsOneTimeSF,
        maxNumberBetsOneGameSF,
        maxNumberBetsOneOutcomeSF,
        minLifetimeForkSF,
        maxLifetimeForkSF,
        minPercentInitForkSF,
        maxPercentInitForkSF,
        minCoefOneShoulderFork,
        maxCoefOneShoulderFork,
        // moneyOneShoulderFork,

        minPercentFork1SF,
        maxPercentFork1SF,
        minCoefFork1SF,
        maxCoefFork1SF,
        minPercentFork2SF,
        maxPercentFork2SF,
        minCoefFork2SF,
        maxCoefFork2SF,

        regularForkSportsEmpty,
        maxNumberSentSimilarForkRF,
        minLifetimeForkRF,
        maxLifetimeForkRF,

        minCoefForkRF1,
        maxCoefForkRF1,
        minCoefForkRF2,
        maxCoefForkRF2,

        // minCoefForkRF,
        // maxCoefForkRF,

        minPercentInitForkRF,
        maxPercentInitForkRF,
        moneyRegularFork,
        maxTimeCloseFork,
        minRangePercentCloseForkRF,

        apiKeyFirst,
        apiKeySecond,

        proxyName1,
        proxyName2,
        proxyIp1,
        proxyIp2,
        proxyPort1,
        proxyPort2,
        proxyUsername1,
        proxyUsername2,
        proxyPassword1,
        proxyPassword2,
        tokenOctoBrowser,
        ipOctoBrowser,
        portOctoBrowser,

        description,
        global_money,
        globalMoneyOSF,
        oddsRanges,
        oneShoulderForksOddsRanges,
        minLifetimeFork,
        maxLifetimeFork,
        minCoefWaitingFork,
        maxCoefWaitingFork,
        minPercentForkWaitingFork,
        maxPercentForkWaitingFork,
        takeProfit,
        stopLoss,
        minusStepTrailingStop,
        plusStepTrailingStop,
        moneyWaitingFork,
        // minCoefficientInitFork,
        // maxCoefficientInitFork,
        minPercentInitFork,
        maxPercentInitFork,
        delayedBetTime,
        minPercentFinalFork,
        maxPercentFinalFork,
        maxNumberSentSimilarFork,
        delayBeforeSending,
      });

      return;
    }

    backend
      .post(`/bot/create/${this.props.username}`, {
        name: `${this.state.name.value}`.trim(),
        usernameFirst: `${this.state.usernameFirst.value}`.trim(),
        passwordFirst: `${this.state.passwordFirst.value}`.trim(),
        usernameSecond: `${this.state.usernameSecond.value}`.trim(),
        passwordSecond: `${this.state.passwordSecond.value}`.trim(),
        bookmakerFirst: this.state.bookmakerFirst ? `${this.state.bookmakerFirst}`.trim() : this.state.bookmakerFirst,
        bookmakerSecond: this.state.bookmakerSecond ? `${this.state.bookmakerSecond}`.trim() : this.state.bookmakerSecond,
        // bookmakerSecond: (this.state.singleExpressBetType === 'single_express_bet' || this.state.singleExpressBetType === 'express_bet') ? 'betfair' : (this.state.bookmakerSecond ? `${this.state.bookmakerSecond}`.trim() : this.state.bookmakerSecond),
        isBetsApi1: this.state.isBetsApi1,
        isBetsApi2: this.state.isBetsApi2,
        isProxy1: this.state.isProxy1,
        isProxy2: this.state.isProxy2,
        isEnableReplay: this.state.isEnableReplay,
        isIncludedOverUnderBasket: this.state.isIncludedOverUnderBasket,
        isOverUnderBasket: this.state.isOverUnderBasket,
        isNotPutUnder: this.state.isNotPutUnder,
        isNotPutOver: this.state.isNotPutOver,
        isPutOnlyUnder: this.state.isPutOnlyUnder,
        isPutOnlyOver: this.state.isPutOnlyOver,
        isAutologinFirst: this.state.isAutologinFirst,
        isAutologinSecond: this.state.isAutologinSecond,
        isAmericanCoef: this.state.isAmericanCoef,
        isMirrorBets: this.state.isMirrorBets,
        isBetMaxAmount: this.state.isBetMaxAmount,
        isFirstBotStopTimeInterval: this.state.isFirstBotStopTimeInterval,
        firstBotStopTimeInterval: this.state.firstBotStopTimeInterval,
        isSecondBotStopTimeInterval: this.state.isSecondBotStopTimeInterval,
        secondBotStopTimeInterval: this.state.secondBotStopTimeInterval,
        isPutOneSecondShoulder: this.state.isPutOneSecondShoulder,
        isNotOverlapSecondShoulder: this.state.isNotOverlapSecondShoulder,
        isNotOverlapTwoShoulders: this.state.isNotOverlapTwoShoulders,
        isDisableCornersFootball: this.state.isDisableCornersFootball,
        isDisableOneHalfFootball: this.state.isDisableOneHalfFootball,
        isPlaceOnlyPlusHandicaps: this.state.isPlaceOnlyPlusHandicaps,
        isDisableIntegerHandicap: this.state.isDisableIntegerHandicap,
        isDisableQuarterHandicap: this.state.isDisableQuarterHandicap,
        isOddsFirstBookOver: this.state.isOddsFirstBookOver,
        isOddsFirstBookUnder: this.state.isOddsFirstBookUnder,
        isSandbox: this.state.isSandbox,
        mirrorFirst: this.state.mirrorFirst ? `${this.state.mirrorFirst}`.trim() : this.state.mirrorFirst,
        mirrorSecond: this.state.mirrorSecond ? `${this.state.mirrorSecond}`.trim() : this.state.mirrorSecond,
        mirrorLottolandFirst: this.state.mirrorLottolandFirst ? `${this.state.mirrorLottolandFirst}`.trim() : this.state.mirrorLottolandFirst,
        mirrorLottolandSecond: this.state.mirrorLottolandSecond ? `${this.state.mirrorLottolandSecond}`.trim() : this.state.mirrorLottolandSecond,
        waitingForkSports: this.state.waitingForkSports,
        pendingBetSports: this.state.pendingBetSports,
        maxSumBets1: `${this.state.maxSumBets1.value}`.trim(),
        maxSumBets2: `${this.state.maxSumBets2.value}`.trim(),
        maxBalance1: `${this.state.maxBalance1.value}`.trim(),
        maxBalance2: `${this.state.maxBalance2.value}`.trim(),
        withdrawnMoneySum1: this.state.withdrawnMoneySum1,
        withdrawnMoneySum2: this.state.withdrawnMoneySum2,
        lastBetTime1: this.state.lastBetTime1,
        lastBetTime2: this.state.lastBetTime2,
        lastFailedBetTime1: this.state.lastFailedBetTime1,
        lastFailedBetTime2: this.state.lastFailedBetTime2,
        numberFailedBets1: this.state.numberFailedBets1,
        numberFailedBets2: this.state.numberFailedBets2,
        lastErrorMessage1: this.state.lastErrorMessage1,
        lastErrorMessage2: this.state.lastErrorMessage2,
        lastErrorTime1: this.state.lastErrorTime1,
        lastErrorTime2: this.state.lastErrorTime2,
        balance1: this.state.balance1,
        balance2: this.state.balance2,
        sumBets1: this.state.sumBets1,
        sumBets2: this.state.sumBets2,
        cashout1: this.state.cashout1,
        cashout2: this.state.cashout2,

        oneShoulderForkSports: this.state.oneShoulderForkSports,
        moneyLineOutcomesSF: this.state.moneyLineOutcomesSF,
        totalsOutcomesSF: this.state.totalsOutcomesSF,
        indTotalsOutcomesSF: this.state.indTotalsOutcomesSF,
        handicapsOutcomesSF: this.state.handicapsOutcomesSF,
        winnerOutcomesSF: this.state.winnerOutcomesSF,
        additionalsOutcomesSF: this.state.additionalsOutcomesSF,
        // maxNumberSentSimilarForkSF: `${this.state.maxNumberSentSimilarForkSF.value}`.trim(),
        maxNumberBetsOneTimeSF: `${this.state.maxNumberBetsOneTimeSF.value}`.trim(),
        maxNumberBetsOneGameSF: `${this.state.maxNumberBetsOneGameSF.value}`.trim(),
        maxNumberBetsOneOutcomeSF: `${this.state.maxNumberBetsOneOutcomeSF.value}`.trim(),
        minLifetimeForkSF: `${this.state.minLifetimeForkSF.value}`.trim(),
        maxLifetimeForkSF: `${this.state.maxLifetimeForkSF.value}`.trim(),
        minPercentInitForkSF: `${this.state.minPercentInitForkSF.value}`.trim(),
        maxPercentInitForkSF: `${this.state.maxPercentInitForkSF.value}`.trim(),
        minCoefOneShoulderFork: `${this.state.minCoefOneShoulderFork.value}`.trim(),
        maxCoefOneShoulderFork: `${this.state.maxCoefOneShoulderFork.value}`.trim(),
        // moneyOneShoulderFork: `${this.state.moneyOneShoulderFork.value}`.trim(),

        minPercentFork1SF: `${this.state.minPercentFork1SF.value}`.trim(),
        maxPercentFork1SF: `${this.state.maxPercentFork1SF.value}`.trim(),
        minCoefFork1SF: `${this.state.minCoefFork1SF.value}`.trim(),
        maxCoefFork1SF: `${this.state.maxCoefFork1SF.value}`.trim(),
        minPercentFork2SF: `${this.state.minPercentFork2SF.value}`.trim(),
        maxPercentFork2SF: `${this.state.maxPercentFork2SF.value}`.trim(),
        minCoefFork2SF: `${this.state.minCoefFork2SF.value}`.trim(),
        maxCoefFork2SF: `${this.state.maxCoefFork2SF.value}`.trim(),

        regularForkSports: this.state.regularForkSports,
        moneyLineOutcomesRF: this.state.moneyLineOutcomesRF,
        totalsOutcomesRF: this.state.totalsOutcomesRF,
        indTotalsOutcomesRF: this.state.indTotalsOutcomesRF,
        handicapsOutcomesRF: this.state.handicapsOutcomesRF,
        winnerOutcomesRF: this.state.winnerOutcomesRF,
        additionalsOutcomesRF: this.state.additionalsOutcomesRF,
        maxNumberSentSimilarForkRF: `${this.state.maxNumberSentSimilarForkRF.value}`.trim(),
        minLifetimeForkRF: `${this.state.minLifetimeForkRF.value}`.trim(),
        maxLifetimeForkRF: `${this.state.maxLifetimeForkRF.value}`.trim(),

        minCoefForkRF1: `${this.state.minCoefForkRF1.value}`.trim(),
        maxCoefForkRF1: `${this.state.maxCoefForkRF1.value}`.trim(),
        minCoefForkRF2: `${this.state.minCoefForkRF2.value}`.trim(),
        maxCoefForkRF2: `${this.state.maxCoefForkRF2.value}`.trim(),

        // minCoefForkRF: `${this.state.minCoefForkRF.value}`.trim(),
        // maxCoefForkRF: `${this.state.maxCoefForkRF.value}`.trim(),

        minPercentInitForkRF: `${this.state.minPercentInitForkRF.value}`.trim(),
        maxPercentInitForkRF: `${this.state.maxPercentInitForkRF.value}`.trim(),
        moneyRegularFork: `${this.state.moneyRegularFork.value}`.trim(),
        maxTimeCloseFork: `${this.state.maxTimeCloseFork.value}`.trim(),
        minRangePercentCloseForkRF: `${this.state.minRangePercentCloseForkRF.value}`.trim(),

        apiKeyFirst: `${this.state.apiKeyFirst.value}`.trim(),
        apiKeySecond: `${this.state.apiKeySecond.value}`.trim(),
        currencyFirst: this.state.currencyFirst ? `${this.state.currencyFirst}`.trim() : this.state.currencyFirst,
        currencySecond: this.state.currencySecond ? `${this.state.currencySecond}`.trim() : this.state.currencySecond,
        currencyTypeFirst: this.state.currencyTypeFirst ? `${this.state.currencyTypeFirst}`.trim() : this.state.currencyTypeFirst,
        currencyTypeSecond: this.state.currencyTypeSecond ? `${this.state.currencyTypeSecond}`.trim() : this.state.currencyTypeSecond,

        global_money: `${this.state.global_money.value}`.trim(),
        description: `${this.state.description.value}`.trim(),

        proxyName1: `${this.state.proxyName1.value}`.trim(),
        proxyName2: `${this.state.proxyName2.value}`.trim(),
        proxyIp1: `${this.state.proxyIp1.value}`.trim(),
        proxyIp2: `${this.state.proxyIp2.value}`.trim(),
        proxyPort1: `${this.state.proxyPort1.value}`.trim(),
        proxyPort2: `${this.state.proxyPort2.value}`.trim(),
        proxyUsername1: `${this.state.proxyUsername1.value}`.trim(),
        proxyUsername2: `${this.state.proxyUsername2.value}`.trim(),
        proxyPassword1: `${this.state.proxyPassword1.value}`.trim(),
        proxyPassword2: `${this.state.proxyPassword2.value}`.trim(),
        tokenOctoBrowser: `${this.state.tokenOctoBrowser.value}`.trim(),
        ipOctoBrowser: `${this.state.ipOctoBrowser.value}`.trim(),
        portOctoBrowser: `${this.state.portOctoBrowser.value}`.trim(),
        proxyProtocol1: this.state.proxyProtocol1 ? `${this.state.proxyProtocol1}`.trim() : this.state.proxyProtocol1,
        proxyProtocol2: this.state.proxyProtocol2 ? `${this.state.proxyProtocol2}`.trim() : this.state.proxyProtocol2,

        mirror: this.state.mirror ? `${this.state.mirror}`.trim() : this.state.mirror,
        betting_type: this.state.betting_type ? `${this.state.betting_type}`.trim() : this.state.betting_type,
        sports: this.state.sports,
        gameTime: this.state.gameTime,
        oddsRanges: this.state.oddsRanges
          .filter((el) => {
            return `${el.coef}`.trim() !== "" && `${el.money}`.trim() !== "";
          })
          .map((e) => {
            return {
              coef: e.coef,
              money: e.money,
            };
          }),
        oneShoulderForksOddsRanges: this.state.oneShoulderForksOddsRanges
          .filter((el) => {
            return `${el.coef}`.trim() !== "" && `${el.money}`.trim() !== "";
          })
          .map((e) => {
            return {
              coef: e.coef,
              money: e.money,
            };
          }),
        globalMoneyOSF: `${this.state.globalMoneyOSF.value}`.trim(),
        strategyName: this.state.strategyName,
        flatName: this.state.flatName,
        isFlat: this.state.isFlat,
        strategyIsPlaceBet: this.state.strategyIsPlaceBet,
        strategyConditions: this.state.strategyConditions,
        workStatus: this.state.workStatus,
        moneyStatus: this.state.moneyStatus,
        deposit1: Number(this.state.deposit1),
        deposit2: Number(this.state.deposit2),
        strategy: this.state.hasStrategies ? strategy : null,
        minLifetimeFork: `${this.state.minLifetimeFork.value}`.trim(),
        maxLifetimeFork: `${this.state.maxLifetimeFork.value}`.trim(),
        minCoefWaitingFork: `${this.state.minCoefWaitingFork.value}`.trim(),
        maxCoefWaitingFork: `${this.state.maxCoefWaitingFork.value}`.trim(),
        minPercentForkWaitingFork: `${this.state.minPercentForkWaitingFork.value}`.trim(),
        maxPercentForkWaitingFork: `${this.state.maxPercentForkWaitingFork.value}`.trim(),
        takeProfit: `${this.state.takeProfit.value}`.trim(),
        stopLoss: `${this.state.stopLoss.value}`.trim(),
        minusStepTrailingStop: `${this.state.minusStepTrailingStop.value}`.trim(),
        plusStepTrailingStop: `${this.state.plusStepTrailingStop.value}`.trim(),
        moneyWaitingFork: `${this.state.moneyWaitingFork.value}`.trim(),
        // minCoefficientInitFork: `${this.state.minCoefficientInitFork.value}`.trim(),
        // maxCoefficientInitFork: `${this.state.maxCoefficientInitFork.value}`.trim(),
        minPercentInitFork: `${this.state.minPercentInitFork.value}`.trim(),
        maxPercentInitFork: `${this.state.maxPercentInitFork.value}`.trim(),
        delayedBetTime: `${this.state.delayedBetTime.value}`.trim(),
        minPercentFinalFork: `${this.state.minPercentFinalFork.value}`.trim(),
        maxPercentFinalFork: `${this.state.maxPercentFinalFork.value}`.trim(),
        maxNumberSentSimilarFork: `${this.state.maxNumberSentSimilarFork.value}`.trim(),
        delayBeforeSending: `${this.state.delayBeforeSending.value}`.trim(),
        botType: this.state.botType ? `${this.state.botType}`.trim() : this.state.botType,
        singleExpressBetType: this.state.singleExpressBetType ? `${this.state.singleExpressBetType}`.trim() : this.state.singleExpressBetType,
        isTrailingStop: this.state.isTrailingStop,
        moneyLineOutcomes: this.state.moneyLineOutcomes,
        totalsOutcomes: this.state.totalsOutcomes,
        indTotalsOutcomes: this.state.indTotalsOutcomes,
        handicapsOutcomes: this.state.handicapsOutcomes,
        winnerOutcomes: this.state.winnerOutcomes,
        additionalsOutcomes: this.state.additionalsOutcomes,
        colorClient: this.state.colorClient,
        isSendToBot: false,
        uuid1: '',
        uuid2: '',
        endpointWS1: '',
        endpointWS2: '',
        isSimbetBasketball: false,
        isArchive: false,
        isSendBetSimbet: true,
        numberSuccessBets1: 0,
        numberSuccessBets2: 0,
        moneySimbetRegularFork: 100,
      })
      .then((res) => {
        if (res.data.success) {
          this.props.updateData();
        }
      })
      .catch(console.log)
      .finally(() => {
        document.querySelector("#myModalAdd").querySelector(".close").click();
      });
  }

  changeStrategyName(e) {
    const value = e.target.value;

    const state = {
      strategyName: value || ''
    };

    if (value) {
      try {
        state.strategyConditions = this.state.betStrategies.filter((i) => i.name === e.target.value)[0].oddsRanges;
        state.strategyIsPlaceBet = this.state.betStrategies.filter((i) => i.name === e.target.value)[0].isPlaceBet;
      }
      catch (e) {
      }
    }
    else {
      state.strategyConditions = []
    }

    this.setState(state);
  }

  changeFlatName(e) {
    this.setState({
      flatName: e.target.value || '',
    });
  }

  changeIsFlat(e) {
    this.setState({
      isFlat: e.target.checked,
    });
  }

  changeMaxSumBets1(e) {
    this.setState({
      maxSumBets1: {
        hasError: false,
        value: e.target.value,
      },
    });
  }

  changeMaxSumBets2(e) {
    this.setState({
      maxSumBets2: {
        hasError: false,
        value: e.target.value,
      },
    });
  }

  changeMaxBalance1(e) {
    this.setState({
      maxBalance1: {
        hasError: false,
        value: e.target.value,
      },
    });
  }

  changeMaxBalance2(e) {
    this.setState({
      maxBalance2: {
        hasError: false,
        value: e.target.value,
      },
    });
  }

  changeRangeName(e) {
    const value = e.target.value;

    const state = {
      rangeName: {
        value,
      },
    };

    if (value) {
      try {
        state.oddsRanges = this.state.ranges.filter((i) => i.name === e.target.value)[0].oddsRanges;
      }
      catch (e) {
      }
    }

    this.setState(state);
  }

  changeOneShoulderForksRangeName(e) {
    const value = e.target.value;

    const state = {
      oneShoulderForksRangeName: {
        value,
      },
    };

    if (value) {
      try {
        state.oneShoulderForksOddsRanges = this.state.oneShoulderForksRanges.filter((i) => i.name === e.target.value)[0].oddsRanges;
      }
      catch (e) {
      }
    }

    this.setState(state);
  }

  changeNameBot(e) {
    this.setState({
      name: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeUsernameFirst(e) {
    this.setState({
      usernameFirst: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changePasswordFirst(e) {
    this.setState({
      passwordFirst: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeUsernameSecond(e) {
    this.setState({
      usernameSecond: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changePasswordSecond(e) {
    this.setState({
      passwordSecond: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeIsBetsApi1(e) {
    this.setState({
      isBetsApi1: e.target.checked,
    });
  }

  changeIsBetsApi2(e) {
    this.setState({
      isBetsApi2: e.target.checked,
    });
  }

  changeIsProxy1(e) {
    this.setState({
      isProxy1: e.target.checked,
    });
  }

  changeIsProxy2(e) {
    this.setState({
      isProxy2: e.target.checked,
    });
  }

  changeIsIncludedOverUnderBasket(e) {
    this.setState({
      isIncludedOverUnderBasket: e.target.checked,
    });
  }

  changeIsPutOnlyOver(e) {
    this.setState({
      isPutOnlyOver: e.target.checked,
    });
  }
  changeIsPutOnlyUnder(e) {
    this.setState({
      isPutOnlyUnder: e.target.checked,
    });
  }
  changeIsNotPutUnder(e) {
    this.setState({
      isNotPutUnder: e.target.checked,
    });
  }

  changeIsNotPutOver(e) {
    this.setState({
      isNotPutOver: e.target.checked,
    });
  }

  changeIsOverUnderBasket(e) {
    this.setState({
      isOverUnderBasket: e.target.checked,
    });
  }

  changeIsEnableReplay(e) {
    this.setState({
      isEnableReplay: e.target.checked,
    });
  }
  changeIsAutologinFirst(e) {
    this.setState({
      isAutologinFirst: e.target.checked,
    });
  }

  changeIsAutologinSecond(e) {
    this.setState({
      isAutologinSecond: e.target.checked,
    });
  }

  changeIsAmericanCoef(e) {
    this.setState({
      isAmericanCoef: e.target.checked,
    });
  }

  changeIsBetMaxAmount(e) {
    this.setState({
      isBetMaxAmount: e.target.checked,
    });
  }

  changeIsMirrorBets(e) {
    this.setState({
      isMirrorBets: e.target.checked,
    });
  }

  changeIsFirstBotStopTimeInterval(e) {
    this.setState({
      isFirstBotStopTimeInterval: e.target.checked,
    });
  }

  changeFirstBotStopTimeInterval(e, val) {
    this.setState({
      firstBotStopTimeInterval: val,
    });
  }

  changeIsSecondBotStopTimeInterval(e) {
    this.setState({
      isSecondBotStopTimeInterval: e.target.checked,
    });
  }

  changeSecondBotStopTimeInterval(e, val) {
    this.setState({
      secondBotStopTimeInterval: val,
    });
  }

  changeIsPutOneSecondShoulder(e) {
    this.setState({
      isPutOneSecondShoulder: e.target.checked,
    });
  }

  changeIsNotOverlapSecondShoulder(e) {
    this.setState({
      isNotOverlapSecondShoulder: e.target.checked,
    });
  }

  changeIsNotOverlapTwoShoulders(e) {
    this.setState({
      isNotOverlapTwoShoulders: e.target.checked,
    });
  }

  changeIsDisableCornersFootball(e) {
    this.setState({
      isDisableCornersFootball: e.target.checked,
    });
  }

  changeIsDisableOneHalfFootball(e) {
    this.setState({
      isDisableOneHalfFootball: e.target.checked,
    });
  }

  changeIsPlaceOnlyPlusHandicaps(e) {
    this.setState({
      isPlaceOnlyPlusHandicaps: e.target.checked,
    });
  }

  changeIsDisableIntegerHandicap(e) {
    this.setState({
      isDisableIntegerHandicap: e.target.checked,
    });
  }

  changeIsDisableQuarterHandicap(e) {
    this.setState({
      isDisableQuarterHandicap: e.target.checked,
    });
  }

  changeIsOddsFirstBookOver(e) {
    this.setState({
      isOddsFirstBookOver: e.target.checked,
    });
  }

  changeIsOddsFirstBookUnder(e) {
    this.setState({
      isOddsFirstBookUnder: e.target.checked,
    });
  }

  changeBookmakerFirst(e) {
    this.setState({
      bookmakerFirst: e.target.value,
    });
  }

  changeBookmakerSecond(e) {
    this.setState({
      bookmakerSecond: e.target.value,
    });
  }

  changeMirrorFirst(e) {
    this.setState({
      mirrorFirst: e.target.value,
    });
  }

  changeMirrorSecond(e) {
    this.setState({
      mirrorSecond: e.target.value,
    });
  }

  changeMirrorLottolandFirst(e) {
    this.setState({
      mirrorLottolandFirst: e.target.value,
    });
  }

  changeMirrorLottolandSecond(e) {
    this.setState({
      mirrorLottolandSecond: e.target.value,
    });
  }

  setWaitingForkSports(waitingForkSports) {
    const waitingForkSportsEmpty = waitingForkSports.length === 0;
    this.setState({waitingForkSports, waitingForkSportsEmpty});
  }

  setPendingBetSports(pendingBetSports) {
    const pendingBetSportsEmpty = pendingBetSports.length === 0;
    this.setState({pendingBetSports, pendingBetSportsEmpty});
  }

  setOneShoulderForkSports(oneShoulderForkSports) {
    const oneShoulderForkSportsEmpty = oneShoulderForkSports.length === 0;
    this.setState({oneShoulderForkSports, oneShoulderForkSportsEmpty});
  }

  setMoneyLineOutcomesSF(moneyLineOutcomesSF) {
    this.setState({moneyLineOutcomesSF});
  }

  setTotalsOutcomesSF(totalsOutcomesSF) {
    this.setState({totalsOutcomesSF});
  }

  setIndTotalsOutcomesSF(indTotalsOutcomesSF) {
    this.setState({indTotalsOutcomesSF});
  }

  setHandicapsOutcomesSF(handicapsOutcomesSF) {
    this.setState({handicapsOutcomesSF});
  }

  setWinnerOutcomesSF(winnerOutcomesSF) {
    this.setState({winnerOutcomesSF});
  }

  setAdditionalsOutcomesSF(additionalsOutcomesSF) {
    this.setState({additionalsOutcomesSF});
  }

  // changeMaxNumberSentSimilarForkSF(e) {
  //   this.setState({
  //     maxNumberSentSimilarForkSF: {
  //       value: e.target.value,
  //       hasError: false,
  //     },
  //   });
  // }

  changeMaxNumberBetsOneTimeSF(e) {
    this.setState({
      maxNumberBetsOneTimeSF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxNumberBetsOneGameSF(e) {
    this.setState({
      maxNumberBetsOneGameSF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxNumberBetsOneOutcomeSF(e) {
    this.setState({
      maxNumberBetsOneOutcomeSF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinLifetimeForkSF(e) {
    this.setState({
      minLifetimeForkSF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxLifetimeForkSF(e) {
    this.setState({
      maxLifetimeForkSF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinPercentInitForkSF(e) {
    this.setState({
      minPercentInitForkSF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxPercentInitForkSF(e) {
    this.setState({
      maxPercentInitForkSF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinCoefOneShoulderFork(e) {
    this.setState({
      minCoefOneShoulderFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxCoefOneShoulderFork(e) {
    this.setState({
      maxCoefOneShoulderFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  // changeMoneyOneShoulderFork(e) {
  //   this.setState({
  //     moneyOneShoulderFork: {
  //       value: e.target.value,
  //       hasError: false,
  //     },
  //   });
  // }

  setRegularForkSports(regularForkSports) {
    const regularForkSportsEmpty = regularForkSports.length === 0;
    this.setState({regularForkSports, regularForkSportsEmpty});
  }

  setMoneyLineOutcomesRF(moneyLineOutcomesRF) {
    this.setState({moneyLineOutcomesRF});
  }

  setTotalsOutcomesRF(totalsOutcomesRF) {
    this.setState({totalsOutcomesRF});
  }

  setIndTotalsOutcomesRF(indTotalsOutcomesRF) {
    this.setState({indTotalsOutcomesRF});
  }

  setHandicapsOutcomesRF(handicapsOutcomesRF) {
    this.setState({handicapsOutcomesRF});
  }

  setWinnerOutcomesRF(winnerOutcomesRF) {
    this.setState({winnerOutcomesRF});
  }

  setAdditionalsOutcomesRF(additionalsOutcomesRF) {
    this.setState({additionalsOutcomesRF});
  }

  changeMaxNumberSentSimilarForkRF(e) {
    this.setState({
      maxNumberSentSimilarForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinLifetimeForkRF(e) {
    this.setState({
      minLifetimeForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxLifetimeForkRF(e) {
    this.setState({
      maxLifetimeForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }


  changeMinCoefForkRF1(e) {
    this.setState({
      minCoefForkRF1: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxCoefForkRF1(e) {
    this.setState({
      maxCoefForkRF1: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinCoefForkRF2(e) {
    this.setState({
      minCoefForkRF2: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxCoefForkRF2(e) {
    this.setState({
      maxCoefForkRF2: {
        value: e.target.value,
        hasError: false,
      },
    });
  }


  changeMinCoefForkRF(e) {
    this.setState({
      minCoefForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxCoefForkRF(e) {
    this.setState({
      maxCoefForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }


  changeMinPercentInitForkRF(e) {
    this.setState({
      minPercentInitForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxPercentInitForkRF(e) {
    this.setState({
      maxPercentInitForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMoneyRegularFork(e) {
    this.setState({
      moneyRegularFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxTimeCloseFork(e) {
    this.setState({
      maxTimeCloseFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinRangePercentCloseForkRF(e) {
    this.setState({
      minRangePercentCloseForkRF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeApiKeyFirst(e) {
    this.setState({
      apiKeyFirst: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeApiKeySecond(e) {
    this.setState({
      apiKeySecond: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeCurrencyFirst(e) {
    this.setState({
      currencyFirst: e.target.value,
    });
  }

  changeCurrencySecond(e) {
    this.setState({
      currencySecond: e.target.value,
    });
  }

  changeCurrencyTypeFirst(e) {
    this.setState({
      currencyTypeFirst: e.target.value,
    });
  }

  changeCurrencyTypeSecond(e) {
    this.setState({
      currencyTypeSecond: e.target.value,
    });
  }

  changeDescription(e) {
    this.setState({
      description: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeColor(e) {
    this.setState({
      colorClient: e
    });
  }

  changeProxyName1(e) {
    this.setState({
      proxyName1: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyName2(e) {
    this.setState({
      proxyName2: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyIp1(e) {
    this.setState({
      proxyIp1: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyIp2(e) {
    this.setState({
      proxyIp2: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyPort1(e) {
    this.setState({
      proxyPort1: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyPort2(e) {
    this.setState({
      proxyPort2: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyUsername1(e) {
    this.setState({
      proxyUsername1: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyUsername2(e) {
    this.setState({
      proxyUsername2: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyPassword1(e) {
    this.setState({
      proxyPassword1: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyPassword2(e) {
    this.setState({
      proxyPassword2: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeTokenOctoBrowser(e) {
    this.setState({
      tokenOctoBrowser: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeIpOctoBrowser(e) {
    this.setState({
      ipOctoBrowser: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changePortOctoBrowser(e) {
    this.setState({
      portOctoBrowser: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeProxyProtocol1(e) {
    this.setState({
      proxyProtocol1: e.target.value,
    });
  }

  changeProxyProtocol2(e) {
    this.setState({
      proxyProtocol2: e.target.value,
    });
  }

  changeMirror(e) {
    this.setState({
      mirror: e.target.value,
    });
  }

  changeGlobalMoney(e) {
    this.setState({
      global_money: {
        hasError: false,
        value: e.target.value,
      },
    });
  }

  changeGlobalMoneyOSF(e) {
    this.setState({
      globalMoneyOSF: {
        hasError: false,
        value: e.target.value,
      },
    });
  }

  changeDeposit1(e) {
    // const deposit1 = Number(e.target.value.trim());
    //
    // if (!isNaN(deposit1)) {
    //   this.setState({deposit1});
    // }

    const deposit1 = e.target.value.trim()
    this.setState({deposit1});
  }

  changeDeposit2(e) {
    // const deposit2 = Number(e.target.value.trim());
    //
    // if (!isNaN(deposit2)) {
    //   this.setState({deposit2});
    // }

    const deposit2 = e.target.value.trim()
    this.setState({deposit2});
  }

  changeCoef(e) {
    const ind = `${e.target.id}`.replace("coef-", "");
    const coef = e.target.value;
    const oddsRanges = this.state.oddsRanges;
    oddsRanges[ind].coef = coef;
    oddsRanges[ind].hasError = false;
    oddsRanges[ind].hasCoefError = false;
    this.setState({oddsRanges});
  }

  changeMoney(e) {
    const ind = `${e.target.id}`.replace("money-", "");
    const money = e.target.value;
    const oddsRanges = this.state.oddsRanges;
    oddsRanges[ind].money = money;
    oddsRanges[ind].hasError = false;
    oddsRanges[ind].hasMoneyError = false;
    this.setState({oddsRanges});
  }

  changeCoefOSF(e) {
    const ind = `${e.target.id}`.replace("coef-", "");
    const coef = e.target.value;
    const oneShoulderForksOddsRanges = this.state.oneShoulderForksOddsRanges;
    oneShoulderForksOddsRanges[ind].coef = coef;
    oneShoulderForksOddsRanges[ind].hasError = false;
    oneShoulderForksOddsRanges[ind].hasCoefError = false;
    this.setState({oneShoulderForksOddsRanges});
  }

  changeMoneyOSF(e) {
    const ind = `${e.target.id}`.replace("money-", "");
    const money = e.target.value;
    const oneShoulderForksOddsRanges = this.state.oneShoulderForksOddsRanges;
    oneShoulderForksOddsRanges[ind].money = money;
    oneShoulderForksOddsRanges[ind].hasError = false;
    oneShoulderForksOddsRanges[ind].hasMoneyError = false;
    this.setState({oneShoulderForksOddsRanges});
  }

  setSports(sports) {
    const sportsEmpty = sports.length === 0;
    this.setState({sports, sportsEmpty});
  }

  changeBetValue(e) {
    this.setState({
      betting_type: e.target.value,
    });
  }

  changeBotType(e) {
    this.setState({
      botType: e.target.value,
    });
  }

  changeSingleExpressBetType(e) {
    this.setState({
      singleExpressBetType: e.target.value,
    });
  }

  changeMinPercentFork1SF(e) {
    this.setState({
      minPercentFork1SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxPercentFork1SF(e) {
    this.setState({
      maxPercentFork1SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinCoefFork1SF(e) {
    this.setState({
      minCoefFork1SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxCoefFork1SF(e) {
    this.setState({
      maxCoefFork1SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinPercentFork2SF(e) {
    this.setState({
      minPercentFork2SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxPercentFork2SF(e) {
    this.setState({
      maxPercentFork2SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinCoefFork2SF(e) {
    this.setState({
      minCoefFork2SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxCoefFork2SF(e) {
    this.setState({
      maxCoefFork2SF: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeIsTrailingStop(e) {
    this.setState({
      isTrailingStop: e.target.checked,
    });
  }

  setMoneyLineOutcomes(moneyLineOutcomes) {
    this.setState({moneyLineOutcomes});
  }

  setTotalsOutcomes(totalsOutcomes) {
    this.setState({totalsOutcomes});
  }

  setIndTotalsOutcomes(indTotalsOutcomes) {
    this.setState({indTotalsOutcomes});
  }

  setHandicapsOutcomes(handicapsOutcomes) {
    this.setState({handicapsOutcomes});
  }

  setWinnerOutcomes(winnerOutcomes) {
    this.setState({winnerOutcomes});
  }

  setAdditionalsOutcomes(additionalsOutcomes) {
    this.setState({additionalsOutcomes});
  }

  changeMinLifetimeFork(e) {
    this.setState({
      minLifetimeFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxLifetimeFork(e) {
    this.setState({
      maxLifetimeFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinCoefWaitingFork(e) {
    this.setState({
      minCoefWaitingFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxCoefWaitingFork(e) {
    this.setState({
      maxCoefWaitingFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinPercentForkWaitingFork(e) {
    this.setState({
      minPercentForkWaitingFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxPercentForkWaitingFork(e) {
    this.setState({
      maxPercentForkWaitingFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeTakeProfit(e) {
    this.setState({
      takeProfit: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeStopLoss(e) {
    this.setState({
      stopLoss: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinusStepTrailingStop(e) {
    this.setState({
      minusStepTrailingStop: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changePlusStepTrailingStop(e) {
    this.setState({
      plusStepTrailingStop: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMoneyWaitingFork(e) {
    this.setState({
      moneyWaitingFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  // changeMinCoefficientInitFork(e) {
  //   this.setState({
  //     minCoefficientInitFork: {
  //       value: e.target.value,
  //       hasError: false,
  //     },
  //   });
  // }

  // changeMaxCoefficientInitFork(e) {
  //   this.setState({
  //     maxCoefficientInitFork: {
  //       value: e.target.value,
  //       hasError: false,
  //     },
  //   });
  // }

  changeMinPercentInitFork(e) {
    this.setState({
      minPercentInitFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxPercentInitFork(e) {
    this.setState({
      maxPercentInitFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeDelayedBetTime(e) {
    this.setState({
      delayedBetTime: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeDelayBeforeSending(e) {
    this.setState({
      delayBeforeSending: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMinPercentFinalFork(e) {
    this.setState({
      minPercentFinalFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxPercentFinalFork(e) {
    this.setState({
      maxPercentFinalFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  changeMaxNumberSentSimilarFork(e) {
    this.setState({
      maxNumberSentSimilarFork: {
        value: e.target.value,
        hasError: false,
      },
    });
  }

  render() {
    const maxSumBets1Class = `form-control${this.state.maxSumBets1.hasError ? " is-invalid" : ""}`;
    const maxSumBets2Class = `form-control${this.state.maxSumBets2.hasError ? " is-invalid" : ""}`;
    const maxBalance1Class = `form-control${this.state.maxBalance1.hasError ? " is-invalid" : ""}`;
    const maxBalance2Class = `form-control${this.state.maxBalance2.hasError ? " is-invalid" : ""}`;
    const botnameClass = `form-control${this.state.name.hasError ? " is-invalid" : ""}`;
    const usernameFirstClass = `form-control${this.state.usernameFirst.hasError ? " is-invalid" : ""}`;
    const passwordFirstClass = `form-control${this.state.passwordFirst.hasError ? " is-invalid" : ""}`;
    const usernameSecondClass = `form-control${this.state.usernameSecond.hasError ? " is-invalid" : ""}`;
    const passwordSecondClass = `form-control${this.state.passwordSecond.hasError ? " is-invalid" : ""}`;
    const apiKeyFirstClass = `form-control${this.state.apiKeyFirst.hasError ? " is-invalid" : ""}`;
    const apiKeySecondClass = `form-control${this.state.apiKeySecond.hasError ? " is-invalid" : ""}`;
    const glMoneyClass = `form-control${this.state.global_money.hasError ? " is-invalid" : ""}`;
    const descripClass = `form-control${this.state.description.hasError ? " is-invalid" : ""}`;

    const proxyName1Class = `form-control${this.state.proxyName1.hasError ? " is-invalid" : ""}`;
    const proxyName2Class = `form-control${this.state.proxyName2.hasError ? " is-invalid" : ""}`;
    const proxyIp1Class = `form-control${this.state.proxyIp1.hasError ? " is-invalid" : ""}`;
    const proxyIp2Class = `form-control${this.state.proxyIp2.hasError ? " is-invalid" : ""}`;
    const proxyPort1Class = `form-control${this.state.proxyPort1.hasError ? " is-invalid" : ""}`;
    const proxyPort2Class = `form-control${this.state.proxyPort2.hasError ? " is-invalid" : ""}`;
    const proxyUsername1Class = `form-control${this.state.proxyUsername1.hasError ? " is-invalid" : ""}`;
    const proxyUsername2Class = `form-control${this.state.proxyUsername2.hasError ? " is-invalid" : ""}`;
    const proxyPassword1Class = `form-control${this.state.proxyPassword1.hasError ? " is-invalid" : ""}`;
    const proxyPassword2Class = `form-control${this.state.proxyPassword2.hasError ? " is-invalid" : ""}`;
    const tokenOctoBrowserClass = `form-control${this.state.tokenOctoBrowser.hasError ? " is-invalid" : ""}`;
    const ipOctoBrowserClass = `form-control${this.state.ipOctoBrowser.hasError ? " is-invalid" : ""}`;
    const portOctoBrowserClass = `form-control${this.state.portOctoBrowser.hasError ? " is-invalid" : ""}`;

    const delayedBetTimeClass = `form-control${this.state.delayedBetTime.hasError ? " is-invalid" : ""}`;
    const minLifetimeForkClass = `form-control${this.state.minLifetimeFork.hasError ? " is-invalid" : ""}`;
    const maxLifetimeForkClass = `form-control${this.state.maxLifetimeFork.hasError ? " is-invalid" : ""}`;
    const minCoefWaitingForkClass = `form-control${this.state.minCoefWaitingFork.hasError ? " is-invalid" : ""}`;
    const maxCoefWaitingForkClass = `form-control${this.state.maxCoefWaitingFork.hasError ? " is-invalid" : ""}`;
    const minPercentForkWaitingForkClass = `form-control${this.state.minPercentForkWaitingFork.hasError ? " is-invalid" : ""}`;
    const maxPercentForkWaitingForkClass = `form-control${this.state.maxPercentForkWaitingFork.hasError ? " is-invalid" : ""}`;
    const takeProfitClass = `form-control${this.state.takeProfit.hasError ? " is-invalid" : ""}`;
    const stopLossClass = `form-control${this.state.stopLoss.hasError ? " is-invalid" : ""}`;
    const minusStepTrailingStopClass = `form-control${this.state.minusStepTrailingStop.hasError ? " is-invalid" : ""}`;
    const plusStepTrailingStopClass = `form-control${this.state.plusStepTrailingStop.hasError ? " is-invalid" : ""}`;
    const moneyWaitingForkClass = `form-control${this.state.moneyWaitingFork.hasError ? " is-invalid" : ""}`;
    // const minCoefficientInitForkClass = `form-control${this.state.minCoefficientInitFork.hasError ? " is-invalid" : ""}`;
    // const maxCoefficientInitForkClass = `form-control${this.state.maxCoefficientInitFork.hasError ? " is-invalid" : ""}`;
    const minPercentInitForkClass = `form-control${this.state.minPercentInitFork.hasError ? " is-invalid" : ""}`;
    const maxPercentInitForkClass = `form-control${this.state.maxPercentInitFork.hasError ? " is-invalid" : ""}`;
    const minPercentFinalForkClass = `form-control${this.state.minPercentFinalFork.hasError ? " is-invalid" : ""}`;
    const maxPercentFinalForkClass = `form-control${this.state.maxPercentFinalFork.hasError ? " is-invalid" : ""}`;
    const maxNumberSentSimilarForkClass = `form-control${this.state.maxNumberSentSimilarFork.hasError ? " is-invalid" : ""}`;
    const delayBeforeSendingClass = `form-control${this.state.delayBeforeSending.hasError ? " is-invalid" : ""}`;
    const maxNumberSentSimilarForkRFClass = `form-control${this.state.maxNumberSentSimilarForkRF.hasError ? " is-invalid" : ""}`;
    const minLifetimeForkRFClass = `form-control${this.state.minLifetimeForkRF.hasError ? " is-invalid" : ""}`;
    const maxLifetimeForkRFClass = `form-control${this.state.maxLifetimeForkRF.hasError ? " is-invalid" : ""}`;

    const minCoefForkRF1Class = `form-control${this.state.minCoefForkRF1.hasError ? " is-invalid" : ""}`;
    const maxCoefForkRF1Class = `form-control${this.state.maxCoefForkRF1.hasError ? " is-invalid" : ""}`;
    const minCoefForkRF2Class = `form-control${this.state.minCoefForkRF2.hasError ? " is-invalid" : ""}`;
    const maxCoefForkRF2Class = `form-control${this.state.maxCoefForkRF2.hasError ? " is-invalid" : ""}`;

    const minCoefForkRFClass = `form-control${this.state.minCoefForkRF.hasError ? " is-invalid" : ""}`;
    const maxCoefForkRFClass = `form-control${this.state.maxCoefForkRF.hasError ? " is-invalid" : ""}`;

    const minPercentInitForkRFClass = `form-control${this.state.minPercentInitForkRF.hasError ? " is-invalid" : ""}`;
    const maxPercentInitForkRFClass = `form-control${this.state.maxPercentInitForkRF.hasError ? " is-invalid" : ""}`;
    const moneyRegularForkClass = `form-control${this.state.moneyRegularFork.hasError ? " is-invalid" : ""}`;
    const maxTimeCloseForkClass = `form-control${this.state.maxTimeCloseFork.hasError ? " is-invalid" : ""}`;
    const minRangePercentCloseForkRFClass = `form-control${this.state.minRangePercentCloseForkRF.hasError ? " is-invalid" : ""}`;
    // const maxNumberSentSimilarForkSFClass = `form-control${this.state.maxNumberSentSimilarForkSF.hasError ? " is-invalid" : ""}`;
    const maxNumberBetsOneGameSFClass = `form-control${this.state.maxNumberBetsOneGameSF.hasError ? " is-invalid" : ""}`;
    const maxNumberBetsOneTimeSFClass = `form-control${this.state.maxNumberBetsOneTimeSF.hasError ? " is-invalid" : ""}`;
    const maxNumberBetsOneOutcomeSFClass = `form-control${this.state.maxNumberBetsOneOutcomeSF.hasError ? " is-invalid" : ""}`;
    const minLifetimeForkSFClass = `form-control${this.state.minLifetimeForkSF.hasError ? " is-invalid" : ""}`;
    const maxLifetimeForkSFClass = `form-control${this.state.maxLifetimeForkSF.hasError ? " is-invalid" : ""}`;
    const minPercentInitForkSFClass = `form-control${this.state.minPercentInitForkSF.hasError ? " is-invalid" : ""}`;
    const maxPercentInitForkSFClass = `form-control${this.state.maxPercentInitForkSF.hasError ? " is-invalid" : ""}`;
    const minCoefOneShoulderForkClass = `form-control${this.state.minCoefOneShoulderFork.hasError ? " is-invalid" : ""}`;
    const maxCoefOneShoulderForkClass = `form-control${this.state.maxCoefOneShoulderFork.hasError ? " is-invalid" : ""}`;
    // const moneyOneShoulderForkClass = `form-control${this.state.moneyOneShoulderFork.hasError ? " is-invalid" : ""}`;
    const glMoneyClassOSF = `form-control${this.state.globalMoneyOSF.hasError ? " is-invalid" : ""}`;
    const minPercentFork1SFClass = `form-control${this.state.minPercentFork1SF.hasError ? " is-invalid" : ""}`;
    const maxPercentFork1SFClass = `form-control${this.state.maxPercentFork1SF.hasError ? " is-invalid" : ""}`;
    const minCoefFork1SFClass = `form-control${this.state.minCoefFork1SF.hasError ? " is-invalid" : ""}`;
    const maxCoefFork1SFClass = `form-control${this.state.maxCoefFork1SF.hasError ? " is-invalid" : ""}`;
    const minPercentFork2SFClass = `form-control${this.state.minPercentFork2SF.hasError ? " is-invalid" : ""}`;
    const maxPercentFork2SFClass = `form-control${this.state.maxPercentFork2SF.hasError ? " is-invalid" : ""}`;
    const minCoefFork2SFClass = `form-control${this.state.minCoefFork2SF.hasError ? " is-invalid" : ""}`;
    const maxCoefFork2SFClass = `form-control${this.state.maxCoefFork2SF.hasError ? " is-invalid" : ""}`;

    const book1 = this.state.bookmakerFirst.replace('stake', 'pinnacle_2').replace('gorilla', 'newgioco').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('efbet', 'ifortuna').replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('unikrn', 'rabet777').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('betflag', 'inbet365').replace('omg', '212bet').replace('sbobet', 'simbet')
    const book2 = this.state.bookmakerSecond.replace('stake', 'pinnacle_2').replace('gorilla', 'newgioco').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('efbet', 'ifortuna').replace('loot', 'nitrobetting').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('unikrn', 'rabet777').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('betflag', 'inbet365').replace('omg', '212bet').replace('sbobet', 'simbet')

    // Список цветов
    const colors = [
      { name: "", value: "#fafafa" },
      { name: "", value: "#d9d9d9" },
      { name: "", value: "#ffbb96" },
      { name: "", value: "#ffd591" },
      { name: "", value: "#fffb8f" },
      { name: "", value: "#eaff8f" },
      { name: "", value: "#b7eb8f" },
      { name: "", value: "#87e8de" },
      { name: "", value: "#91caff" },
      { name: "", value: "#d3adf7" },
      { name: "", value: "#ffadd2" },

      // { name: "", value: "#A9A9A9" },
      // { name: "", value: "#7FFF00" },
      // { name: "", value: "#32CD32" },
      // { name: "", value: "#FFDEAD" },
      // { name: "", value: "#DAA520" },
      // { name: "", value: "#D2691E" },
      // { name: "", value: "#A52A2A" },
      // { name: "", value: "#FF69B4" },
      // { name: "", value: "#00FFFF" },
      // { name: "", value: "#20B2AA" },
      // { name: "", value: "#1E90FF" },
    ];

    return (
      <>
        <div
          className="modal fade bs-example-modal-lg modal-profile"
          id="myModalAdd"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Add new bot
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form className="form-label-left input_mask" onSubmit={this.sendCreateForm}>


                <div className="modal-body">

                  <div className="form-row">
                    <div className="form-group col-md-5 col-sm-5">
                      <label>Bot Name</label>
                      <input
                        value={this.state.name.value}
                        onChange={this.changeNameBot}
                        type="text"
                        className={botnameClass}
                      />
                      {this.state.name.hasError && (
                        <small className="text-danger">Invalid bot name</small>
                      )}
                    </div>
                    <div className="form-group col-md-5 col-sm-5">
                      <label>Description</label>
                      <input
                        value={this.state.description.value}
                        onChange={this.changeDescription}
                        type="text"
                        className={descripClass}
                      />
                      {this.state.description.hasError && (
                        <small className="text-danger">Invalid description</small>
                      )}
                    </div>
                    <div className="form-group col-md-2 col-sm-2">
                      <label>Color</label>
                      <Select
                        // style={{ width: 200 }}
                        style={{
                          width: '100%',
                        }}
                        // className={"form-control"}
                        // placeholder="Выберите цвет"
                        defaultValue={'#fafafa'}
                        onChange={this.changeColor}
                      >
                        {colors.map((color) => (
                          <Option key={color.value} value={color.value}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  backgroundColor: color.value,
                                  border: "1px solid #ddd",
                                }}
                              />
                              {color.name}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>


                  <div className="form-row">
                    <div className="form-group col-md-12 col-sm-12">
                      <Card>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Не перекрывать второе плечо(первое перекроется)</label>
                                <div className="custom-control custom-switch form-control control-no-border">
                                <input
                                    checked={this.state.isNotOverlapSecondShoulder}
                                    // defaultChecked={this.state.isNotOverlapSecondShoulder}
                                    onChange={this.changeIsNotOverlapSecondShoulder}
                                    type="checkbox"
                                    className="custom-control-input input-pointer"
                                    id="isNotOverlapSecondShoulder"
                                  />
                                  <label className="custom-control-label input-pointer" htmlFor="isNotOverlapSecondShoulder"><span
                                    className={this.state.isNotOverlapSecondShoulder ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isNotOverlapSecondShoulder ? 'YES' : 'NO'}</span></label>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Не перекрывать оба плеча(первое и второе)</label>
                                <div className="custom-control custom-switch form-control control-no-border">
                                  <input
                                    checked={this.state.isNotOverlapTwoShoulders}
                                    // defaultChecked={this.state.isNotOverlapTwoShoulders}
                                    onChange={this.changeIsNotOverlapTwoShoulders}
                                    type="checkbox"
                                    className="custom-control-input input-pointer"
                                    id="isNotOverlapTwoShoulders"
                                  />
                                  <label className="custom-control-label input-pointer" htmlFor="isNotOverlapTwoShoulders"><span
                                    className={this.state.isNotOverlapTwoShoulders ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isNotOverlapTwoShoulders ? 'YES' : 'NO'}</span></label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Ставить 1$ на второе плечо</label>
                                <div className="custom-control custom-switch form-control control-no-border">
                                  <input
                                    checked={this.state.isPutOneSecondShoulder}
                                    // defaultChecked={this.state.isPutOneSecondShoulder}
                                    onChange={this.changeIsPutOneSecondShoulder}
                                    type="checkbox"
                                    className="custom-control-input input-pointer"
                                    id="isPutOneSecondShoulder"
                                  />
                                  <label className="custom-control-label input-pointer" htmlFor="isPutOneSecondShoulder"><span
                                    className={this.state.isPutOneSecondShoulder ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isPutOneSecondShoulder ? 'YES' : 'NO'}</span></label>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Decimal/American кефы</label>
                      <div className="custom-control custom-switch form-control control-no-border">
                        <input
                          checked={this.state.isAmericanCoef}
                          // defaultChecked={this.state.isAmericanCoef}
                          onChange={this.changeIsAmericanCoef}
                          type="checkbox"
                          className="custom-control-input input-pointer"
                          id="isAmericanCoef"
                        />
                        <label className="custom-control-label input-pointer" htmlFor="isAmericanCoef"><span
                          className={this.state.isAmericanCoef ? 'badge badge-primary' : 'badge badge-dark'}>{this.state.isAmericanCoef ? 'AMERICAN' : 'DECIMAL'}</span></label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Зеркалить и ставить на 2 плечо</label>
                      <div className="custom-control custom-switch form-control control-no-border">
                        <input
                          checked={this.state.isMirrorBets}
                          // defaultChecked={this.state.isMirrorBets}
                          onChange={this.changeIsMirrorBets}
                          type="checkbox"
                          className="custom-control-input input-pointer"
                          id="isMirrorBets"
                        />
                        <label className="custom-control-label input-pointer" htmlFor="isMirrorBets"><span
                          className={this.state.isMirrorBets ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isMirrorBets ? 'YES' : 'NO'}</span></label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>בט מקסימלי המותר באתר אבל לא יותר מהקבוע</label>
                      <div className="custom-control custom-switch form-control control-no-border">
                        <input
                          checked={this.state.isBetMaxAmount}
                          // defaultChecked={this.state.isBetMaxAmount}
                          onChange={this.changeIsBetMaxAmount}
                          type="checkbox"
                          className="custom-control-input input-pointer"
                          id="isBetMaxAmount"
                        />
                        <label className="custom-control-label input-pointer" htmlFor="isBetMaxAmount"><span
                          className={this.state.isBetMaxAmount ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isBetMaxAmount ? 'YES' : 'NO'}</span></label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>המרווח הראשון לעצירת הרובוט</label>
                      {/*<label>1-ый временной интервал остановки бота</label>*/}
                      <div className="custom-control custom-switch form-control control-no-border">
                        <input
                          checked={this.state.isFirstBotStopTimeInterval}
                          // defaultChecked={this.state.isFirstBotStopTimeInterval}
                          onChange={this.changeIsFirstBotStopTimeInterval}
                          type="checkbox"
                          className="custom-control-input input-pointer"
                          id="isFirstBotStopTimeInterval"
                        />
                        <label className="custom-control-label input-pointer" htmlFor="isFirstBotStopTimeInterval"><span
                          className={this.state.isFirstBotStopTimeInterval ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isFirstBotStopTimeInterval ? 'YES' : 'NO'}</span></label>
                      </div>
                    </div>

                    {this.state.isFirstBotStopTimeInterval ? (
                      <div className="form-group col-md-6 col-sm-6">
                        <label>Интервал в течении которого бот не ставит ставки</label>
                        <Space style={{width: '100%'}}>
                          <TimePicker.RangePicker
                            showNow={false}
                            // secondStep={10}
                            value={[this.state.firstBotStopTimeInterval[0] ? dayjs(this.state.firstBotStopTimeInterval[0], formatTime) : '', this.state.firstBotStopTimeInterval[1] ? dayjs(this.state.firstBotStopTimeInterval[1], formatTime) : '']}
                            defaultValue={[this.state.firstBotStopTimeInterval[0] ? dayjs(this.state.firstBotStopTimeInterval[0], formatTime) : '', this.state.firstBotStopTimeInterval[1] ? dayjs(this.state.firstBotStopTimeInterval[1], formatTime) : '']}
                            format={formatTime}
                            // order={false}
                            onChange={this.changeFirstBotStopTimeInterval}
                            // style={{width: '100%'}}
                          />
                        </Space>
                      </div>
                    ) : (
                      <div className="form-group col-md-6 col-sm-6"></div>
                    )}
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>המרווח השני לעצירת הרובוט</label>
                      {/*<label>2-ой временной интервал остановки бота</label>*/}
                      <div className="custom-control custom-switch form-control control-no-border">
                        <input
                          checked={this.state.isSecondBotStopTimeInterval}
                          // defaultChecked={this.state.isSecondBotStopTimeInterval}
                          onChange={this.changeIsSecondBotStopTimeInterval}
                          type="checkbox"
                          className="custom-control-input input-pointer"
                          id="isSecondBotStopTimeInterval"
                        />
                        <label className="custom-control-label input-pointer" htmlFor="isSecondBotStopTimeInterval"><span
                          className={this.state.isSecondBotStopTimeInterval ? "badge badge-success" : "badge badge-danger"}>{this.state.isSecondBotStopTimeInterval ? "YES" : "NO"}</span></label>
                      </div>
                    </div>

                    {this.state.isSecondBotStopTimeInterval ? (
                      <div className="form-group col-md-6 col-sm-6">
                        <label>Интервал в течении которого бот не ставит ставки</label>
                        <Space style={{width: '100%'}}>
                          <TimePicker.RangePicker
                            showNow={false}
                            // secondStep={10}
                            value={[this.state.secondBotStopTimeInterval[0] ? dayjs(this.state.secondBotStopTimeInterval[0], formatTime) : '', this.state.secondBotStopTimeInterval[1] ? dayjs(this.state.secondBotStopTimeInterval[1], formatTime) : '']}
                            defaultValue={[this.state.secondBotStopTimeInterval[0] ? dayjs(this.state.secondBotStopTimeInterval[0], formatTime) : '', this.state.secondBotStopTimeInterval[1] ? dayjs(this.state.secondBotStopTimeInterval[1], formatTime) : '']}
                            format={formatTime}
                            // order={false}
                            onChange={this.changeSecondBotStopTimeInterval}
                            // style={{width: '100%'}}
                          />
                        </Space>
                      </div>
                    ) : (
                      <div className="form-group col-md-6 col-sm-6"></div>
                    )}
                  </div>


                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Перерыв перед отправкой следующей ставки(секунды)</label>
                      {/*<label>Перерыв перед отправкой следующей ставки, когда предыдущая успешно поставилась(секунды)</label>*/}
                      <input
                        placeholder="3"
                        value={this.state.delayBeforeSending.value}
                        onChange={this.changeDelayBeforeSending}
                        type="text"
                        className={delayBeforeSendingClass}
                      />
                      {this.state.delayBeforeSending.hasError && (
                        <small className="text-danger">Invalid break between sent bets</small>
                      )}
                    </div>
                    <div className="form-group col-md-6 col-sm-6"></div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12 col-sm-12">
                      <Card>
                        <Card.Header>AdsPower</Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>IP</label>
                                    <input
                                      value={this.state.ipOctoBrowser.value}
                                      onChange={this.changeIpOctoBrowser}
                                      type="text"
                                      className={ipOctoBrowserClass}
                                    />
                                    {this.state.ipOctoBrowser.hasError && (
                                      <small className="text-danger">Invalid IP</small>
                                    )}
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Port</label>
                                    <input
                                      // placeholder="58888"
                                      value={this.state.portOctoBrowser.value}
                                      onChange={this.changePortOctoBrowser}
                                      type="text"
                                      className={portOctoBrowserClass}
                                    />
                                    {this.state.portOctoBrowser.hasError && (
                                      <small className="text-danger">Invalid Port</small>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>API Token</label>
                                <input
                                  value={this.state.tokenOctoBrowser.value}
                                  onChange={this.changeTokenOctoBrowser}
                                  type="text"
                                  className={tokenOctoBrowserClass}
                                />
                                {this.state.tokenOctoBrowser.hasError && (
                                  <small className="text-danger">Invalid Token</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <Card>
                        <Card.Header>
                          <div className="row">
                            <div className="col-md-8 col-sm-8">
                              Bookmaker <strong>{book1}</strong>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              {(this.state.bookmakerFirst === 'pinnacle') && (
                                // {(this.state.bookmakerFirst === 'pinnacle' || this.state.bookmakerFirst === 'bwin_it') && (
                                <>
                                  <label><strong>API</strong></label>
                                  <div className="custom-control custom-switch control-no-border float-right">
                                    <input
                                      checked={this.state.isBetsApi1}
                                      onChange={this.changeIsBetsApi1}
                                      type="checkbox"
                                      className="custom-control-input input-pointer"
                                      id="isBetsApi1"
                                    />
                                    <label className="custom-control-label input-pointer" htmlFor="isBetsApi1"><span
                                      className={this.state.isBetsApi1 ? "badge badge-success" : "badge badge-danger"}>{this.state.isBetsApi1 ? "YES" : "NO"}</span></label>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Bookmaker</label>
                                <select
                                  className="form-control input-pointer"
                                  defaultValue={this.state.bookmakerFirst}
                                  onChange={this.changeBookmakerFirst}
                                >


                                  {this.state.botType === 'waiting_forks' ? (
                                    <>
                                      <option key="pinnacle" value="pinnacle">pinnacle</option>
                                      <option key="bwin_it" value="bwin_it">superbet_ro</option>
                                      {/*<option key="gorilla" value="gorilla">newgioco</option>*/}
                                    </>
                                  ) : (
                                    <>
                                      <option key="pinnacle" value="pinnacle">pinnacle</option>
                                      <option key="bet365" value="bet365">bet365</option>
                                      <option key="sbobet" value="sbobet">simbet</option>
                                      <option key="unibet" value="unibet">winbet88</option>
                                      <option key="pinup" value="pinup">betbetter</option>
                                      <option key="unikrn" value="unikrn">rabet777</option>
                                      <option key="omg" value="omg">212bet</option>
                                      <option key="betflag" value="betflag">inbet365</option>
                                      <option key="ligastavok" value="ligastavok">lottoland</option>
                                      <option key="tonybet" value="tonybet">tipsport</option>
                                      <option key="zenit" value="zenit">chance</option>
                                      <option key="efbet" value="efbet">ifortuna</option>
                                      <option key="planetwin" value="planetwin">planetwin</option>
                                      <option key="eurobet" value="eurobet">eurobet</option>
                                      <option key="bwin_it" value="bwin_it">superbet_ro</option>
                                      {/*<option key="vbet" value="vbet">winnbet</option>*/}
                                      {/*<option key="dafabet_df" value="dafabet_df">winbet_bg</option>*/}
                                      <option key="gorilla" value="gorilla">newgioco</option>
                                      <option key="loot" value="loot">nitrobetting</option>
                                    </>
                                  )}

                                  {/*<option key="bet365" value="bet365">bet365</option>*/}
                                  {/*<option key="betfair" value="betfair">betfair</option>*/}
                                  {/*<option key="bwin" value="bwin">superbet_pl</option>*/}
                                  {/*<option key="bwin_it" value="bwin_it">superbet_ro</option>*/}
                                  {/*<option key="ladbrokes" value="ladbrokes">ladbrokes_be</option>*/}

                                  {/*{(this.state.botType !== 'waiting_forks' && this.state.botType !== 'regular_forks' && this.state.botType !== 'pending_bets') && (*/}
                                  {/*  <>*/}
                                  {/*    <option key="betfair" value="betfair">betfair</option>*/}
                                  {/*    <option key="bwin" value="bwin">superbet_pl</option>*/}
                                  {/*    <option key="bwin_it" value="bwin_it">superbet_ro</option>*/}
                                  {/*    <option key="ladbrokes" value="ladbrokes">ladbrokes_be</option>*/}
                                  {/*  </>*/}
                                  {/*)}*/}
                                  {/*{(this.state.botType !== 'waiting_forks' && this.state.botType !== 'regular_forks') && (*/}
                                  {/*  <option key="stake" value="stake">pinnacle_2</option>*/}
                                  {/*)}*/}
                                  {/*<option key="cloudbet" value="cloudbet">cloudbet</option>*/}
                                  {/*{(this.state.botType !== 'waiting_forks' && this.state.botType !== 'regular_forks') && (*/}
                                  {/*  <option key="1win" value="1win">cloudbet_fifa</option>*/}
                                  {/*)}*/}
                                  {/*{(this.state.botType === 'one_shoulder_forks' ? this.state.singleExpressBetType !== 'single_express_bet' && this.state.singleExpressBetType !== 'express_bet' : true) && (*/}
                                  {/*  <>*/}
                                  {/*    /!*<option key="pinnacle" value="pinnacle">pinnacle</option>*!/*/}
                                  {/*    /!*<option key="stake" value="stake">pinnacle_2</option>*!/*/}
                                  {/*    /!*<option key="cloudbet" value="cloudbet">cloudbet</option>*!/*/}
                                  {/*    {this.props.user.hasAccess('strategies.show_admin') && (<option key="tonybet" value="tonybet">tipsport</option>)}*/}
                                  {/*  </>*/}
                                  {/*)}*/}
                                  {/*{(this.state.botType !== 'waiting_forks' && this.state.botType !== 'regular_forks') && (*/}
                                  {/*  <option key="parimatch" value="parimatch">parimatch</option>*/}
                                  {/*)}*/}
                                  {/*<option key="fonbet" value="fonbet">fonbet</option>*/}
                                  {/*<option key="1xbit" value="1xbit">1xbit</option>*/}
                                </select>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Currency</label>
                                <select
                                  className="form-control input-pointer"
                                  defaultValue={this.state.currencyFirst}
                                  onChange={this.changeCurrencyFirst}
                                >
                                  <option key="USD" value="USD">USD</option>
                                  <option key="EUR" value="EUR">EUR</option>
                                  <option key="GBP" value="GBP">GBP</option>
                                  <option key="MXN" value="MXN">MXN</option>
                                  <option key="ILS" value="ILS">ILS</option>
                                  <option key="Х400" value="Х400">Х400$</option>
                                  <option key="Х10" value="Х10">Х10$</option>
                                  <option key="CZK" value="CZK">CZK</option>
                                  <option key="MBT" value="MBT">mBTC</option>
                                  <option key="RON" value="RON">RON</option>
                                  <option key="BGN" value="BGN">BGN</option>
                                  {/*<option key="RUB" value="RUB">RUB</option>*/}
                                  {/*<option key="CZK" value="CZK">CZK</option>*/}
                                  {/*<option key="BRL" value="BRL">BRL</option>*/}
                                </select>
                                {this.state.currencyFirst === 'Х10' && (
                                  <small className="text-danger">Умножайте на 10</small>
                                )}
                                {this.state.currencyFirst === 'Х400' && (
                                  <small className="text-danger">Умножайте на 400</small>
                                )}

                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              {(this.state.bookmakerFirst === 'cloudbet' || this.state.bookmakerFirst === '1win') ? (
                                <>
                                  <div className="fcol-md-6 col-sm-6">
                                    <label>API Key <strong>{book1}</strong></label>
                                    <input
                                      value={this.state.apiKeyFirst.value}
                                      onChange={this.changeApiKeyFirst}
                                      type="text"
                                      className={apiKeyFirstClass}
                                    />
                                    {this.state.apiKeyFirst.hasError && (
                                      <small className="text-danger">Invalid api key</small>
                                    )}
                                  </div>

                                  <div className="col-md-6 col-sm-6">
                                    <label>Тип валюты для ставки <strong>{book1}</strong></label>
                                    <select
                                      className="form-control input-pointer"
                                      defaultValue={this.state.currencyTypeFirst}
                                      onChange={this.changeCurrencyTypeFirst}
                                    >
                                      <option key="USDT" value="USDT">USDT</option>
                                      <option key="BTC" value="BTC">BTC</option>
                                      <option key="PLAY_EUR" value="PLAY_EUR">PLAY_EUR</option>
                                    </select>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-md-6 col-sm-6"></div>
                                  <div className="col-md-6 col-sm-6"></div>
                                </>
                              )}
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Username</label>
                                <input
                                  value={this.state.usernameFirst.value}
                                  onChange={this.changeUsernameFirst}
                                  type="text"
                                  className={usernameFirstClass}
                                />
                                {this.state.usernameFirst.hasError && (
                                  <small className="text-danger">Invalid user name</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Password</label>
                                <input
                                  value={this.state.passwordFirst.value}
                                  onChange={this.changePasswordFirst}
                                  type="text"
                                  className={passwordFirstClass}
                                />
                                {this.state.passwordFirst.hasError && (
                                  <small className="text-danger">Invalid password</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Autologin</label>
                                <div className="custom-control custom-switch form-control control-no-border">
                                  <input
                                    checked={this.state.isAutologinFirst}
                                    // defaultChecked={this.state.isAutologinFirst}
                                    onChange={this.changeIsAutologinFirst}
                                    type="checkbox"
                                    className="custom-control-input input-pointer"
                                    id="isAutologinFirst"
                                  />
                                  <label className="custom-control-label input-pointer" htmlFor="isAutologinFirst"><span
                                    className={this.state.isAutologinFirst ? "badge badge-success" : "badge badge-danger"}>{this.state.isAutologinFirst ? "YES" : "NO"}</span></label>
                                </div>
                              </div>

                              {this.state.bookmakerFirst === 'bet365' ? (
                                <div className="col-md-6 col-sm-6">
                                  <label>Mirror <strong>{book1}</strong></label>
                                  <select
                                    className="form-control"
                                    defaultValue={this.state.mirrorFirst}
                                    required
                                    onChange={this.changeMirrorFirst}
                                  >
                                    <option key="mirror_first_1" value="https://bet365.com">
                                      bet365.com
                                    </option>
                                    <option key="mirror_first_2" value="https://bet365.it">
                                      bet365.it
                                    </option>
                                    <option key="mirror_first_3" value="https://bet365.mx">
                                      bet365.mx
                                    </option>
                                    <option key="mirror_first_4" value="https://bet365.es">
                                      bet365.es
                                    </option>
                                    <option key="mirror_first_5" value="https://bet365.bet.ar">
                                      bet365.bet.ar
                                    </option>
                                    <option key="mirror_first_6" value="https://bet365.ee">
                                      bet365.ee
                                    </option>
                                    <option key="mirror_first_7" value="https://bet365.gr">
                                      bet365.gr
                                    </option>
                                    <option key="mirror_first_8" value="https://www.bet365.de">
                                      bet365.de
                                    </option>
                                    <option key="mirror_first_9" value="https://365-288.com">
                                      365-288.com
                                    </option>
                                    <option key="mirror_first_10" value="https://www.nj.bet365.com">
                                      nj.bet365.com
                                    </option>
                                    <option key="mirror_first_11" value="https://365-808.com">
                                      365-808.com
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                <>
                                  {this.state.bookmakerFirst === 'ligastavok' ? (
                                    <div className="col-md-6 col-sm-6">
                                      <label>Mirror <strong>{book1}</strong></label>
                                      <select
                                        className="form-control"
                                        defaultValue={this.state.mirrorLottolandFirst}
                                        required
                                        onChange={this.changeMirrorLottolandFirst}
                                      >
                                        <option key="mir_first_1" value="https://www.lottoland.co.uk">
                                          lottoland.co.uk
                                        </option>
                                        <option key="mir_first_2" value="https://www.lottoland.mx">
                                          lottoland.mx
                                        </option>
                                        <option key="mir_first_3" value="https://www.lottoland.com">
                                          lottoland.com
                                        </option>
                                      </select>
                                    </div>
                                  ) : (
                                    <div className="col-md-6 col-sm-6"></div>
                                  )}
                                </>
                              )}

                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                      <Card>
                        <Card.Header>
                          <div className="row">
                            <div className="col-md-8 col-sm-8">
                              Bookmaker <strong>{book2}</strong>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              {(this.state.bookmakerSecond === 'pinnacle') && (
                                // {(this.state.bookmakerSecond === 'pinnacle' || this.state.bookmakerSecond === 'bwin_it') && (
                                <>
                                  <label><strong>API</strong></label>
                                  <div className="custom-control custom-switch control-no-border float-right">
                                    <input
                                      checked={this.state.isBetsApi2}
                                      onChange={this.changeIsBetsApi2}
                                      type="checkbox"
                                      className="custom-control-input input-pointer"
                                      id="isBetsApi2"
                                    />
                                    <label className="custom-control-label input-pointer" htmlFor="isBetsApi2"><span
                                      className={this.state.isBetsApi2 ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isBetsApi2 ? 'YES' : 'NO'}</span></label>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Bookmaker</label>
                                <select
                                  className="form-control input-pointer"
                                  defaultValue={this.state.bookmakerSecond}
                                  onChange={this.changeBookmakerSecond}
                                >


                                  {this.state.botType === 'waiting_forks' ? (
                                    <>
                                      <option key="pinnacle" value="pinnacle">pinnacle</option>
                                      <option key="stake" value="stake">pinnacle_2</option>
                                      <option key="bwin_it" value="bwin_it">superbet_ro</option>
                                      {/*<option key="gorilla" value="gorilla">newgioco</option>*/}
                                    </>
                                  ) : (
                                    <>
                                      <option key="pinnacle" value="pinnacle">pinnacle</option>
                                      <option key="mostbet" value="mostbet">ps3838</option>
                                      <option key="bet365" value="bet365">bet365</option>
                                      <option key="efbet" value="efbet">ifortuna</option>
                                      <option key="omg" value="omg">212bet</option>
                                      <option key="betflag" value="betflag">inbet365</option>
                                      <option key="ligastavok" value="ligastavok">lottoland</option>
                                      <option key="tonybet" value="tonybet">tipsport</option>
                                      <option key="zenit" value="zenit">chance</option>
                                      <option key="bwin_it" value="bwin_it">superbet_ro</option>
                                      {/*<option key="planetwin" value="planetwin">planetwin</option>*/}
                                      {/*<option key="eurobet" value="eurobet">eurobet</option>*/}
                                      {/*<option key="vbet" value="vbet">winnbet</option>*/}
                                      {/*<option key="dafabet_df" value="dafabet_df">winbet_bg</option>*/}
                                      <option key="gorilla" value="gorilla">newgioco</option>
                                    </>
                                  )}


                                  {/*<option key="loot" value="loot">nitrobetting</option>*/}

                                  {/*{(this.state.botType === 'one_shoulder_forks' ? this.state.singleExpressBetType !== 'single_express_bet' && this.state.singleExpressBetType !== 'express_bet' : true) && (*/}
                                  {/*  <option key="bet365" value="bet365">bet365</option>*/}
                                  {/*)}*/}
                                  {/*<option key="bet365" value="bet365">bet365</option>*/}
                                  {/*<option key="betfair" value="betfair">betfair</option>*/}
                                  {/*<option key="bwin" value="bwin">superbet_pl</option>*/}
                                  {/*<option key="bwin_it" value="bwin_it">superbet_ro</option>*/}
                                  {/*<option key="ladbrokes" value="ladbrokes">ladbrokes_be</option>*/}
                                  {/*{(this.state.botType !== 'waiting_forks' && this.state.botType !== 'regular_forks' && this.state.botType !== 'pending_bets') && (*/}
                                  {/*  <>*/}
                                  {/*    <option key="betfair" value="betfair">betfair</option>*/}
                                  {/*    <option key="bwin" value="bwin">superbet_pl</option>*/}
                                  {/*    <option key="bwin_it" value="bwin_it">superbet_ro</option>*/}
                                  {/*    <option key="ladbrokes" value="ladbrokes">ladbrokes_be</option>*/}
                                  {/*  </>*/}
                                  {/*)}*/}
                                  {/*<option key="pinnacle" value="pinnacle">pinnacle</option>*/}
                                  {/*{(this.state.botType === 'one_shoulder_forks' ? this.state.singleExpressBetType !== 'single_express_bet' && this.state.singleExpressBetType !== 'express_bet' : true) && (*/}
                                  {/*  <>*/}
                                  {/*    /!*<option key="pinnacle" value="pinnacle">pinnacle</option>*!/*/}
                                  {/*    /!*<option key="stake" value="stake">pinnacle_2</option>*!/*/}
                                  {/*    /!*<option key="cloudbet" value="cloudbet">cloudbet</option>*!/*/}
                                  {/*    {this.props.user.hasAccess('strategies.show_admin') && (<option key="tonybet" value="tonybet">tipsport</option>)}*/}
                                  {/*  </>*/}
                                  {/*)}*/}
                                  {/*<option key="fonbet" value="fonbet">fonbet</option>*/}
                                  {/*<option key="1xbit" value="1xbit">1xbit</option>*/}
                                </select>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Currency</label>
                                <select
                                  className="form-control input-pointer"
                                  defaultValue={this.state.currencySecond}
                                  onChange={this.changeCurrencySecond}
                                >
                                  <option key="USD" value="USD">USD</option>
                                  <option key="EUR" value="EUR">EUR</option>
                                  <option key="GBP" value="GBP">GBP</option>
                                  <option key="MXN" value="MXN">MXN</option>
                                  <option key="Х400" value="Х400">Х400$</option>
                                  <option key="Х10" value="Х10">Х10$</option>
                                  <option key="CZK" value="CZK">CZK</option>
                                  <option key="MBT" value="MBT">mBTC</option>
                                  <option key="RON" value="RON">RON</option>
                                  <option key="BGN" value="BGN">BGN</option>
                                  {/*<option key="RUB" value="RUB">RUB</option>*/}
                                  {/*<option key="CZK" value="CZK">CZK</option>*/}
                                  {/*<option key="BRL" value="BRL">BRL</option>*/}
                                </select>
                                {this.state.currencySecond === 'Х10' && (
                                  <small className="text-danger">Умножайте на 10</small>
                                )}
                                {this.state.currencySecond === 'Х400' && (
                                  <small className="text-danger">Умножайте на 400</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              {(this.state.bookmakerSecond === 'cloudbet' || this.state.bookmakerSecond === '1win') ? (
                                <>
                                  <div className="fcol-md-6 col-sm-6">
                                    <label>API Key <strong>{book2}</strong></label>
                                    <input
                                      value={this.state.apiKeySecond.value}
                                      onChange={this.changeApiKeySecond}
                                      type="text"
                                      className={apiKeySecondClass}
                                    />
                                    {this.state.apiKeySecond.hasError && (
                                      <small className="text-danger">Invalid api key</small>
                                    )}
                                  </div>

                                  <div className="col-md-6 col-sm-6">
                                    <label>Тип валюты для ставки <strong>{book2}</strong></label>
                                    <select
                                      className="form-control input-pointer"
                                      defaultValue={this.state.currencyTypeSecond}
                                      onChange={this.changeCurrencyTypeSecond}
                                    >
                                      <option key="USDT" value="USDT">USDT</option>
                                      <option key="BTC" value="BTC">BTC</option>
                                      <option key="PLAY_EUR" value="PLAY_EUR">PLAY_EUR</option>
                                    </select>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-md-6 col-sm-6"></div>
                                  <div className="col-md-6 col-sm-6"></div>
                                </>
                              )}
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Username</label>
                                <input
                                  value={this.state.usernameSecond.value}
                                  onChange={this.changeUsernameSecond}
                                  type="text"
                                  className={usernameSecondClass}
                                />
                                {this.state.usernameSecond.hasError && (
                                  <small className="text-danger">Invalid user name</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Password</label>
                                <input
                                  value={this.state.passwordSecond.value}
                                  onChange={this.changePasswordSecond}
                                  type="text"
                                  className={passwordSecondClass}
                                />
                                {this.state.passwordSecond.hasError && (
                                  <small className="text-danger">Invalid password</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Autologin</label>
                                <div className="custom-control custom-switch form-control control-no-border">
                                  <input
                                    checked={this.state.isAutologinSecond}
                                    // defaultChecked={this.state.isAutologinSecond}
                                    onChange={this.changeIsAutologinSecond}
                                    type="checkbox"
                                    className="custom-control-input input-pointer"
                                    id="isAutologinSecond"
                                  />
                                  <label className="custom-control-label input-pointer" htmlFor="isAutologinSecond"><span
                                    className={this.state.isAutologinSecond ? "badge badge-success" : "badge badge-danger"}>{this.state.isAutologinSecond ? "YES" : "NO"}</span></label>
                                </div>
                              </div>

                              {this.state.bookmakerSecond === 'bet365' ? (
                                <div className="col-md-6 col-sm-6">
                                  <label>Mirror <strong>{book2}</strong></label>
                                  <select
                                    className="form-control"
                                    defaultValue={this.state.mirrorSecond}
                                    required
                                    onChange={this.changeMirrorSecond}
                                  >
                                    <option key="mirror_second_1" value="https://bet365.com">
                                      bet365.com
                                    </option>
                                    <option key="mirror_second_2" value="https://bet365.it">
                                      bet365.it
                                    </option>
                                    <option key="mirror_second_3" value="https://bet365.mx">
                                      bet365.mx
                                    </option>
                                    <option key="mirror_second_4" value="https://bet365.es">
                                      bet365.es
                                    </option>
                                    <option key="mirror_second_5" value="https://bet365.bet.ar">
                                      bet365.bet.ar
                                    </option>
                                    <option key="mirror_second_6" value="https://bet365.ee">
                                      bet365.ee
                                    </option>
                                    <option key="mirror_second_7" value="https://bet365.gr">
                                      bet365.gr
                                    </option>
                                    <option key="mirror_second_8" value="https://www.bet365.de">
                                      bet365.de
                                    </option>
                                    <option key="mirror_second_9" value="https://365-288.com">
                                      365-288.com
                                    </option>
                                    <option key="mirror_second_10" value="https://www.nj.bet365.com">
                                      nj.bet365.com
                                    </option>
                                    <option key="mirror_second_11" value="https://365-808.com">
                                      365-808.com
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                <>
                                  {this.state.bookmakerSecond === 'ligastavok' ? (
                                    <div className="col-md-6 col-sm-6">
                                      <label>Mirror <strong>{book2}</strong></label>
                                      <select
                                        className="form-control"
                                        defaultValue={this.state.mirrorLottolandSecond}
                                        required
                                        onChange={this.changeMirrorLottolandSecond}
                                      >
                                        <option key="mir_second_1" value="https://www.lottoland.co.uk">
                                          lottoland.co.uk
                                        </option>
                                        <option key="mir_second_2" value="https://www.lottoland.mx">
                                          lottoland.mx
                                        </option>
                                        <option key="mir_second_3" value="https://www.lottoland.com">
                                          lottoland.com
                                        </option>
                                      </select>
                                    </div>
                                  ) : (
                                    <div className="col-md-6 col-sm-6"></div>
                                  )}
                                </>
                              )}

                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <Card>
                        <Card.Header>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              Proxy <strong>{book1}</strong>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="custom-control custom-switch control-no-border float-right">
                                <input
                                  checked={this.state.isProxy1}
                                  onChange={this.changeIsProxy1}
                                  type="checkbox"
                                  className="custom-control-input input-pointer"
                                  id="isProxy1"
                                />
                                <label className="custom-control-label input-pointer" htmlFor="isProxy1"><span
                                  className={this.state.isProxy1 ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isProxy1 ? 'YES' : 'NO'}</span></label>
                              </div>
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Name</label>
                                <input
                                  value={this.state.proxyName1.value}
                                  onChange={this.changeProxyName1}
                                  type="text"
                                  className={proxyName1Class}
                                  disabled={!this.state.isProxy1}
                                />
                                {this.state.proxyName1.hasError && (
                                  <small className="text-danger">Invalid name</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Protocol Type</label>
                                <select
                                  className="form-control input-pointer"
                                  defaultValue={this.state.proxyProtocol1}
                                  onChange={this.changeProxyProtocol1}
                                  disabled={!this.state.isProxy1}
                                >
                                  <option key="socks5" value="socks5">SOCKS5</option>
                                  <option key="http" value="http">HTTP</option>
                                </select>
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>IP address</label>
                                <input
                                  value={this.state.proxyIp1.value}
                                  onChange={this.changeProxyIp1}
                                  type="text"
                                  className={proxyIp1Class}
                                  disabled={!this.state.isProxy1}
                                />
                                {this.state.proxyIp1.hasError && (
                                  <small className="text-danger">Invalid IP</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Port</label>
                                <input
                                  value={this.state.proxyPort1.value}
                                  onChange={this.changeProxyPort1}
                                  type="text"
                                  className={proxyPort1Class}
                                  disabled={!this.state.isProxy1}
                                />
                                {this.state.proxyPort1.hasError && (
                                  <small className="text-danger">Invalid Port</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Username</label>
                                <input
                                  value={this.state.proxyUsername1.value}
                                  onChange={this.changeProxyUsername1}
                                  type="text"
                                  className={proxyUsername1Class}
                                  disabled={!this.state.isProxy1}
                                />
                                {this.state.proxyUsername1.hasError && (
                                  <small className="text-danger">Invalid Username</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Password</label>
                                <input
                                  value={this.state.proxyPassword1.value}
                                  onChange={this.changeProxyPassword1}
                                  type="text"
                                  className={proxyPassword1Class}
                                  disabled={!this.state.isProxy1}
                                />
                                {this.state.proxyPassword1.hasError && (
                                  <small className="text-danger">Invalid Password</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                      <Card>
                        <Card.Header>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              Proxy <strong>{book2}</strong>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="custom-control custom-switch control-no-border float-right">
                                <input
                                  checked={this.state.isProxy2}
                                  onChange={this.changeIsProxy2}
                                  type="checkbox"
                                  className="custom-control-input input-pointer"
                                  id="isProxy2"
                                />
                                <label className="custom-control-label input-pointer" htmlFor="isProxy2"><span
                                  className={this.state.isProxy2 ? "badge badge-success" : "badge badge-danger"}>{this.state.isProxy2 ? "YES" : "NO"}</span></label>
                              </div>
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Name</label>
                                <input
                                  value={this.state.proxyName2.value}
                                  onChange={this.changeProxyName2}
                                  type="text"
                                  className={proxyName2Class}
                                  disabled={!this.state.isProxy2}
                                />
                                {this.state.proxyName2.hasError && (
                                  <small className="text-danger">Invalid name</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Protocol Type</label>
                                <select
                                  className="form-control input-pointer"
                                  defaultValue={this.state.proxyProtocol2}
                                  onChange={this.changeProxyProtocol2}
                                  disabled={!this.state.isProxy2}
                                >
                                  <option key="socks5" value="socks5">SOCKS5</option>
                                  <option key="http" value="http">HTTP</option>
                                </select>
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>IP address</label>
                                <input
                                  value={this.state.proxyIp2.value}
                                  onChange={this.changeProxyIp2}
                                  type="text"
                                  className={proxyIp2Class}
                                  disabled={!this.state.isProxy2}
                                />
                                {this.state.proxyIp2.hasError && (
                                  <small className="text-danger">Invalid IP</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Port</label>
                                <input
                                  value={this.state.proxyPort2.value}
                                  onChange={this.changeProxyPort2}
                                  type="text"
                                  className={proxyPort2Class}
                                  disabled={!this.state.isProxy2}
                                />
                                {this.state.proxyPort2.hasError && (
                                  <small className="text-danger">Invalid Port</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Username</label>
                                <input
                                  value={this.state.proxyUsername2.value}
                                  onChange={this.changeProxyUsername2}
                                  type="text"
                                  className={proxyUsername2Class}
                                  disabled={!this.state.isProxy2}
                                />
                                {this.state.proxyUsername2.hasError && (
                                  <small className="text-danger">Invalid Username</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Password</label>
                                <input
                                  value={this.state.proxyPassword2.value}
                                  onChange={this.changeProxyPassword2}
                                  type="text"
                                  className={proxyPassword2Class}
                                  disabled={!this.state.isProxy2}
                                />
                                {this.state.proxyPassword2.hasError && (
                                  <small className="text-danger">Invalid Password</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <Card>
                        <Card.Header>Balance <strong>{book1}</strong></Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Deposit</label>
                                <input
                                  value={this.state.deposit1}
                                  onChange={this.changeDeposit1}
                                  type="text"
                                  className="form-control"
                                  required=""
                                />
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Макс. Баланс</label>
                                <input
                                  placeholder="500"
                                  value={this.state.maxBalance1.value}
                                  onChange={this.changeMaxBalance1}
                                  className={maxBalance1Class}
                                  type="text"
                                  required=""
                                />
                                {this.state.maxBalance1.hasError && (
                                  <small className="text-danger">Invalid max balance</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Макс. сумма ставок</label>
                                <input
                                  placeholder="10000"
                                  value={this.state.maxSumBets1.value}
                                  onChange={this.changeMaxSumBets1}
                                  className={maxSumBets1Class}
                                  type="text"
                                  required=""
                                />
                                {this.state.maxSumBets1.hasError && (
                                  <small className="text-danger">Invalid max money sum</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6"></div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="form-group col-md-6 col-sm-6">
                      <Card>
                        <Card.Header>Balance <strong>{book2}</strong></Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Deposit</label>
                                <input
                                  value={this.state.deposit2}
                                  onChange={this.changeDeposit2}
                                  type="text"
                                  className="form-control"
                                  required=""
                                />
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Макс. Баланс</label>
                                <input
                                  placeholder="500"
                                  value={this.state.maxBalance2.value}
                                  onChange={this.changeMaxBalance2}
                                  className={maxBalance2Class}
                                  type="text"
                                  required=""
                                />
                                {this.state.maxBalance2.hasError && (
                                  <small className="text-danger">Invalid max balance</small>
                                )}
                              </div>
                            </div>
                          </Card.Text>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>Макс. сумма ставок</label>
                                <input
                                  placeholder="10000"
                                  value={this.state.maxSumBets2.value}
                                  onChange={this.changeMaxSumBets2}
                                  className={maxSumBets2Class}
                                  type="text"
                                  required=""
                                />
                                {this.state.maxSumBets2.hasError && (
                                  <small className="text-danger">Invalid max money sum</small>
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6"></div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>


                  <div className="form-row">
                    <div className="form-group col-md-12 col-sm-12">
                      <label>Тип бота</label>
                      <div className="btn-group btn-group-toggle col-md-12 col-sm-12">
                        <label className={`btn input-pointer btn-secondary${this.state.botType === 'regular_forks' ? ' active' : ''}`}>
                          <input
                            value="regular_forks"
                            id="regular_forks"
                            checked={this.state.botType === "regular_forks"}
                            // defaultChecked={this.state.botType === "regular_forks"}
                            onChange={this.changeBotType}
                            name="botType"
                            type="radio"
                            autoComplete="off"
                          />
                          Вилки
                        </label>
                        {this.props.user.hasAccess('strategies.show_admin') && (
                          <label className={`btn input-pointer btn-secondary${this.state.botType === 'waiting_forks' ? ' active' : ''}`}>
                            <input
                              value="waiting_forks"
                              id="waiting_forks"
                              checked={this.state.botType === "waiting_forks"}
                              // defaultChecked={this.state.botType === "waiting_forks"}
                              onChange={this.changeBotType}
                              name="botType"
                              type="radio"
                              autoComplete="off"
                            />
                            Вилки с выжиданием(1x2)
                          </label>
                        )}
                        {/*<label className={`btn input-pointer btn-secondary${this.state.botType === 'one_shoulder_forks' ? ' active' : ''}`}>*/}
                        {/*  <input*/}
                        {/*    value="one_shoulder_forks"*/}
                        {/*    id="one_shoulder_forks"*/}
                        {/*    checked={this.state.botType === "one_shoulder_forks"}*/}
                        {/*    // defaultChecked={this.state.botType === "regular_forks"}*/}
                        {/*    onChange={this.changeBotType}*/}
                        {/*    name="botType"*/}
                        {/*    type="radio"*/}
                        {/*    autoComplete="off"*/}
                        {/*  />*/}
                        {/*  Вилки в одно плечо*/}
                        {/*</label>*/}
                        {/*{this.props.user.hasAccess('strategies.show_admin') && (*/}
                        {/*    <label className={`btn input-pointer btn-secondary${this.state.botType === 'pending_bets' ? ' active' : ''}`}>*/}
                        {/*      <input*/}
                        {/*          value="pending_bets"*/}
                        {/*          id="pending_bets"*/}
                        {/*          checked={this.state.botType === "pending_bets"}*/}
                        {/*          // defaultChecked={this.state.botType === "pending_bets"}*/}
                        {/*          onChange={this.changeBotType}*/}
                        {/*          name="botType"*/}
                        {/*          type="radio"*/}
                        {/*          autoComplete="off"*/}
                        {/*      />*/}
                        {/*      Отложенные ставки в одно плечо*/}
                        {/*    </label>*/}
                        {/*)}*/}
                      </div>
                    </div>
                  </div>

                  {this.state.botType === 'waiting_forks' && (
                    <>
                      <div className="form-row">
                        <div className="form-group col-md">
                          <label>Список спортов для формирования вилки</label>
                          <div className="btn-group btn-sm col-md">
                            <WaitingForkSportsComponent
                              type="checkbox"
                              active={this.state.waitingForkSports}
                              setSports={this.setWaitingForkSports}
                            />
                          </div>
                          {this.state.waitingForkSportsEmpty && (
                            <small className="text-danger">Please select sports</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Минимальная маржа вилки(проценты)</label>
                          <input
                            placeholder="-5"
                            value={this.state.minPercentForkWaitingFork.value}
                            onChange={this.changeMinPercentForkWaitingFork}
                            type="text"
                            className={minPercentForkWaitingForkClass}
                          />
                          {this.state.minPercentForkWaitingFork.hasError && (
                            <small className="text-danger">Invalid minimum margin</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Максимальная маржа вилки(проценты)</label>
                          <input
                            placeholder="30"
                            value={this.state.maxPercentForkWaitingFork.value}
                            onChange={this.changeMaxPercentForkWaitingFork}
                            type="text"
                            className={maxPercentForkWaitingForkClass}
                          />
                          {this.state.maxPercentForkWaitingFork.hasError && (
                            <small className="text-danger">Invalid maximum margin</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Мин. коэффициент <strong>{book1}</strong></label>
                          <input
                            placeholder="1.0"
                            value={this.state.minCoefWaitingFork.value}
                            onChange={this.changeMinCoefWaitingFork}
                            type="text"
                            className={minCoefWaitingForkClass}
                          />
                          {this.state.minCoefWaitingFork.hasError && (
                            <small className="text-danger">Invalid min coefficient</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Макс. коэффициент <strong>{book1}</strong></label>
                          <input
                            placeholder="5.0"
                            value={this.state.maxCoefWaitingFork.value}
                            onChange={this.changeMaxCoefWaitingFork}
                            type="text"
                            className={maxCoefWaitingForkClass}
                          />
                          {this.state.maxCoefWaitingFork.hasError && (
                            <small className="text-danger">Invalid max coefficient</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Amount First Bet</label>
                          <input
                            placeholder="10"
                            value={this.state.moneyWaitingFork.value}
                            onChange={this.changeMoneyWaitingFork}
                            type="text"
                            className={moneyWaitingForkClass}
                          />
                          {this.state.moneyWaitingFork.hasError && (
                            <small className="text-danger">Invalid money</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-12 col-sm-12">
                          <label>Включить "Trailing Stop" (при включении перестает работать "Stop Loss" & "Take Profit")</label>
                          <div className="custom-control custom-switch form-control control-no-border">
                            {/*<label className="custom-control-label" htmlFor="isTrailingStop">Stop Loss & Take Profit</label>*/}
                            <input
                              checked={this.state.isTrailingStop}
                              // defaultChecked={this.state.isTrailingStop}
                              // onChange={this.changeIsTrailingStop}
                              type="checkbox"
                              className="custom-control-input input-pointer"
                              id="isTrailingStop"
                            />
                            <label className="custom-control-label input-pointer" htmlFor="isTrailingStop"><span
                              className={this.state.isTrailingStop ? "badge badge-success" : "badge badge-danger"}>{this.state.isTrailingStop ? "YES" : "NO"}</span></label>
                          </div>
                        </div>
                      </div>

                      {this.state.isTrailingStop ? (
                        <div className="form-row">
                          <div className="form-group col-md-6 col-sm-6">
                            <label>Шаг "Trailing Stop" при марже меньше 0(-)</label>
                            <input
                              placeholder="20"
                              value={this.state.minusStepTrailingStop.value}
                              onChange={this.changeMinusStepTrailingStop}
                              type="text"
                              className={minusStepTrailingStopClass}
                            />
                            {this.state.minusStepTrailingStop.hasError && (
                              <small className="text-danger">Invalid step trailing stop</small>
                            )}
                          </div>
                          <div className="form-group col-md-6 col-sm-6">
                            <label>Шаг "Trailing Stop" при марже больше 0(+)</label>
                            <input
                              placeholder="5"
                              value={this.state.plusStepTrailingStop.value}
                              onChange={this.changePlusStepTrailingStop}
                              type="text"
                              className={plusStepTrailingStopClass}
                            />
                            {this.state.plusStepTrailingStop.hasError && (
                              <small className="text-danger">Invalid step trailing stop</small>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="form-row">
                          <div className="form-group col-md-6 col-sm-6">
                            <label>Take Profit (проценты)</label>
                            <input
                              placeholder="10"
                              value={this.state.takeProfit.value}
                              onChange={this.changeTakeProfit}
                              type="text"
                              className={takeProfitClass}
                            />
                            {this.state.takeProfit.hasError && (
                              <small className="text-danger">Invalid take profit</small>
                            )}
                          </div>
                          <div className="form-group col-md-6 col-sm-6">
                            <label>Stop Loss (проценты)</label>
                            <input
                              placeholder="-20"
                              value={this.state.stopLoss.value}
                              onChange={this.changeStopLoss}
                              type="text"
                              className={stopLossClass}
                            />
                            {this.state.stopLoss.hasError && (
                              <small className="text-danger">Invalid stop loss</small>
                            )}
                          </div>
                        </div>
                      )}

                    </>
                  )}

                  {this.state.botType === 'regular_forks' && (
                    <>
                      <div className="form-row">
                        <div className="form-group col-md">
                          <label>Список спортов для формирования вилки</label>
                          <div className="btn-group btn-sm col-md">
                            <RegularForkSportsComponent
                              type="checkbox"
                              active={this.state.regularForkSports}
                              setSports={this.setRegularForkSports}
                            />
                          </div>
                          {this.state.regularForkSportsEmpty && (
                            <small className="text-danger">Please select sports</small>
                          )}
                        </div>

                        <div className="form-group col-md">
                          <label>Можно выбрать определенные исходы по каждому спорту</label>
                          <div className="btn-group btn-sm col-md">
                            <MoneyLineOutcomesComponent
                              type="checkbox"
                              active={this.state.moneyLineOutcomesRF}
                              setSports={this.setMoneyLineOutcomesRF}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <TotalsOutcomesComponent
                              type="checkbox"
                              active={this.state.totalsOutcomesRF}
                              setSports={this.setTotalsOutcomesRF}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <IndTotalsOutcomesComponent
                              type="checkbox"
                              active={this.state.indTotalsOutcomesRF}
                              setSports={this.setIndTotalsOutcomesRF}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <HandicapsOutcomesComponent
                              type="checkbox"
                              active={this.state.handicapsOutcomesRF}
                              setSports={this.setHandicapsOutcomesRF}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <WinnerOutcomesComponent
                              type="checkbox"
                              active={this.state.winnerOutcomesRF}
                              setSports={this.setWinnerOutcomesRF}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <AdditionalsOutcomesComponent
                              type="checkbox"
                              active={this.state.additionalsOutcomesRF}
                              setSports={this.setAdditionalsOutcomesRF}
                            />
                          </div>
                        </div>
                      </div>


                      <div className="form-row">
                        <div className="form-group col-md-12 col-sm-12">
                          <Card>
                            <Card.Header>Отключить исходы</Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>לנתק קרנות בכדורגל</label>
                                    {/*<label>Отключить "Corners" в футболе</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isDisableCornersFootball}
                                        // defaultChecked={this.state.isDisableCornersFootball}
                                        onChange={this.changeIsDisableCornersFootball}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isDisableCornersFootball"
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isDisableCornersFootball"><span
                                        className={this.state.isDisableCornersFootball ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isDisableCornersFootball ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>לבטל פיקים עגולים 0,1,2,3</label>
                                    {/*<label>Отключить круглые форы(0, 1, 2)</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isDisableIntegerHandicap}
                                        // defaultChecked={this.state.isDisableIntegerHandicap}
                                        onChange={this.changeIsDisableIntegerHandicap}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isDisableIntegerHandicap"
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isDisableIntegerHandicap"><span
                                        className={this.state.isDisableIntegerHandicap ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isDisableIntegerHandicap ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                </div>
                              </Card.Text>
                              <Card.Text>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>לבטל הימורים במחצית הראשונה</label>
                                    {/*<label>Отключить "1st Half" в футболе</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isDisableOneHalfFootball}
                                        // defaultChecked={this.state.isDisableOneHalfFootball}
                                        onChange={this.changeIsDisableOneHalfFootball}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isDisableOneHalfFootball"
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isDisableOneHalfFootball"><span
                                        className={this.state.isDisableOneHalfFootball ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isDisableOneHalfFootball ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>לבטל פיקים 0.25,0.75</label>
                                    {/*<label>Отключить четвертные форы(0.25, 0.75)</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isDisableQuarterHandicap}
                                        // defaultChecked={this.state.isDisableQuarterHandicap}
                                        onChange={this.changeIsDisableQuarterHandicap}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isDisableQuarterHandicap"
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isDisableQuarterHandicap"><span
                                        className={this.state.isDisableQuarterHandicap ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isDisableQuarterHandicap ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-12 col-sm-12">
                          <Card>
                            <Card.Header>Ставить только</Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>לשים רק פלוסים</label>
                                    {/*<label>Ставить только "+" форы</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isPlaceOnlyPlusHandicaps}
                                        // defaultChecked={this.state.isPlaceOnlyPlusHandicaps}
                                        onChange={this.changeIsPlaceOnlyPlusHandicaps}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isPlaceOnlyPlusHandicaps"
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isPlaceOnlyPlusHandicaps"><span
                                        className={this.state.isPlaceOnlyPlusHandicaps ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isPlaceOnlyPlusHandicaps ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6"></div>
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>


                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <Card>
                            <Card.Header>
                              <div className="row">
                                <div className="col-md-12 col-sm-12">
                                  Не ставить Under/Over, но ставить все остальное на <strong>{book1}</strong>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>לא לשים אנדר</label>
                                    {/*<label>Не ставить Under</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isNotPutUnder}
                                        // defaultChecked={this.state.isNotPutUnder}
                                        onChange={this.changeIsNotPutUnder}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isNotPutUnder"
                                        disabled={this.state.isNotPutOver}
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isNotPutUnder"><span
                                        className={this.state.isNotPutUnder ? 'badge badge-primary' : 'badge badge-warning'}>{this.state.isNotPutUnder ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>לא לשים עובר</label>
                                    {/*<label>Не ставить Over</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isNotPutOver}
                                        // defaultChecked={this.state.isNotPutOver}
                                        onChange={this.changeIsNotPutOver}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isNotPutOver"
                                        disabled={this.state.isNotPutUnder}
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isNotPutOver"><span
                                        className={this.state.isNotPutOver ? 'badge badge-primary' : 'badge badge-warning'}>{this.state.isNotPutOver ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="form-group col-md-6 col-sm-6">
                          <Card>
                            <Card.Header>
                              <div className="row">
                                <div className="col-md-12 col-sm-12">
                                  Ставить только Under/Over и больше ничего на <strong>{book1}</strong>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>לשים רק אנדר</label>
                                    {/*<label>Ставить только Under</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isPutOnlyUnder}
                                        // defaultChecked={this.state.isPutOnlyUnder}
                                        onChange={this.changeIsPutOnlyUnder}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isPutOnlyUnder"
                                        disabled={this.state.isPutOnlyOver}
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isPutOnlyUnder"><span
                                        className={this.state.isPutOnlyUnder ? 'badge badge-primary' : 'badge badge-warning'}>{this.state.isPutOnlyUnder ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>לשים רק עובר</label>
                                    {/*<label>Ставить только Over</label>*/}
                                    <div className="custom-control custom-switch form-control control-no-border">
                                      <input
                                        checked={this.state.isPutOnlyOver}
                                        // defaultChecked={this.state.isPutOnlyOver}
                                        onChange={this.changeIsPutOnlyOver}
                                        type="checkbox"
                                        className="custom-control-input input-pointer"
                                        id="isPutOnlyOver"
                                        disabled={this.state.isPutOnlyUnder}
                                      />
                                      <label className="custom-control-label input-pointer" htmlFor="isPutOnlyOver"><span
                                        className={this.state.isPutOnlyOver ? 'badge badge-primary' : 'badge badge-warning'}>{this.state.isPutOnlyOver ? 'YES' : 'NO'}</span></label>
                                    </div>
                                  </div>
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>


                      {/*<div className="form-row">*/}
                      {/*  <div className="form-group col-md-12 col-sm-12">*/}
                      {/*    <Card>*/}
                      {/*      <Card.Header>*/}
                      {/*        <div className="row">*/}
                      {/*          <div className="col-md-6 col-sm-6">*/}
                      {/*            Ставить на Over/Under basketball на <strong>{book1}</strong>*/}
                      {/*          </div>*/}
                      {/*          <div className="col-md-6 col-sm-6">*/}
                      {/*            <div className="custom-control custom-switch control-no-border float-right">*/}
                      {/*              <input*/}
                      {/*                checked={this.state.isIncludedOverUnderBasket}*/}
                      {/*                onChange={this.changeIsIncludedOverUnderBasket}*/}
                      {/*                type="checkbox"*/}
                      {/*                className="custom-control-input input-pointer"*/}
                      {/*                id="isIncludedOverUnderBasket"*/}
                      {/*              />*/}
                      {/*              <label className="custom-control-label input-pointer" htmlFor="isIncludedOverUnderBasket"><span*/}
                      {/*                className={this.state.isIncludedOverUnderBasket ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isIncludedOverUnderBasket ? 'YES' : 'NO'}</span></label>*/}
                      {/*            </div>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*      </Card.Header>*/}
                      {/*      <Card.Body>*/}
                      {/*        <Card.Text>*/}
                      {/*          <div className="row">*/}
                      {/*            <div className="col-md-6 col-sm-6">*/}
                      {/*              <label>Выберите Over/Under</label>*/}
                      {/*              <div className="custom-control custom-switch form-control control-no-border">*/}
                      {/*                <input*/}
                      {/*                  checked={this.state.isOverUnderBasket}*/}
                      {/*                  // defaultChecked={this.state.isOverUnderBasket}*/}
                      {/*                  onChange={this.changeIsOverUnderBasket}*/}
                      {/*                  type="checkbox"*/}
                      {/*                  className="custom-control-input input-pointer"*/}
                      {/*                  id="isOverUnderBasket"*/}
                      {/*                  disabled={!this.state.isIncludedOverUnderBasket}*/}
                      {/*                />*/}
                      {/*                <label className="custom-control-label input-pointer" htmlFor="isOverUnderBasket"><span*/}
                      {/*                  className={this.state.isOverUnderBasket ? 'badge badge-primary' : 'badge badge-warning'}>{this.state.isOverUnderBasket ? 'UNDER' : 'OVER'}</span></label>*/}
                      {/*              </div>*/}
                      {/*            </div>*/}
                      {/*            <div className="col-md-6 col-sm-6">*/}
                      {/*            </div>*/}
                      {/*          </div>*/}
                      {/*        </Card.Text>*/}
                      {/*      </Card.Body>*/}
                      {/*    </Card>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="form-row">
                        <div className="form-group col-md-12 col-sm-12">
                          <Card>
                            <Card.Header>
                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  Включить повторы на группу исхода
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="custom-control custom-switch control-no-border float-right">
                                    <input
                                      checked={this.state.isEnableReplay}
                                      onChange={this.changeIsEnableReplay}
                                      type="checkbox"
                                      className="custom-control-input input-pointer"
                                      id="isEnableReplay"
                                    />
                                    <label className="custom-control-label input-pointer" htmlFor="isEnableReplay"><span
                                      className={this.state.isEnableReplay ? 'badge badge-success' : 'badge badge-danger'}>{this.state.isEnableReplay ? 'YES' : 'NO'}</span></label>
                                  </div>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <div className="row">
                                  <div className="col-md-4 col-sm-4">
                                    <label>כמות הימורים למשחק</label>
                                    {/*<label>Ставок на одну игру</label>*/}
                                    <input
                                      placeholder="2"
                                      value={this.state.maxNumberBetsOneGameSF.value}
                                      onChange={this.changeMaxNumberBetsOneGameSF}
                                      type="text"
                                      className={maxNumberBetsOneGameSFClass}
                                      disabled={!this.state.isEnableReplay}
                                    />
                                    {this.state.maxNumberBetsOneGameSF.hasError && (
                                      <small className="text-danger">Invalid number</small>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4">
                                    <label>כמות פעמים להימור זהה</label>
                                    {/*<label>Ставок на один исход</label>*/}
                                    <input
                                      placeholder="1"
                                      value={this.state.maxNumberBetsOneOutcomeSF.value}
                                      onChange={this.changeMaxNumberBetsOneOutcomeSF}
                                      type="text"
                                      className={maxNumberBetsOneOutcomeSFClass}
                                      disabled={!this.state.isEnableReplay}
                                    />
                                    {this.state.maxNumberBetsOneOutcomeSF.hasError && (
                                      <small className="text-danger">Invalid number</small>
                                    )}
                                  </div>
                                  <div className="col-md-4 col-sm-4">
                                    <label>כמות פעמים למחצית</label>
                                    {/*<label>Ставок на тайм</label>*/}
                                    <input
                                      placeholder="1"
                                      value={this.state.maxNumberBetsOneTimeSF.value}
                                      onChange={this.changeMaxNumberBetsOneTimeSF}
                                      type="text"
                                      className={maxNumberBetsOneTimeSFClass}
                                      disabled={!this.state.isEnableReplay}
                                    />
                                    {this.state.maxNumberBetsOneTimeSF.hasError && (
                                      <small className="text-danger">Invalid number</small>
                                    )}
                                  </div>
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>


                      {/*<div className="form-row">*/}
                      {/*  <div className="form-group col-md-6 col-sm-6">*/}
                      {/*    <label>Количество отправленных ставок одного исхода</label>*/}
                      {/*    <input*/}
                      {/*      placeholder="2"*/}
                      {/*      value={this.state.maxNumberSentSimilarForkRF.value}*/}
                      {/*      onChange={this.changeMaxNumberSentSimilarForkRF}*/}
                      {/*      type="text"*/}
                      {/*      className={maxNumberSentSimilarForkRFClass}*/}
                      {/*    />*/}
                      {/*    {this.state.maxNumberSentSimilarForkRF.hasError && (*/}
                      {/*      <small className="text-danger">Invalid number</small>*/}
                      {/*    )}*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Минимальное время жизни вилки(секунды)</label>
                          <input
                            placeholder="3"
                            value={this.state.minLifetimeForkRF.value}
                            onChange={this.changeMinLifetimeForkRF}
                            type="text"
                            className={minLifetimeForkRFClass}
                          />
                          {this.state.minLifetimeForkRF.hasError && (
                            <small className="text-danger">Invalid minimum lifetime fork</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Максимальное время жизни вилки(секунды)</label>
                          <input
                            placeholder="300"
                            value={this.state.maxLifetimeForkRF.value}
                            onChange={this.changeMaxLifetimeForkRF}
                            type="text"
                            className={maxLifetimeForkRFClass}
                          />
                          {this.state.maxLifetimeForkRF.hasError && (
                            <small className="text-danger">Invalid maximum lifetime fork</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Минимальная маржа вилки(проценты)</label>
                          <input
                            placeholder="-5"
                            value={this.state.minPercentInitForkRF.value}
                            onChange={this.changeMinPercentInitForkRF}
                            type="text"
                            className={minPercentInitForkRFClass}
                          />
                          {this.state.minPercentInitForkRF.hasError && (
                            <small className="text-danger">Invalid minimum margin</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Максимальная маржа вилки(проценты)</label>
                          <input
                            placeholder="30"
                            value={this.state.maxPercentInitForkRF.value}
                            onChange={this.changeMaxPercentInitForkRF}
                            type="text"
                            className={maxPercentInitForkRFClass}
                          />
                          {this.state.maxPercentInitForkRF.hasError && (
                            <small className="text-danger">Invalid maximum margin</small>
                          )}
                        </div>
                      </div>


                      {/*<div className="form-row">*/}
                      {/*  <div className="form-group col-md-6 col-sm-6">*/}
                      {/*    <label>Мин. коэффициент вилки</label>*/}
                      {/*    <input*/}
                      {/*      placeholder="1.1"*/}
                      {/*      value={this.state.minCoefForkRF.value}*/}

                      {/*      onChange={this.changeMinCoefForkRF}*/}
                      {/*      type="text"*/}
                      {/*      className={minCoefForkRFClass}*/}
                      {/*    />*/}
                      {/*    {this.state.minCoefForkRF.hasError && (*/}
                      {/*      <small className="text-danger">Invalid min coefficient</small>*/}
                      {/*    )}*/}
                      {/*  </div>*/}
                      {/*  <div className="form-group col-md-6 col-sm-6">*/}
                      {/*    <label>Макс. коэффициент вилки</label>*/}
                      {/*    <input*/}
                      {/*      placeholder="4.5"*/}
                      {/*      value={this.state.maxCoefForkRF.value}*/}
                      {/*      onChange={this.changeMaxCoefForkRF}*/}
                      {/*      type="text"*/}
                      {/*      className={maxCoefForkRFClass}*/}
                      {/*    />*/}
                      {/*    {this.state.maxCoefForkRF.hasError && (*/}
                      {/*      <small className="text-danger">Invalid max coefficient</small>*/}
                      {/*    )}*/}
                      {/*  </div>*/}
                      {/*</div>*/}


                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <Card>
                            <Card.Header>
                              <div className="row">
                                <div className="col-md-12 col-sm-12">
                                  Коэффициент <strong>{book1}</strong>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <div className="row">
                                  <div className="form-group col-md-6 col-sm-6">
                                    <label>Мин. коэффициент</label>
                                    <input
                                      placeholder="1.1"
                                      value={this.state.minCoefForkRF1.value}

                                      onChange={this.changeMinCoefForkRF1}
                                      type="text"
                                      className={minCoefForkRF1Class}
                                    />
                                    {this.state.minCoefForkRF1.hasError && (
                                      <small className="text-danger">Invalid min coefficient</small>
                                    )}
                                  </div>
                                  <div className="form-group col-md-6 col-sm-6">
                                    <label>Макс. коэффициент</label>
                                    <input
                                      placeholder="4.5"
                                      value={this.state.maxCoefForkRF1.value}
                                      onChange={this.changeMaxCoefForkRF1}
                                      type="text"
                                      className={maxCoefForkRF1Class}
                                    />
                                    {this.state.maxCoefForkRF1.hasError && (
                                      <small className="text-danger">Invalid max coefficient</small>
                                    )}
                                  </div>
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="form-group col-md-6 col-sm-6">
                          <Card>
                            <Card.Header>
                              <div className="row">
                                <div className="col-md-12 col-sm-12">
                                  Коэффициент <strong>{book2}</strong>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <div className="row">
                                  <div className="form-group col-md-6 col-sm-6">
                                    <label>Мин. коэффициент</label>
                                    <input
                                      placeholder="1.1"
                                      value={this.state.minCoefForkRF2.value}

                                      onChange={this.changeMinCoefForkRF2}
                                      type="text"
                                      className={minCoefForkRF2Class}
                                    />
                                    {this.state.minCoefForkRF2.hasError && (
                                      <small className="text-danger">Invalid min coefficient</small>
                                    )}
                                  </div>
                                  <div className="form-group col-md-6 col-sm-6">
                                    <label>Макс. коэффициент</label>
                                    <input
                                      placeholder="4.5"
                                      value={this.state.maxCoefForkRF2.value}
                                      onChange={this.changeMaxCoefForkRF2}
                                      type="text"
                                      className={maxCoefForkRF2Class}
                                    />
                                    {this.state.maxCoefForkRF2.hasError && (
                                      <small className="text-danger">Invalid max coefficient</small>
                                    )}
                                  </div>
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>


                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Amount First Bet</label>
                          <input
                            placeholder="10"
                            value={this.state.moneyRegularFork.value}
                            onChange={this.changeMoneyRegularFork}
                            type="text"
                            className={moneyRegularForkClass}
                          />
                          {this.state.moneyRegularFork.hasError && (
                            <small className="text-danger">Invalid money</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Время ожидания перекрытия второго плеча(секунды)</label>
                          <input
                            placeholder="90"
                            value={this.state.maxTimeCloseFork.value}
                            onChange={this.changeMaxTimeCloseFork}
                            type="text"
                            className={maxTimeCloseForkClass}
                          />
                          {this.state.maxTimeCloseFork.hasError && (
                            <small className="text-danger">Invalid time</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Макс. разрешеная просадка перекрытия второго плеча</label>
                          <input
                            placeholder="-10"
                            value={this.state.minRangePercentCloseForkRF.value}
                            onChange={this.changeMinRangePercentCloseForkRF}
                            type="text"
                            className={minRangePercentCloseForkRFClass}
                          />
                          {this.state.minRangePercentCloseForkRF.hasError && (
                            <small className="text-danger">Invalid min percent</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Bet Strategies</label>
                          <select
                            className="form-control"
                            onChange={this.changeStrategyName}
                            value={this.state.strategyName}
                          >
                            <option value="">Выберите стратегию</option>
                            {this.state.betStrategies.map((range, i) => (
                              <option key={i} value={range.name}>
                                {range.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-6 col-sm-6"></div>
                      </div>

                    </>
                  )}


                  {this.state.botType === 'pending_bets' && (
                    <>
                      <div className="form-row">
                        <div className="form-group col-md">
                          <label>Список спортов для формирования вилки</label>
                          <div className="btn-group btn-sm col-md">
                            <PendingBetSportsComponent
                              type="checkbox"
                              active={this.state.pendingBetSports}
                              setSports={this.setPendingBetSports}
                            />
                          </div>
                          {this.state.pendingBetSportsEmpty && (
                            <small className="text-danger">Please select sports</small>
                          )}
                        </div>

                        <div className="form-group col-md">
                          <label>Можно выбрать определенные исходы по каждому спорту</label>
                          <div className="btn-group btn-sm col-md">
                            <MoneyLineOutcomesComponent
                              type="checkbox"
                              active={this.state.moneyLineOutcomes}
                              setSports={this.setMoneyLineOutcomes}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <TotalsOutcomesComponent
                              type="checkbox"
                              active={this.state.totalsOutcomes}
                              setSports={this.setTotalsOutcomes}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <IndTotalsOutcomesComponent
                              type="checkbox"
                              active={this.state.indTotalsOutcomes}
                              setSports={this.setIndTotalsOutcomes}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <HandicapsOutcomesComponent
                              type="checkbox"
                              active={this.state.handicapsOutcomes}
                              setSports={this.setHandicapsOutcomes}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <WinnerOutcomesComponent
                              type="checkbox"
                              active={this.state.winnerOutcomes}
                              setSports={this.setWinnerOutcomes}
                            />
                          </div>
                          <div className="btn-group btn-sm col-md">
                            <AdditionalsOutcomesComponent
                              type="checkbox"
                              active={this.state.additionalsOutcomes}
                              setSports={this.setAdditionalsOutcomes}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Время отложенной ставки(секунды)</label>
                          <input
                            placeholder="60"
                            value={this.state.delayedBetTime.value}
                            onChange={this.changeDelayedBetTime}
                            type="text"
                            className={delayedBetTimeClass}
                          />
                          {this.state.delayedBetTime.hasError && (
                            <small className="text-danger">Invalid delayed bet time</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Количество отправленных ставок одного исхода</label>
                          <input
                            placeholder="2"
                            value={this.state.maxNumberSentSimilarFork.value}
                            onChange={this.changeMaxNumberSentSimilarFork}
                            type="text"
                            className={maxNumberSentSimilarForkClass}
                          />
                          {this.state.maxNumberSentSimilarFork.hasError && (
                            <small className="text-danger">Invalid number</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Минимальное время жизни вилки(секунды)</label>
                          <input
                            placeholder="3"
                            value={this.state.minLifetimeFork.value}
                            onChange={this.changeMinLifetimeFork}
                            type="text"
                            className={minLifetimeForkClass}
                          />
                          {this.state.minLifetimeFork.hasError && (
                            <small className="text-danger">Invalid minimum lifetime fork</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Максимальное время жизни вилки(секунды)</label>
                          <input
                            placeholder="300"
                            value={this.state.maxLifetimeFork.value}
                            onChange={this.changeMaxLifetimeFork}
                            type="text"
                            className={maxLifetimeForkClass}
                          />
                          {this.state.maxLifetimeFork.hasError && (
                            <small className="text-danger">Invalid maximum lifetime fork</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Минимальная начальная маржа вилки(проценты)</label>
                          <input
                            placeholder="0.5"
                            value={this.state.minPercentInitFork.value}
                            onChange={this.changeMinPercentInitFork}
                            type="text"
                            className={minPercentInitForkClass}
                          />
                          {this.state.minPercentInitFork.hasError && (
                            <small className="text-danger">Invalid minimum margin</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Максимальная начальная маржа вилки(проценты)</label>
                          <input
                            placeholder="30"
                            value={this.state.maxPercentInitFork.value}
                            onChange={this.changeMaxPercentInitFork}
                            type="text"
                            className={maxPercentInitForkClass}
                          />
                          {this.state.maxPercentInitFork.hasError && (
                            <small className="text-danger">Invalid maximum margin</small>
                          )}
                        </div>
                      </div>

                      {/*<div className="form-row">*/}
                      {/*  <div className="form-group col-md-6 col-sm-6">*/}
                      {/*    <label>Минимальный начальный коэффициент вилки</label>*/}
                      {/*    <input*/}
                      {/*        placeholder="1.2"*/}
                      {/*        value={this.state.minCoefficientInitFork.value}*/}
                      {/*        onChange={this.changeMinCoefficientInitFork}*/}
                      {/*        type="text"*/}
                      {/*        className={minCoefficientInitForkClass}*/}
                      {/*    />*/}
                      {/*    {this.state.minCoefficientInitFork.hasError && (*/}
                      {/*        <small className="text-danger">Invalid min coefficient</small>*/}
                      {/*    )}*/}
                      {/*  </div>*/}
                      {/*  <div className="form-group col-md-6 col-sm-6">*/}
                      {/*    <label>Максимальный начальный коэффициент вилки</label>*/}
                      {/*    <input*/}
                      {/*        placeholder="7"*/}
                      {/*        value={this.state.maxCoefficientInitFork.value}*/}
                      {/*        onChange={this.changeMaxCoefficientInitFork}*/}
                      {/*        type="text"*/}
                      {/*        className={maxCoefficientInitForkClass}*/}
                      {/*    />*/}
                      {/*    {this.state.maxCoefficientInitFork.hasError && (*/}
                      {/*        <small className="text-danger">Invalid max coefficient</small>*/}
                      {/*    )}*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Минимальная конечная маржа ставки(проценты)</label>
                          <input
                            placeholder="0.5"
                            value={this.state.minPercentFinalFork.value}
                            onChange={this.changeMinPercentFinalFork}
                            type="text"
                            className={minPercentFinalForkClass}
                          />
                          {this.state.minPercentFinalFork.hasError && (
                            <small className="text-danger">Invalid minimum margin</small>
                          )}
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Максимальная конечная маржа ставки(проценты)</label>
                          <input
                            placeholder="30"
                            value={this.state.maxPercentFinalFork.value}
                            onChange={this.changeMaxPercentFinalFork}
                            type="text"
                            className={maxPercentFinalForkClass}
                          />
                          {this.state.maxPercentFinalFork.hasError && (
                            <small className="text-danger">Invalid maximum margin</small>
                          )}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6 col-sm-6">
                          <label>Global Money Range</label>
                          <input
                            placeholder="20, 15.5, 10-30.5"
                            value={this.state.global_money.value}
                            onChange={this.changeGlobalMoney}
                            type="text"
                            className={glMoneyClass}
                            required=""
                          />
                          {this.state.global_money.hasError && (
                            <small className="text-danger">Invalid money range.</small>
                          )}
                        </div>
                        {/*<div className="form-group col-md-6 col-sm-6">*/}
                        {/*  <label>Cost</label>*/}
                        {/*  <input*/}
                        {/*      value={this.state.cost}*/}
                        {/*      onChange={this.changeCost}*/}
                        {/*      type="text"*/}
                        {/*      className="form-control"*/}
                        {/*      required=""*/}
                        {/*  />*/}
                        {/*</div>*/}
                      </div>

                      <div className="form-group row">
                        <label className="col-form-label col-md-3 col-sm-3 ">
                          Money Ranges
                        </label>
                        <div className="col-md-3 col-sm-3 ">
                          <button
                            type="button"
                            onClick={this.addMoneyRange}
                            className="btn btn-round btn-info"
                          >
                            <i className="fa fa-plus"/>
                          </button>
                        </div>
                        <div className="col-md-1 col-sm-1 pt-2">OR</div>
                        <div className="col-md-5 col-sm-5 ">
                          <select
                            className="form-control"
                            onChange={this.changeRangeName}
                            value={this.state.rangeName.value}
                          >
                            <option value="">Please select</option>
                            {this.state.ranges.map((range, i) => (
                              <option key={i} value={range.name}>
                                {range.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {this.state.oddsRanges.map((oddsData, i) => (
                        <MoneyLine
                          key={i}
                          ind={i}
                          data={oddsData}
                          changeCoef={this.changeCoef}
                          changeMoney={this.changeMoney}
                          hasRemoveBtn={this.state.oddsRanges.length > 1}
                          removeMoneyLinePosition={this.removeMoneyLinePosition}
                        />
                      ))}

                    </>
                  )}


                </div>


                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    Close
                  </button>
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddBotModal;
