import React from 'react'
import { Link } from 'react-router-dom'
import routes from '../../../chunks/routes'
import helpers from '../../../chunks/helpers'
import '../../Users/src/user-entry.css'
import backend from '../../../chunks/backend'
import md5 from 'md5-hash'

import { Input, Button, Popover, Space } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import Image from 'react-bootstrap/Image'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Typography } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

const {Text, Paragraph} = Typography

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMoneyFromBracket } from '@fortawesome/free-solid-svg-icons'

class BotsEntity extends React.Component {
  constructor(props) {
    super(props)

    const mirror = (() => {
      const host = window.location.host

      if (host === 'eurobet.billy-lg.com') {
        return 'https://eurobet.it'
      }
      else if (host === 'parimatch.billy-lg.com') {
        return false
      }
      else if (host === 'billy-lg.com' || host === 'bet365-point.billy-lg.com') {
        return 'https://bet365.com'
      }

      return 'https://bet365.com'
    })()

    this.state = {
      showPassword: false,
      timeOut: 0,
      mirror
    }

    this.showPassword = this.showPassword.bind(this)
    this.logoutBot = this.logoutBot.bind(this)
    this.gravatar = this.gravatar.bind(this)
    this.changeIsAmericanCoef = this.changeIsAmericanCoef.bind(this)
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeOut)
  }

  showPassword() {
    this.setState({
      showPassword: true,
      timeOut: setTimeout(() => {
        this.setState({showPassword: false})
      }, 10000)
    })
  }

  logoutBot(bot) {
    backend
      .put(`/bot/logout/${bot.name}`)
      .then(({data}) => {
        if (data.err) {
          console.log(data.err)
        }
        else {
          window.location.reload()
        }
      })
      .catch(console.log)
  }

  changeIsAmericanCoef(e) {
    const bot = this.props.data
    this.props.handleAmericanCoef(bot.name, e.target.checked)
  }

  gravatar() {
    const hash = md5(this.props.data.name).toLowerCase()
    return `https://www.gravatar.com/avatar/${hash}?d=robohash&s=40`
  }

  render() {
    const bot = this.props.data
    const link = routes.users.profile.replace(':username', bot.username)
    const linkBotDetailed = routes.bots.detailed.replace(':name', bot.name)
    const userAccess = 'userAccess' in this.props ? this.props.userAccess : true
    let botClasses = 'text-muted'

    // console.log('bot:', bot)
    // console.log('bot.moneyWaitingFork:', bot.moneyRegularFork)

    // if (bot.online1) {
    //   if (bot.authOnSite1) {
    //     botClasses = bot.balance1 > 0 ? "text-success" : "text-warning";
    //   }
    //   else {
    //     botClasses = "text-danger";
    //   }
    // }

    botClasses += ' mr-2'

    const currentTime = new Date().getTime()
    let lastSuccessBetTime1 = '0h 0m'
    if (bot.lastBetTime1) {
      const t1 = currentTime - bot.lastBetTime1
      const t2 = Number(t1) / 1000 / 60
      lastSuccessBetTime1 = `0h ${Math.floor(Number(Number(t1) / 1000 / 60))}m`

      if (t2 > 60) {
        const t3 = Math.floor(t2 / 60)
        lastSuccessBetTime1 = `${t3}h ${Math.floor(Number(t2 - (t3 * 60)))}m`
      }
    }

    let lastFailedBetTime1 = '0h 0m'
    if (bot.lastFailedBetTime1) {
      const t1 = currentTime - bot.lastFailedBetTime1
      const t2 = Number(t1) / 1000 / 60
      lastFailedBetTime1 = `0h ${Math.floor(Number(Number(t1) / 1000 / 60))}m`

      if (t2 > 60) {
        const t3 = Math.floor(t2 / 60)
        lastFailedBetTime1 = `${t3}h ${Math.floor(Number(t2 - (t3 * 60)))}m`
      }
    }


    let lastSuccessBetTime2 = '0h 0m'
    if (bot.lastBetTime2) {
      const t1 = currentTime - bot.lastBetTime2
      const t2 = Number(t1) / 1000 / 60
      lastSuccessBetTime2 = `0h ${Math.floor(Number(Number(t1) / 1000 / 60))}m`

      if (t2 > 60) {
        const t3 = Math.floor(t2 / 60)
        lastSuccessBetTime2 = `${t3}h ${Math.floor(Number(t2 - (t3 * 60)))}m`
      }
    }

    let lastFailedBetTime2 = '0h 0m'
    if (bot.lastFailedBetTime2) {
      const t1 = currentTime - bot.lastFailedBetTime2
      const t2 = Number(t1) / 1000 / 60
      lastFailedBetTime2 = `0h ${Math.floor(Number(Number(t1) / 1000 / 60))}m`

      if (t2 > 60) {
        const t3 = Math.floor(t2 / 60)
        lastFailedBetTime2 = `${t3}h ${Math.floor(Number(t2 - (t3 * 60)))}m`
      }
    }

    const book1 = bot.bookmakerFirst ? bot.bookmakerFirst.replace('loot', 'nitrobetting').replace('stake', 'pinnacle_2').replace('gorilla', 'newgioco').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('ladbrokes', 'ladbrokes_be').replace('virginbet', 'pin880').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('olimp', 'max24bet').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet').replace('efbet', 'ifortuna').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').toUpperCase() : ''
    const book2 = bot.bookmakerSecond ? bot.bookmakerSecond.replace('loot', 'nitrobetting').replace('stake', 'pinnacle_2').replace('gorilla', 'newgioco').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('ladbrokes', 'ladbrokes_be').replace('virginbet', 'pin880').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('olimp', 'max24bet').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet').replace('efbet', 'ifortuna').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('zenit', 'chance').toUpperCase() : ''
    // const currency1 = bot.currencyFirst === 'CZK' ? <strong>Kč</strong> : <i className={bot.currencyFirst === 'EUR' ? 'fa fa-eur' : (bot.currencyFirst === 'GBP' ? 'fa fa-gbp' : (bot.currencyFirst === 'MBT' ? 'fa fa-btc' : 'fa fa-usd'))}/>
    // const currency2 = bot.currencySecond === 'CZK' ? <strong>Kč</strong> : <i className={bot.currencySecond === 'EUR' ? 'fa fa-eur' : (bot.currencySecond === 'GBP' ? 'fa fa-gbp' : (bot.currencySecond === 'MBT' ? 'fa fa-btc' : 'fa fa-usd'))}/>

    const currency1 = <span style={{fontSize: '8px'}}><strong>{bot.currencyFirst}</strong></span>
    const currency2 = <span style={{fontSize: '8px'}}><strong>{bot.currencySecond}</strong></span>

    let pl1 = Number(Number(Number(Number(((bot.balance1 > 1000000 ? 0 : bot.balance1) + Number(bot.cashout1 || 0) + Number(bot.withdrawnMoneySum1 || 0)) - bot.deposit1).toFixed(2)) * (bot.exchangeRate || 1)).toFixed(0))
    if (bot.bookmakerFirst === 'loot') {
      pl1 = Number(Number(Number(Number(((bot.balance1 > 1000000 ? 0 : bot.balance1) + Number(bot.cashout1 || 0) + Number(bot.withdrawnMoneySum1 || 0)) - bot.deposit1).toFixed(2)) * (bot.exchangeRate || 1) / 1000).toFixed(0))
    }
    // console.log("-----bookmakerFirst:", bot.bookmakerFirst)
    // console.log("-----pl1:", pl1)
    let pl2 = Number(Number(((bot.balance2 > 1000000 ? 0 : bot.balance2) + Number(bot.withdrawnMoneySum2 || 0)) - bot.deposit2).toFixed(0))
    if (bot.bookmakerFirst === 'sbobet' || bot.bookmakerFirst === 'omg') {
      pl1 = Number(Number(Number(Number(((bot.balance1 > 1000000 ? 0 : bot.balance1) + Number(bot.cashout1 || 0) + Number(bot.withdrawnMoneySum1 || 0)) - bot.deposit1).toFixed(2)) * 1).toFixed(0))
    }

    // console.log("-----pl1:", pl1)
    // console.log("-----1:", (bot.balance1 > 1000000 ? 0 : bot.balance1))
    // console.log("-----2:", ((bot.balance1 > 1000000 ? 0 : bot.balance1) + Number(bot.withdrawnMoneySum1 || 0)))
    // console.log("-----2:", (((bot.balance1 > 1000000 ? 0 : bot.balance1) + Number(bot.withdrawnMoneySum1 || 0)) - bot.deposit1).toFixed(2))
    // console.log("-----balance1:", bot.balance1)
    // console.log("-----withdrawnMoneySum1:", bot.withdrawnMoneySum1)
    // console.log("-----withdrawnMoneySum1:", bot.withdrawnMoneySum1)
    // console.log("-----exchangeRate:", bot.exchangeRate)
    //
    // console.log("-------------------------------------")

    const classNameDelete = bot.deletedAt ? 'card border-danger' : 'card border-info'
    // const classNamePL = Number((bot.balance2 + Number(bot.cashout2) + Number(bot.withdrawnMoneySum2 || 0)) - bot.deposit2).toFixed(0) > 0 ? "accepted-tr" : "remove-tr"
    const classNamePL = Number(pl1 + pl2) > 0 ? 'accepted-tr' : 'remove-tr'
    const classColor = bot.colorClient || '#fafafa'
    const classColorSecond = bot.colorSecond || '#fafafa'

    const textTitle = <span>Errors</span>
    // const content = (
    //   <div>
    //     <p>Content</p>
    //     <p>Content</p>
    //   </div>
    // );

    let tempMes = ''
    let arrErr = bot.messageErrors ? [].concat(bot.messageErrors).reverse() : []
    // let arrErr = bot.messageErrors ? bot.messageErrors.reverse() : []
    const content = arrErr.map((error) => {
      if (tempMes !== error.message) {
        tempMes = error.message
        return <p>{helpers.dttt(error.date)} | {error.message}</p>
      }
    })

    const content2 = (
      <div>
        {content}
      </div>
    )

    return (
      <div className="col-sm-2 p-1" style={{fontSize: '10px'}}>
        <div style={{border: bot.deletedAt ? '' : ''}} className={`${classNameDelete}`}>
          {/*<div style={{border: bot.deletedAt ? "" : ""}} className={bot.balance === bot.cost ? (bot.deletedAt ? "card border-danger" : "card border-info") : (bot.balance > bot.cost ? (bot.deletedAt ? "accepted-tr card border-danger" : "accepted-tr card border-info") : (bot.deletedAt ? "remove-tr card border-danger" : "remove-tr card border-info"))}>*/}


          <div className={`card-header p-1`} style={{backgroundColor: classColor}}>

            <div className="d-flex justify-content-between pb-1">
              <div>
                <Link to={linkBotDetailed}>
                  <span style={{fontSize: '10px'}}>
                    <strong className="text-dark">{bot.usernameFirst}</strong>
                    {/*<strong className={botClasses}> {bot.name}</strong>*/}
                  </span>
                </Link>
              </div>

              <div>
                <Space.Compact style={{width: '100%'}}>
                  <Button
                    onClick={() => this.props.handleDecrement(bot)}
                    style={{
                      width: '23px',
                      height: '25px',
                      backgroundColor: '#8c8c8c',
                      color: '#FFF',
                      border: 'none'
                      // borderTopLeftRadius: 0,
                      // borderBottomLeftRadius: 0,
                      // borderTopRightRadius: 0,
                      // borderBottomRightRadius: 0,
                    }}
                    // type="default"
                    // variant="solid"
                    icon={<MinusOutlined/>}
                  />
                  <Input
                    // className="text-danger"
                    // defaultValue={bot.minPercentInitForkRF}
                    value={bot.minPercentInitForkRF}
                    size="small"
                    style={{
                      width: '55px',
                      height: '25px',
                      textAlign: 'center',
                      backgroundColor: classColorSecond,
                      fontWeight: '600'
                      // borderRadius: 0
                    }}
                  />
                  {/*<Input*/}
                  {/*  size="small"*/}
                  {/*  // size="small"*/}
                  {/*  // value={value}*/}
                  {/*  // onChange={handleChange}*/}
                  {/*  // style={{*/}
                  {/*  //   width: '60px',*/}
                  {/*  //   textAlign: 'center',*/}
                  {/*  //   // borderRadius: 0*/}
                  {/*  // }}*/}
                  {/*/>*/}
                  <Button
                    onClick={() => this.props.handleIncrement(bot)}
                    style={{
                      width: '23px',
                      height: '25px',
                      backgroundColor: '#8c8c8c',
                      color: '#FFF',
                      border: 'none'
                      // borderTopLeftRadius: 0,
                      // borderBottomLeftRadius: 0,
                      // borderTopRightRadius: 0,
                      // borderBottomRightRadius: 0,
                    }}
                    // type="default"
                    icon={<PlusOutlined/>}
                  />
                </Space.Compact>
              </div>

              <div>
               <span>
                 <>
                    {bot.restricted && <><i className="fa fa-hand fa-lg text-danger"></i></>}
                   <strong>P&L</strong>:&nbsp;
                   {Number(pl1 + pl2) > 0 ? (
                     <span>
                        <strong className="text-success">
                         {Number(pl1 + pl2).toFixed(0)}
                          &nbsp;
                        </strong>
                       {currency2}
                       {/*<i className="fa fa-usd green-text"/>*/}
                      </span>
                   ) : (
                     <span>
                            <strong className="text-danger">
                              {Number(pl1 + pl2).toFixed(0)}
                              &nbsp;
                            </strong>
                       {currency2}
                       {/*<i className="fa fa-usd green-text"/>*/}
                      </span>
                   )}
                  </>
                 {/*&nbsp;*/}
                 {/*<OverlayTrigger*/}
                 {/*  placement="left"*/}
                 {/*  overlay={<Tooltip>{bot.isSendToBot ? "Bot Running" : "Bot Stopped"}</Tooltip>}*/}
                 {/*>*/}
                 {/*   <span style={{cursor: "pointer", position: "absolute", marginLeft: "-30px", marginTop: "-2px"}} onClick={() => this.props.stopSendBot(bot)}>*/}
                 {/*     /!*<i className={bot.isSendToBot ? "fa fa-unlock fa-lg text-success" : "fa fa-lock fa-lg text-danger"}/>*!/*/}
                 {/*      <Image src={bot.isSendToBot ? "./bablo_1_2.png" : "./bablo_1_1.png"} fluid style={{width: "30px"}} />*/}
                 {/*   </span>*/}
                 {/* </OverlayTrigger>*/}

                </span>
              </div>

              <div style={{paddingTop: '17px', position: 'absolute', right: '4px'}}>
               <span>
                 <>
                    {bot.restricted && <><i className="fa fa-hand fa-lg text-danger"></i></>}
                   <strong>In Play</strong>:&nbsp;
                   <span><strong className="text-primary">{bot.cashout1 || 0}</strong>&nbsp;{currency2}</span>
                  </>
                </span>
              </div>

            </div>

            <div className="d-flex justify-content-between">
              <div>
                <strong style={{fontSize: '10px'}}><Link to={link}>{bot.description}</Link></strong>
              </div>
              <div>
                <strong style={{fontSize: '10px'}}><i className="text-secondary">{helpers.dt(bot.createdAt)}</i></strong>
              </div>
            </div>

          </div>

          <div className={`card-body p-1 ${classNamePL}`}>

            {/*<div style={{paddingTop: '130px', position: 'absolute', right: '100px', zIndex: '1000'}}>*/}
            {/*  <OverlayTrigger*/}
            {/*    placement="bottom"*/}
            {/*    overlay={<Tooltip>Finish</Tooltip>}*/}
            {/*  >*/}
            {/*    <button*/}
            {/*      type="button"*/}
            {/*      onClick={() => this.props.setFinish(bot)}*/}
            {/*      className={bot.isFinish ? 'btn btn-sm btn-success' : 'btn btn-sm btn-warning'}*/}
            {/*      // data-toggle="modal" data-target=".modal-clone-bot"*/}
            {/*    >*/}
            {/*      <i className="fa fa-flag-checkered fa-lg"/>*/}
            {/*      /!*<FontAwesomeIcon icon={faMoneyFromBracket} />*!/*/}
            {/*    </button>*/}
            {/*  </OverlayTrigger>*/}
            {/*</div>*/}

            <div className="row" style={{display: 'block'}}>
              {/*{bot.botType === 'regular_forks' ? (*/}
              {/*  <div className="col-md-4 col-sm-4" style={{maxWidth: "26%", fontSize: "9px"}}>*/}
              {/*  <span className="text-dark" style={{fontSize: "8px"}}>*/}
              {/*      <strong>{bot.botType.replace('regular_forks', 'forks').replace('one_shoulder_forks', 'валуй').toUpperCase()}</strong>*/}
              {/*  </span>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <div className="col-md-4 col-sm-4" style={{maxWidth: "26%", fontSize: "9px"}}>*/}
              {/*  <span className="text-dark" style={{fontSize: "8px"}}>*/}
              {/*      <strong>{bot.botType.replace('regular_forks', 'forks').replace('one_shoulder_forks', 'валуй').toUpperCase()}</strong>*/}
              {/*  </span>*/}
              {/*    &nbsp;|&nbsp;*/}
              {/*    <span className="text-dark" style={{fontSize: "8px"}}>*/}
              {/*      <strong>{bot.singleExpressBetType && bot.singleExpressBetType.replace('single_bet', 's').replace('single_express_bet', 's_e').replace('express_bet', 'e').toUpperCase()}</strong>*/}
              {/*  </span>*/}
              {/*  </div>*/}
              {/*)}*/}

              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%'}}></div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{bot.online1 ? (bot.authOnSite1 ? `${book1} Authorized` : `${book1} Online`) : `${book1} Offline`}</Tooltip>}
                >
                  <span><i
                    className={bot.online1 ? (bot.authOnSite1 ? 'fa fa-circle text-success' : 'fa fa-circle text-warning') : 'fa fa-circle text-danger'}/>&nbsp;</span>
                </OverlayTrigger>
                <span className="text-primary" style={{fontSize: '8px'}}>
                    <strong>{book1}</strong>
                </span>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{bot.online2 ? (bot.authOnSite2 ? `${book2} Authorized` : `${book2} Online`) : `${book2} Offline`}</Tooltip>}
                >
                  <span><i
                    className={bot.online2 ? (bot.authOnSite2 ? 'fa fa-circle text-success' : 'fa fa-circle text-warning') : 'fa fa-circle text-danger'}/>&nbsp;</span>
                </OverlayTrigger>
                <span className="text-primary" style={{fontSize: '8px'}}>
                   <strong>{book2}</strong>
                </span>
              </div>
            </div>

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>Balance</strong>:</div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <>
                  {(bot.balance1 === bot.deposit1 || bot.balance1 === 0) ? (
                    <span>
                      <strong className="text-danger">{Number((bot.balance1 > 1000000 ? 0 : bot.balance1)).toFixed(1)}&nbsp;</strong>{currency1}
                    </span>
                  ) : (
                    <>
                      {((bot.balance1 > 1000000 ? 0 : bot.balance1) + Number(bot.withdrawnMoneySum1 || 0)) > bot.deposit1 ? (
                        <span>
                          <i className="fa fa-caret-up text-success"/>
                          <strong className="text-primary">{Number((bot.balance1 > 1000000 ? 0 : bot.balance1)).toFixed(1)}&nbsp;</strong>{currency1}
                        </span>
                      ) : (
                        <span>
                          <i className="fa fa-caret-down text-danger"/>
                          <strong className="text-primary">{Number((bot.balance1 > 1000000 ? 0 : bot.balance1)).toFixed(1)}&nbsp;</strong>{currency1}
                        </span>
                      )}
                    </>
                  )}
                </>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <>
                  {(bot.balance2 === bot.deposit2 || bot.balance2 === 0) ? (
                    <span>
                      <strong className="text-danger">{Number((bot.balance2 > 1000000 ? 0 : bot.balance2)).toFixed(1)}&nbsp;</strong>{currency2}
                    </span>
                  ) : (
                    <>
                      {((bot.balance2 > 1000000 ? 0 : bot.balance2) + Number(bot.withdrawnMoneySum2 || 0)) > bot.deposit2 ? (
                        <span>
                          <i className="fa fa-caret-up text-success"/>
                          <strong className="text-primary">{Number((bot.balance2 > 1000000 ? 0 : bot.balance2)).toFixed(1)}&nbsp;</strong>{currency2}
                        </span>
                      ) : (
                        <span>
                          <i className="fa fa-caret-down text-danger"/>
                          <strong className="text-primary">{Number((bot.balance2 > 1000000 ? 0 : bot.balance2)).toFixed(1)}&nbsp;</strong>{currency2}
                        </span>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>


            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>P&L</strong>:</div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                {bot.deposit1 ? (
                  <>
                    {((bot.balance1 > 1000000 ? 0 : bot.balance1) + Number(bot.withdrawnMoneySum1 || 0)) > bot.deposit1 ? (
                      <span>
                        <strong className="text-success">{pl1.toFixed(0)}&nbsp;</strong>{currency2}
                      </span>
                    ) : (
                      <span>
                        <strong className="text-danger">{pl1.toFixed(0)}&nbsp;</strong>{currency2}
                      </span>
                    )}
                  </>
                ) : (
                  <><strong className="text-dark">{pl1.toFixed(0)}&nbsp;</strong>{currency2}</>
                )}
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                {bot.deposit2 ? (
                  <>
                    {((bot.balance2 > 1000000 ? 0 : bot.balance2) + Number(bot.withdrawnMoneySum2 || 0)) > bot.deposit2 ? (
                      <span>
                        <strong className="text-success">{pl2.toFixed(0)}&nbsp;</strong>{currency2}
                      </span>
                    ) : (
                      <span>
                        <strong className="text-danger">{pl2.toFixed(0)}&nbsp;</strong>{currency2}
                      </span>
                    )}
                  </>
                ) : (
                  <><strong className="text-dark">{pl2.toFixed(0)}&nbsp;</strong>{currency2}</>
                )}
              </div>
            </div>

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>Deposit</strong>:</div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span>
                  <strong className="text-dark">{bot.deposit1}&nbsp;</strong>{currency1}
                </span>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span>
                  <strong className="text-dark">{bot.deposit2}&nbsp;</strong>{currency2}
                </span>
              </div>
            </div>

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>Turnover</strong>:</div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span>
                  <strong className="text-dark">{bot.sumBets1}&nbsp;</strong>{currency1}
                </span>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span>
                  {/*<strong className="text-dark">&nbsp;</strong>*/}
                  {/*<strong className="text-dark">{bot.sumBets2}&nbsp;</strong>{currency2}*/}

                  <strong className="text-dark">{bot.usernameSecond}</strong>
                </span>
              </div>
            </div>

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>Suc Bets</strong>:</div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span>
                  <span className="text-success"><strong style={{fontSize: '10px'}}>{bot.numberSuccessBets1 || 0}</strong></span>
                </span>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span>
                   <strong>Stake:</strong> <span className="text-dark"><strong style={{fontSize: '10px'}}>{bot.moneyRegularFork || 0}</strong></span>
                  {/*<span className="text-success"><strong style={{fontSize: '10px'}}>{bot.numberSuccessBets2 || 0}</strong></span>*/}
                </span>
              </div>
            </div>

            {/*<div className="row" style={{display: "block"}}>*/}
            {/*  <div className="col-md-4 col-sm-4" style={{maxWidth: "26%"}}><strong>Max B.</strong>:</div>*/}
            {/*  <div className="col-md-4 col-sm-4" style={{maxWidth: "37%"}}>*/}
            {/*      <span>*/}
            {/*        <strong>{bot.maxBalance1}&nbsp;</strong>*/}
            {/*        {currency1}*/}
            {/*        /!*<i className="fa fa-usd green-text"/>*!/*/}
            {/*      </span>*/}
            {/*  </div>*/}
            {/*  <div className="col-md-4 col-sm-4" style={{maxWidth: "37%"}}>*/}
            {/*      <span>*/}
            {/*        <strong>{bot.maxBalance2}&nbsp;</strong>*/}
            {/*        {currency2}*/}
            {/*        /!*<i className="fa fa-usd green-text"/>*!/*/}
            {/*      </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>Suc Time</strong></div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span className="text-success"><strong style={{fontSize: '10px'}}>{lastSuccessBetTime1}</strong></span>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%', fontSize: '9px'}}>
                <span className={bot.isAmericanCoef ? 'text-dark' : 'text-success'}><strong style={{fontSize: '10px'}}>DEC</strong></span> | <span
                className={bot.isAmericanCoef ? 'text-success' : 'text-dark'}><strong style={{fontSize: '10px'}}>AMER</strong></span>
                {/*<span className="text-danger"><strong style={{fontSize: '10px'}}>{lastFailedBetTime2}</strong></span>*/}
                {/*<span className="text-dark">&nbsp;</span>*/}
                {/*<span className="text-success"><strong style={{fontSize: '10px'}}>{lastSuccessBetTime2}</strong></span>*/}
              </div>
            </div>

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>Fail Time</strong></div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span className="text-danger"><strong style={{fontSize: '10px'}}>{lastFailedBetTime1}</strong></span>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <div className={`custom-control custom-switch form-control control-no-border ${classNamePL}`}
                     style={{height: '10px', paddingLeft: '47px'}}>
                  <input
                    checked={bot.isAmericanCoef}
                    onChange={this.changeIsAmericanCoef}
                    type="checkbox"
                    className="custom-control-input input-pointer"
                    id={`isAmericanCoefUpdate_${bot._id}`}
                  />
                  <label className="custom-control-label input-pointer" htmlFor={`isAmericanCoefUpdate_${bot._id}`}></label>
                </div>
                {/*<strong style={{fontSize: '9px'}}></strong><span className="text-danger"><strong style={{fontSize: '10px'}}>{bot.numberFailedBets2 || 0}</strong></span>*/}
              </div>
            </div>

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '26%', fontSize: '9px'}}><strong>Fail Bets</strong>:</div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <strong style={{fontSize: '9px'}}></strong><span className="text-danger"><strong
                style={{fontSize: '10px'}}>{bot.numberFailedBets1 || 0}</strong></span>
              </div>
              <div className="col-md-4 col-sm-4" style={{maxWidth: '37%'}}>
                <span className="text-dark">&nbsp;</span>
              </div>
            </div>

            <div className="row" style={{display: 'block'}}>
              <div className="col-md-6 col-sm-6">
                <span>
                  <Text style={{fontSize: '12px'}} copyable={{text: bot.ipOctoBrowser || ''}}><strong>{bot.ipOctoBrowser || 'No IP'}</strong></Text>
                </span>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="d-flex justify-content-end" style={{position: 'absolute', right: '10px', top: '-5px'}}>
                  <div className="btn-group-sm btn-group">

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Finish</Tooltip>}
                      // style={{marginRight: '10px'}}
                    >
                      <button
                        type="button"
                        onClick={() => this.props.setFinish(bot)}
                        className={bot.isFinish ? 'btn btn-sm btn-success' : 'btn btn-sm btn-warning'}
                        style={{marginRight: '10px'}}
                        // data-toggle="modal" data-target=".modal-clone-bot"
                      >
                        <i className="fa fa-flag-checkered fa-lg"/>
                        {/*<FontAwesomeIcon icon={faMoneyFromBracket} />*/}
                      </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Single</Tooltip>}
                    >
                      <button
                        type="button"
                        onClick={() => this.props.setSingleExpressBetType(bot, 'single_bet')}
                        className={bot.singleExpressBetType === 'single_bet' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-secondary'}
                        // data-toggle="modal" data-target=".modal-clone-bot"
                      >
                        <b>1</b>
                      </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>2 Express</Tooltip>}
                    >
                      <button
                        type="button"
                        onClick={() => this.props.setSingleExpressBetType(bot, 'express_bet')}
                        className={bot.singleExpressBetType === 'express_bet' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-secondary'}
                        // data-toggle="modal" data-target=".modal-clone-bot"
                      >
                        <b>2</b>
                      </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>3 Express</Tooltip>}
                    >
                      <button
                        type="button"
                        onClick={() => this.props.setSingleExpressBetType(bot, 'triple_express_bet')}
                        className={bot.singleExpressBetType === 'triple_express_bet' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-secondary'}
                        // data-toggle="modal" data-target=".modal-clone-bot"
                      >
                        <b>3</b>
                      </button>
                    </OverlayTrigger>

                  </div>
                </div>
              </div>

              {/*<div className="col-md-6 col-sm-6">*/}
              {/*  <span style={{fontSize: '8px'}}*/}
              {/*        className={bot.lastErrorMessage1 ? 'text-danger' : 'text-success'}><strong>{bot.lastErrorMessage1 || 'No error'}</strong></span>*/}
              {/*</div>*/}
              {/*<div className="col-md-6 col-sm-6">*/}
              {/*  <span style={{fontSize: '8px'}} className={bot.lastErrorMessage2 ? 'text-danger' : 'text-success'}><strong> </strong></span>*/}
              {/*  /!*<span style={{fontSize: '8px'}} className={bot.lastErrorMessage2 ? 'text-danger' : 'text-success'}><strong>{bot.lastErrorMessage2 || 'No error'}</strong></span>*!/*/}
              {/*</div>*/}
            </div>

          </div>

          <div className={`card-footer p-1 ${classNamePL}`}>
            <div className="d-flex justify-content-between">
              <div className="btn-group-sm btn-group">

                {/*TODO: Раскоментить*/}
                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={*/}
                {/*    <Tooltip>{book1} extension</Tooltip>}*/}
                {/*  // overlay={<Tooltip>Betfair Extension</Tooltip>}*/}
                {/*>*/}
                {/*  <button*/}
                {/*    type="button"*/}
                {/*    // onClick={() => this.props.downloadBetfairExtension(bot)}*/}
                {/*    onClick={() => this.props.downloadExtension1(bot)}*/}
                {/*    // onClick={() => this.props.downloadSuperbetPlExtension(bot)}*/}
                {/*    className="btn btn-sm btn-dark"*/}
                {/*  >*/}
                {/*    <i className="fa fa-download fa-lg"/>*/}
                {/*  </button>*/}
                {/*</OverlayTrigger>*/}
                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={*/}
                {/*    <Tooltip>{book2} extension</Tooltip>}*/}
                {/*  // overlay={<Tooltip>Betfair Extension</Tooltip>}*/}
                {/*>*/}
                {/*  <button*/}
                {/*    type="button"*/}
                {/*    // onClick={() => this.props.downloadBetfairExtension(bot)}*/}
                {/*    onClick={() => this.props.downloadExtension2(bot)}*/}
                {/*    // onClick={() => this.props.downloadSuperbetPlExtension(bot)}*/}
                {/*    className="btn btn-sm btn-dark"*/}
                {/*  >*/}
                {/*    <i className="fa fa-download fa-lg"/>*/}
                {/*  </button>*/}
                {/*</OverlayTrigger>*/}

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Clone</Tooltip>}
                >
                  <button
                    type="button"
                    onClick={() => this.props.cloneBot(bot)}
                    className="btn btn-sm btn-primary"
                    // data-toggle="modal" data-target=".modal-clone-bot"
                  >
                    <i className="fa fa-clone fa-lg"/>
                  </button>
                </OverlayTrigger>

                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={<Tooltip>Withdrawal</Tooltip>}*/}
                {/*>*/}
                {/*  <button*/}
                {/*    type="button"*/}
                {/*    onClick={() => this.props.popUpWithdrawnMoneySum(bot)}*/}
                {/*    className="btn btn-sm btn-info"*/}
                {/*    // data-toggle="modal" data-target=".modal-clone-bot"*/}
                {/*  >*/}
                {/*    <i className="fa fa-money fa-lg"/>*/}
                {/*    /!*<FontAwesomeIcon icon={faMoneyFromBracket} />*!/*/}
                {/*  </button>*/}
                {/*</OverlayTrigger>*/}

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Update</Tooltip>}
                >
                  <button
                    type="button"
                    onClick={() => this.props.popUpUpdateBot(bot)}
                    className="btn btn-sm btn-primary"
                    // data-toggle="modal" data-target=".modal-clone-bot"
                  >
                    <i className="fa fa-pencil fa-lg"/>
                    {/*<FontAwesomeIcon icon={faMoneyFromBracket} />*/}
                  </button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Archive</Tooltip>}
                >
                  <button
                    type="button"
                    onClick={() => this.props.setArchive(bot)}
                    className={bot.isArchive ? 'btn btn-sm btn-success' : 'btn btn-sm btn-warning'}
                    // data-toggle="modal" data-target=".modal-clone-bot"
                  >
                    <i className="fa fa-archive fa-lg"/>
                    {/*<FontAwesomeIcon icon={faMoneyFromBracket} />*/}
                  </button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{bot.isSimbetBasketball ? 'Added Basketball' : 'Removed Basketball'}</Tooltip>}
                >
                  <button
                    type="button"
                    onClick={() => this.props.addSimbetBasketball(bot)}
                    className={bot.isSimbetBasketball ? 'btn btn-sm btn-success' : 'btn btn-sm btn-warning'}
                    // data-toggle="modal" data-target=".modal-clone-bot"
                  >
                    {/*<i className="fa fa-rocket fa-lg"/>*/}
                    <i className="fa fa-basketball fa-lg"></i>
                    {/*<FontAwesomeIcon icon={faMoneyFromBracket} />*/}
                  </button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Log Out Profiles Octo Browser</Tooltip>}
                >
                  <button
                    type="button"
                    onClick={() => this.props.logOutOcto(bot)}
                    className="btn btn-sm btn-danger"
                    // data-toggle="modal" data-target=".modal-clone-bot"
                  >
                    <i className="fa fa-sign-out fa-lg"/>
                    {/*<FontAwesomeIcon icon={faMoneyFromBracket} />*/}
                  </button>
                </OverlayTrigger>

                {/*<OverlayTrigger*/}
                {/*  placement="bottom"*/}
                {/*  overlay={<Tooltip>Errors</Tooltip>}*/}
                {/*>*/}
                {/*  <Popover placement="bottom" title={textTitle} content={content2}>*/}
                {/*    <button*/}
                {/*      type="button"*/}
                {/*      // onClick={() => this.props.popUpUpdateBot(bot)}*/}
                {/*      className="btn btn-sm btn-warning"*/}
                {/*      // data-toggle="modal" data-target=".modal-clone-bot"*/}
                {/*    >*/}
                {/*      <i className="fa fa-exclamation-triangle fa-lg"/>*/}
                {/*      /!*<FontAwesomeIcon icon={faMoneyFromBracket} />*!/*/}
                {/*    </button>*/}
                {/*  </Popover>*/}
                {/*</OverlayTrigger>*/}

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{bot.isSendToBot ? 'Bot Running' : 'Bot Stopped'}</Tooltip>}
                >
                    <span style={{cursor: 'pointer', marginLeft: '10px'}} onClick={() => this.props.stopSendBot(bot)}>
                    {/*<span style={{cursor: "pointer", position: "absolute", marginLeft: "-50px", marginTop: "0px"}} onClick={() => this.props.stopSendBot(bot)}>*/}
                      {/*<i className={bot.isSendToBot ? "fa fa-unlock fa-lg text-success" : "fa fa-lock fa-lg text-danger"}/>*/}
                      <Image src={bot.isSendToBot ? './bablo_1_2.png' : './bablo_1_1.png'} fluid style={{width: '30px'}}/>
                    </span>
                </OverlayTrigger>

                {userAccess && !bot.deletedAt && (
                  <>
                    {'restricted' in bot &&
                      (bot.restricted ? (
                        <>
                          <button
                            onClick={() => this.props.allowBot(bot)}
                            type="button"
                            className="btn btn-sm btn-secondary"
                          >
                            Allow
                          </button>
                        </>
                      ) : (
                        <>
                          {/*<button*/}
                          {/*  onClick={() => this.props.disallowBot(bot)}*/}
                          {/*  type="button"*/}
                          {/*  className="btn btn-sm btn-secondary"*/}
                          {/*>*/}
                          {/*  Disallow*/}
                          {/*</button>*/}
                        </>
                      ))}
                  </>
                )}
              </div>
              {/*<div className="btn-group-sm btn-group">*/}
              {/*  <>*/}
              {/*    <InputNumber*/}
              {/*      min={-5} // Минимальное значение*/}
              {/*      max={100} // Максимальное значение*/}
              {/*      step={0.25} // Шаг изменения*/}
              {/*      // value={value} // Текущее значение*/}
              {/*      // onChange={handleChange} // Обработчик изменения*/}
              {/*      style={{ maxWidth: "70px" }}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*</div>*/}
              <div className="btn-group-sm btn-group">
                {userAccess && !bot.deletedAt && (
                  <>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Delete Bot</Tooltip>}
                    >
                      <button
                        type="button"
                        onClick={() => this.props.deleteBot(bot)}
                        className="btn btn-sm btn-danger"
                      >
                        <i className="fa fa-trash fa-lg"/>
                      </button>
                    </OverlayTrigger>
                  </>
                )}
                {userAccess && bot.deletedAt && (
                  <>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Restore Bot</Tooltip>}
                    >
                      <button
                        type="button"
                        onClick={() => this.props.restoreBot(bot)}
                        className="btn btn-sm btn-dark"
                      >
                        <i className="fa fa-undo fa-lg"/>
                      </button>
                    </OverlayTrigger>

                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  onClick={() => this.props.trashBot(bot)}*/}
                    {/*  className="btn btn-sm btn-danger"*/}
                    {/*>*/}
                    {/*  Trash*/}
                    {/*</button>*/}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BotsEntity
