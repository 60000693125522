const helpers = {
    dt: function Dt(dt) {
        dt = new Date(dt);
        const month = dt.getMonth() + 1;

        const y = dt.getFullYear();
        const m = month < 10 ? "0" + String(month) : month;
        const d = dt.getDate() < 10 ? "0" + String(dt.getDate()) : dt.getDate();
        const h = dt.getHours() < 10 ? "0" + String(dt.getHours()) : dt.getHours();
        const i = dt.getMinutes() < 10 ? "0" + String(dt.getMinutes()) : dt.getMinutes();
        const s = dt.getSeconds() < 10 ? "0" + String(dt.getSeconds()) : dt.getSeconds();
        const ms = dt.getMilliseconds() < 10 ? "0" + String(dt.getMilliseconds()) : dt.getMilliseconds();

        // return `${y}-${m}-${d} ${h}:${i}:${s}`;
        return `${d}-${m}-${y} ${h}:${i}:${s}`;
    },
    dtt: function Dt(dt) {
        dt = new Date(dt);

        const h = dt.getHours() < 10 ? "0" + String(dt.getHours()) : dt.getHours();
        const i = dt.getMinutes() < 10 ? "0" + String(dt.getMinutes()) : dt.getMinutes();
        const s = dt.getSeconds() < 10 ? "0" + String(dt.getSeconds()) : dt.getSeconds();
        // const ms = dt.getMilliseconds() < 10 ? "0" + String(dt.getMilliseconds()) : dt.getMilliseconds();

        return `${h}:${i}:${s}`;
    },
    dttt: function Dt(dt) {
        dt = new Date(dt);
        const month = dt.getMonth() + 1;

        const y = dt.getFullYear();
        const m = month < 10 ? "0" + String(month) : month;
        const d = dt.getDate() < 10 ? "0" + String(dt.getDate()) : dt.getDate();

        const h = dt.getHours() < 10 ? "0" + String(dt.getHours()) : dt.getHours();
        const i = dt.getMinutes() < 10 ? "0" + String(dt.getMinutes()) : dt.getMinutes();
        const s = dt.getSeconds() < 10 ? "0" + String(dt.getSeconds()) : dt.getSeconds();
        const ms = dt.getMilliseconds() < 10 ? "0" + String(dt.getMilliseconds()) : dt.getMilliseconds();

        return `${h}:${i}`;
    },
    fm: function (str) {
        return Number((100 * Number(str)) / 100).toFixed(2);
    },
};

export default helpers;
