import React, {Component} from 'react';
import './style.css';

import {withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import BalanceHistoryEntity from "./BalanceHistoryEntity";
import sub from "../../../chunks/subscriber";

class BotDetailedBalanceHistoryFirst extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subIds:  [],
      loaded:  false,
      history: [],
      page:    1
    };

    this.updateData = this.updateData.bind(this);
    this.showPage = this.showPage.bind(this);
  }

  componentDidMount() {
    const ids = [];

    for (let event of ['bots.update']) {
      const id = sub.subscribe(event, (data) => {
        if (data.name === this.props.match.params.name) {
          this.updateData();
        }
      });

      ids.push(id);
    }

    this.setState({ subIds: ids });
    this.updateData();
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
  }

  updateData() {
    backend.get(`/bot/first-balance-history/${this.props.match.params.name}?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        this.setState({history: data.history});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      });
    })
  }

  showPage(page) {
    this.setState({ page }, () => {
      this.updateData();
    });
  }

  render() {
    return (
        <div className="x_panel">
          <div className="x_title">
            <h2>Balance {this.props.bot.bookmakerFirst ? this.props.bot.bookmakerFirst.replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('gorilla', 'newgioco').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('virginbet', 'pin880').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('olimp', 'max24bet').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet').replace('efbet', 'ifortuna').replace('ligastavok', 'lottoland').replace('tonybet', 'tipsport').replace('stake', 'pinnacle_2').toUpperCase() : ''} (First)</h2>
            <PaginationComponent page={this.state.page} showPage={this.showPage} />
            <div className="clearfix"/>
          </div>
          <div className="x_content">

            <div className="table-responsive">
              <table className="table table-hover bulk_action jambo_table table-striped">
                <thead>
                <tr className="headings">
                  <th className="column-title">Timestamp</th>
                  <th className="column-title" style={{width: '120px'}}>Balance</th>
                  <th className="column-title">Bot</th>
                  <th className="column-title">User</th>
                </tr>
                </thead>
                <tbody>
                {this.state.loaded && (
                    this.state.history.length > 0 ? (
                        this.state.history.map((historyData) => <BalanceHistoryEntity key={historyData._id} data={historyData} curency={this.props.bot.currencyFirst}/> )
                    ) : (
                        <tr>
                          <td colSpan="4">List is empty</td>
                        </tr>
                    )
                )}
                {!this.state.loaded && (
                    <tr>
                      <td colSpan="4"><i className="text-muted">Loading...</i></td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>

          </div>
        </div>



    );
  }
}

export default withRouter(BotDetailedBalanceHistoryFirst);
