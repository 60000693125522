import React from 'react';

import TemplateList from "./src/TemplateList";
import title from "../../chunks/title";

class ExpressStrategies extends React.Component {
  componentDidMount() {
    title('Express Strategies');
  }

  render() {
    return (
        <>
          <TemplateList type="all" permissions={this.props.permissions} user={this.props.user}/>
        </>
    );
  }
}

export default ExpressStrategies;
