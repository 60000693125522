import React, {Component} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './style.css';

import {Link, withRouter} from "react-router-dom";
import backend from "../../../chunks/backend";
import helpers from "../../../chunks/helpers";
import routes from "../../../chunks/routes";
// import UpdateBotModal from "../modals/UpdateBotModal";
import sub from "../../../chunks/subscriber";

class BotDetailedInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      loaded: false,
      showPassword: false,
      bot: {},
      odds: 0,
      money: 0
    };

    this.timeOutInstance = 0;

    this.updateData = this.updateData.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.onChangeOdds = this.onChangeOdds.bind(this);
  }

  onChangeOdds(e) {
    let {value} = e.target;

    if (value === '' || !/^\d+(?:\.)?(?:\d+)?$/gi.test(value)) {
      value = 0;
    }

    this.setState({
      odds: value,
    }, () => {
      backend.get(`/bot/test-odds/${this.state.bot.name}/${this.state.odds}`).then(({data}) => {
        const {success, money} = data;

        if (success) {
          this.setState({money});
        }
      }).catch(console.log);
    });
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: true
    });

    this.timeOutInstance = setTimeout(() => {
      this.setState({
        showPassword: false
      });
    }, 5000);
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
    clearTimeout(this.timeOutInstance);
  }

  componentDidMount() {
    const ids = [];

    for (let event of ['bots.ban', 'bots.unban', 'bots.create', 'bots.update', 'bots.restore', 'bots.trash']) {
      const id = sub.subscribe(event, (data) => {
        this.updateData();
      });

      ids.push(id);
    }

    this.setState({subIds: ids});
    this.updateData();
  }

  updateData() {
    backend.get(`/bot/info/${this.props.match.params.name}`).then(({data}) => {
      if (data.success) {
        let {odds, money} = this.state;
        const {bot} = data;

        if (bot) {
          if (odds === 0) {
            money = bot.global_money || ''
          }

          this.setState({bot, money});
        }
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      });
    });
  }


  render() {
    const link = routes.users.profile.replace(':username', this.state.bot.username);
    const book1 = this.state.bot.bookmakerFirst && this.state.bot.bookmakerFirst.replace('loot', 'nitrobetting').replace('stake', 'pinnacle_2').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('virginbet', 'pin880').replace('efbet', 'ifortuna').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('olimp', 'max24bet').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet')
    const book2 = this.state.bot.bookmakerSecond && this.state.bot.bookmakerSecond.replace('loot', 'nitrobetting').replace('stake', 'pinnacle_2').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('virginbet', 'pin880').replace('efbet', 'ifortuna').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('olimp', 'max24bet').replace('betflag', 'inbet365').replace('omg', '212bet').replace('unikrn', 'rabet777').replace('sbobet', 'simbet')
    const currency1 = this.state.bot.currencyFirst === 'CZK' ? <strong>Kč</strong> : <i
      className={this.state.bot.currencyFirst === 'EUR' ? 'fa fa-eur' : (this.state.bot.currencyFirst === 'GBP' ? 'fa fa-gbp' : (this.state.bot.currencyFirst === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/>
    const currency2 = this.state.bot.currencySecond === 'CZK' ? <strong>Kč</strong> : <i
      className={this.state.bot.currencySecond === 'EUR' ? 'fa fa-eur' : (this.state.bot.currencySecond === 'GBP' ? 'fa fa-gbp' : (this.state.bot.currencySecond === 'RUB' ? 'fa fa-rub' : 'fa fa-usd'))}/>


    return (
      <>
        {this.state.bot.username ? (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <>
                    <span className="mr-1">User:</span>
                    <Link to={link}>
                      <strong>{this.state.bot.username}</strong>
                    </Link>
                  </>
                </li>

                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="mr-1">Status Bot:</span>
                      {this.state.bot.deletedAt ? (<span className="text-danger">Deleted</span>) : (
                        this.state.bot.banned ? <span className="text-warning">Banned</span> : <span className="text-success">Active</span>
                      )}
                    </div>
                    <div>
                      {this.state.bot.botType === 'regular_forks' ? (
                        <>
                <span>
                    <strong>{this.state.bot.botType.replace('regular_forks', 'forks').replace('one_shoulder_forks', 'валуй').toUpperCase()}</strong>
                </span>
                        </>
                      ) : (
                        <>
                <span>
                    <strong>{this.state.bot.botType.replace('regular_forks', 'forks').replace('one_shoulder_forks', 'валуй').toUpperCase()}</strong>
                </span>
                          &nbsp;|&nbsp;
                          <span>
                    <strong>{this.state.bot.singleExpressBetType && this.state.bot.singleExpressBetType.replace('single_bet', 's').replace('single_express_bet', 's_e').replace('express_bet', 'e').toUpperCase()}</strong>
                </span>
                        </>
                      )}
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>Bookmaker:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                     <span className="text-primary">
                    <strong>{book1}</strong>
                    </span>
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      <span className="text-primary">
                    <strong>{book2}</strong>
                    </span>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>Status:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>{this.state.bot.online1 ? (this.state.bot.authOnSite1 ? `${book1} Authorized` : `${book1} Online`) : `${book1} Offline`}</Tooltip>}
                      >
                        <span><i
                          className={this.state.bot.online1 ? (this.state.bot.authOnSite1 ? "fa fa-circle text-success" : "fa fa-circle text-warning") : "fa fa-circle text-danger"}/></span>
                      </OverlayTrigger>
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>{this.state.bot.online2 ? (this.state.bot.authOnSite2 ? `${book2} Authorized` : `${book2} Online`) : `${book2} Offline`}</Tooltip>}
                      >
                        <span><i
                          className={this.state.bot.online2 ? (this.state.bot.authOnSite2 ? "fa fa-circle text-success" : "fa fa-circle text-warning") : "fa fa-circle text-danger"}/></span>
                      </OverlayTrigger>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>Balance:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      {(this.state.bot.balance1 === this.state.bot.deposit1 || this.state.bot.balance1 === 0) ? (
                        <span className="text-primary">
                      <strong className="text-danger">{this.state.bot.balance1}&nbsp;</strong>
                          {currency1}
                          {/*<i className="fa fa-usd green-text"/>*/}
                    </span>
                      ) : (
                        <>
                          {(this.state.bot.balance1 + Number(this.state.bot.withdrawnMoneySum1 || 0)) > this.state.bot.deposit1 ? (
                            <span className="text-primary">
                          <i className="fa fa-caret-up text-success"/>
                          <strong>{this.state.bot.balance1}&nbsp;</strong>
                              {currency1}
                              {/*<i className="fa fa-usd green-text"/>*/}
                        </span>
                          ) : (
                            <span className="text-primary">
                          <i className="fa fa-caret-down text-danger"/>
                          <strong>{this.state.bot.balance1}&nbsp;</strong>
                              {currency1}
                              {/*<i className="fa fa-usd green-text"/>*/}
                        </span>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      {(this.state.bot.balance2 === this.state.bot.deposit2 || this.state.bot.balance2 === 0) ? (
                        <span className="text-primary">
                      <strong>{this.state.bot.balance2}&nbsp;</strong>
                          {currency2}
                          {/*<i className="fa fa-usd green-text"/>*/}
                    </span>
                      ) : (
                        <>
                          {(this.state.bot.balance2 + Number(this.state.bot.withdrawnMoneySum2 || 0)) > this.state.bot.deposit2 ? (
                            <span className="text-primary">
                          <i className="fa fa-caret-up text-success"/>
                          <strong>{this.state.bot.balance2}&nbsp;</strong>
                              {currency2}
                              {/*<i className="fa fa-usd green-text"/>*/}
                        </span>
                          ) : (
                            <span className="text-primary">
                          <i className="fa fa-caret-down text-danger"/>
                          <strong>{this.state.bot.balance2}&nbsp;</strong>
                              {currency2}
                              {/*<i className="fa fa-usd green-text"/>*/}
                        </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>In Play:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                     <span>
                    <strong>{this.state.bot.cashout1}&nbsp;</strong>
                       {currency1}
                       {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                       <span>
                    <strong>{this.state.bot.cashout2}&nbsp;</strong>
                         {currency2}
                         {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>P&L:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      {this.state.bot.deposit1 && (
                        <>
                          {(Number(this.state.bot.balance1) + Number(this.state.bot.withdrawnMoneySum1 || 0)) > Number(this.state.bot.deposit1) ? (
                            <span>
                        <strong className="text-success">
                          {Number((100 * (Number(this.state.bot.balance1) - Number(this.state.bot.deposit1) + Number(this.state.bot.withdrawnMoneySum1 || 0))) / 100).toFixed(2)}
                          &nbsp;
                        </strong>
                              {currency1}
                              {/*<i className="fa fa-usd green-text"/>*/}
                      </span>
                          ) : (
                            <>
                              {this.state.bot.balance1 === this.state.bot.deposit1 ? (
                                <span>
                            <strong>
                              {Number((100 * (Number(this.state.bot.balance1) - Number(this.state.bot.deposit1) + Number(this.state.bot.withdrawnMoneySum1 || 0))) / 100).toFixed(0)}
                              &nbsp;
                            </strong>
                                  {currency1}
                                  {/*<i className="fa fa-usd green-text"/>*/}
                          </span>
                              ) : (
                                <span>
                            <strong className="text-danger">
                              {Number((100 * (Number(this.state.bot.balance1) - Number(this.state.bot.deposit1) + Number(this.state.bot.withdrawnMoneySum1 || 0))) / 100).toFixed(2)}
                              &nbsp;
                            </strong>
                                  {currency1}
                                  {/*<i className="fa fa-usd green-text"/>*/}
                          </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      {this.state.bot.deposit2 && (
                        <>
                          {(this.state.bot.balance2 + Number(this.state.bot.withdrawnMoneySum2 || 0)) > this.state.bot.deposit2 ? (
                            <span>
                        <strong className="text-success">
                          {Number((100 * (this.state.bot.balance2 - this.state.bot.deposit2 + Number(this.state.bot.withdrawnMoneySum2 || 0))) / 100).toFixed(2)}
                          &nbsp;
                        </strong>
                              {currency2}
                              {/*<i className="fa fa-usd green-text"/>*/}
                      </span>
                          ) : (
                            <>
                              {this.state.bot.balance2 === this.state.bot.deposit2 ? (
                                <span>
                            <strong>
                              {Number((100 * (this.state.bot.balance2 - this.state.bot.deposit2 + Number(this.state.bot.withdrawnMoneySum2 || 0))) / 100).toFixed(0)}
                              &nbsp;
                            </strong>
                                  {currency2}
                                  {/*<i className="fa fa-usd green-text"/>*/}
                          </span>
                              ) : (
                                <span>
                            <strong className="text-danger">
                              {Number((100 * (this.state.bot.balance2 - this.state.bot.deposit2 + Number(this.state.bot.withdrawnMoneySum2 || 0))) / 100).toFixed(2)}
                              &nbsp;
                            </strong>
                                  {currency2}
                                  {/*<i className="fa fa-usd green-text"/>*/}
                          </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>Deposit:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                     <span>
                    <strong>{this.state.bot.deposit1}&nbsp;</strong>
                       {currency1}
                       {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      <span>
                    <strong>{this.state.bot.deposit2}&nbsp;</strong>
                        {currency2}
                        {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>Withdrawal:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                     <span>
                    <strong>{this.state.bot.withdrawnMoneySum1 || 0}&nbsp;</strong>
                       {currency1}
                       {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      <span>
                    <strong>{this.state.bot.withdrawnMoneySum2 || 0}&nbsp;</strong>
                        {currency2}
                        {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>Turnover:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                     <span>
                    <strong>{this.state.bot.sumBets1}&nbsp;</strong>
                       {currency1}
                       {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                      <span>
                    <strong>{this.state.bot.sumBets2}&nbsp;</strong>
                        {currency2}
                        {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "30%"}}>Max Balance:</div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                     <span>
                    <strong>{this.state.bot.maxBalance1}&nbsp;</strong>
                       {currency1}
                       {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                    <div className="col-md-4 col-sm-4" style={{maxWidth: "35%"}}>
                       <span>
                    <strong>{this.state.bot.maxBalance2}&nbsp;</strong>
                         {currency2}
                         {/*<i className="fa fa-usd green-text"/>*/}
                  </span>
                    </div>
                  </div>
                </li>


                {/*<li className="list-group-item">*/}
                {/*  <span className="mr-1">Макс. сумма ставок:</span>*/}
                {/*  <strong>{this.state.bot.maxSumBets || 0}</strong> <i className="fa fa-usd green-text"/>*/}
                {/*</li>*/}
                {/*<li className="list-group-item">*/}
                {/*  <span className="mr-1">In Play:</span>*/}
                {/*  <strong>{this.state.bot.cashout || 0}</strong> <i className="fa fa-usd green-text"/>*/}
                {/*</li>*/}
                {/*<li className="list-group-item">*/}
                {/*  <span className="mr-1">Balance:</span>*/}
                {/*  {this.state.bot.balance !== undefined && this.state.bot.balance !== null ? (*/}
                {/*    <>*/}
                {/*      <i className="fa fa-usd green-text"/><strong>{this.state.bot.balance}</strong>*/}
                {/*      {'cost' in this.state.bot && (*/}
                {/*        <>*/}
                {/*          <span className="ml-2">Deposit: <i className="fa fa-usd green-text"/><strong>{this.state.bot.cost}</strong></span>*/}
                {/*          <span className="ml-2">P&L: {this.state.bot.balance === this.state.bot.cost ? (*/}
                {/*            <>*/}
                {/*              <strong className="text-muted">no profit yet</strong>*/}
                {/*            </>*/}
                {/*          ) : (*/}
                {/*            this.state.bot.balance > this.state.bot.cost ? (*/}
                {/*              <>*/}
                {/*                <i className="fa fa-usd green-text"/>*/}
                {/*                <strong*/}
                {/*                  className="text-success">{Number((100 * (this.state.bot.balance - this.state.bot.cost)) / 100).toFixed(2)}</strong>*/}
                {/*              </>*/}
                {/*            ) : (*/}
                {/*              <>*/}
                {/*                <i className="fa fa-usd green-text"/>*/}
                {/*                <strong*/}
                {/*                  className="text-danger">{Number((100 * (this.state.bot.balance - this.state.bot.cost)) / 100).toFixed(2)}</strong>*/}
                {/*              </>*/}
                {/*            )*/}
                {/*          )}</span>*/}
                {/*        </>*/}
                {/*      )}*/}
                {/*    </>*/}
                {/*  ) : (*/}
                {/*    <i className="text-muted">probably not authorized</i>*/}
                {/*  )}*/}
                {/*</li>*/}
                {/*<li className="list-group-item">*/}
                {/*  <span className="mr-1">Password:</span>*/}
                {/*  {this.state.showPassword ? (*/}
                {/*    <strong>{this.state.bot.password}</strong>*/}
                {/*  ) : (*/}
                {/*    <span style={{cursor: 'pointer'}} onClick={this.showPassword} className="badge badge-danger">Show password</span>*/}
                {/*  )}*/}
                {/*</li>*/}
                {/*<li className="list-group-item">*/}
                {/*  <span className="mr-1">Status Bot:</span>*/}
                {/*  {this.state.bot.deletedAt ? (<span className="text-danger">Deleted</span>) : (*/}
                {/*    this.state.bot.banned ? <span className="text-warning">Banned</span> : <span className="text-success">Active</span>*/}
                {/*  )}*/}
                {/*</li>*/}
                {/*<li className="list-group-item">*/}
                {/*  <span className="mr-1">Mirror:</span>*/}
                {/*  {this.state.bot.mirror ? (*/}
                {/*    <a href={this.state.bot.mirror} target="_blank" rel="noreferrer">{this.state.bot.mirror}</a>*/}
                {/*  ) : (*/}
                {/*    <strike>Redudant</strike>*/}
                {/*  )}*/}
                {/*</li>*/}
                {this.state.bot.description && (
                  <li className="list-group-item">
                    <span className="mr-1">Description:</span>
                    <span>{this.state.bot.description}</span>
                  </li>
                )}
                {/*<li className="list-group-item">*/}
                {/*  <span className="mr-1">Global money range:</span>*/}
                {/*  {this.state.bot.global_money ? (*/}
                {/*    <strong><i className="fa fa-usd green-text"/> {this.state.bot.global_money}</strong>*/}
                {/*  ) : (*/}
                {/*    <span className="text-danger">Empty</span>*/}
                {/*  )}*/}
                {/*</li>*/}
                {this.state.bot.oddsRanges && this.state.bot.oddsRanges.length > 0 ? (
                  <li className="list-group-item">
                    <span className="mr-1">Coefficient ranges:</span>
                    {this.state.bot.oddsRanges.map((odds, i) => <div key={i}>
                      <mark>{odds.coef}</mark>
                      <strong className="ml-1"><i className="fa fa-usd green-text"/> {odds.money}</strong></div>)}
                    <div className="mt-2">
                      <label htmlFor="test_odds_input">Test odds. Result: <strong>{this.state.money}</strong></label>
                      <input id="test_odds_input" type="text" className="form-control form-control-sm" value={this.state.odds}
                             onChange={this.onChangeOdds}/>
                    </div>
                  </li>
                ) : ''}
                <li className="list-group-item">
                  <span className="mr-1">Created At:</span>
                  <strong><i className="text-secondary">{helpers.dt(this.state.bot.createdAt)}</i></strong>
                </li>
                {this.state.bot.updatedAt && (
                  <li className="list-group-item">
                    <span className="mr-1">Updated At:</span>
                    <strong><i className="text-info">{helpers.dt(this.state.bot.updatedAt)}</i></strong>
                  </li>
                )}
                {this.state.bot.banned && (
                  <li className="list-group-item">
                    <span className="mr-1">Banned At:</span>
                    <strong><i className="text-warning">{helpers.dt(this.state.bot.banned)}</i></strong>
                  </li>
                )}
                {this.state.bot.deletedAt && (
                  <li className="list-group-item">
                    <span className="mr-1">Deleted At:</span>
                    <strong><i className="text-danger">{helpers.dt(this.state.bot.deletedAt)}</i></strong>
                  </li>
                )}
              </ul>
              {/*<UpdateBotModal data={this.state.bot} name={this.state.bot.name} updateData={this.updateData}/>*/}
            </div>

            {/*<div className="col-md-6 col-sm-6">*/}
            {/*  <ul className="list-group mb-4">*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Тип букмекера:</span>*/}
            {/*      <span><strong>{this.state.bot.bookmakerType}</strong></span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Автологин:</span>*/}
            {/*      <span*/}
            {/*        className={this.state.bot.isAutologin ? "badge badge-success" : "badge badge-danger"}><strong>{this.state.bot.isAutologin ? "YES" : "NO"}</strong></span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Время отложенной ставки:</span>*/}
            {/*      <span><strong>{this.state.bot.delayedBetTime}</strong> sec.</span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Задержка перед отправкой на бота:</span>*/}
            {/*      <span><strong>{this.state.bot.delayBeforeSending}</strong> sec.</span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Кол. отправленных ставок одного исхода:</span>*/}
            {/*      <span><strong>{this.state.bot.maxNumberSentSimilarFork}</strong></span>*/}
            {/*    </li>*/}
            {/*    {this.state.bot.forkSports && this.state.bot.forkSports.length > 0 ? (*/}
            {/*      <li className="list-group-item">*/}
            {/*        <span className="mr-1">Список спортов вилки:</span>*/}
            {/*        {this.state.bot.forkSports.map((forkSport, i) => <div key={i}>*/}
            {/*          <mark>{forkSport}</mark>*/}
            {/*        </div>)}*/}
            {/*      </li>*/}
            {/*    ) : ''}*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Мин. время жизни вилки:</span>*/}
            {/*      <span><strong>{this.state.bot.minLifetimeFork}</strong> sec.</span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Макс. время жизни вилки:</span>*/}
            {/*      <span><strong>{this.state.bot.maxLifetimeFork}</strong> sec.</span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Мин. маржа вилки:</span>*/}
            {/*      <span><strong>{this.state.bot.minPercentInitFork}</strong></span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Макс. маржа вилки:</span>*/}
            {/*      <span><strong>{this.state.bot.maxPercentInitFork}</strong></span>*/}
            {/*    </li>*/}
            {/*    /!*<li className="list-group-item">*!/*/}
            {/*    /!*  <span className="mr-1">Мин. коэффициент вилки:</span>*!/*/}
            {/*    /!*  <span><strong>{this.state.bot.minCoefficientInitFork}</strong></span>*!/*/}
            {/*    /!*</li>*!/*/}
            {/*    /!*<li className="list-group-item">*!/*/}
            {/*    /!*  <span className="mr-1">Макс. коэффициент вилки:</span>*!/*/}
            {/*    /!*  <span><strong>{this.state.bot.maxCoefficientInitFork}</strong></span>*!/*/}
            {/*    /!*</li>*!/*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Мин. маржа конечной ставки:</span>*/}
            {/*      <span><strong>{this.state.bot.minPercentFinalFork}</strong></span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Макс. маржа конечной ставки:</span>*/}
            {/*      <span><strong>{this.state.bot.maxPercentFinalFork}</strong></span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Мин. коэффициент конечной ставки:</span>*/}
            {/*      <span><strong>{this.state.bot.minCoefficientFinalFork}</strong></span>*/}
            {/*    </li>*/}
            {/*    <li className="list-group-item">*/}
            {/*      <span className="mr-1">Макс. коэффициент конечной ставки:</span>*/}
            {/*      <span><strong>{this.state.bot.maxCoefficientFinalFork}</strong></span>*/}
            {/*    </li>*/}


            {/*  </ul>*/}
            {/*</div>*/}
          </div>
        ) : (
          <span className="text-muted">Loading...</span>
        )}
      </>
    );
  }
}

export default withRouter(BotDetailedInfo);
