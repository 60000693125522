import React from 'react';
import helpers from "../../../chunks/helpers";
import routes from "../../../chunks/routes";
import {Link, withRouter} from "react-router-dom";
import { Button, message, Popconfirm } from 'antd';

class TemplateEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  render() {
    const bot = this.props.data;
    const url = routes.users.profile.replace(':username', bot.username);

    const confirm = (bot) => {
      console.log(bot);
      this.props.deleteRange(bot)
      message.success('Strategy Deleted!');
    };
    const cancel = (e) => {
      // console.log(e);
      // message.error('Click on No');
    };

    return (
        <>
          <tr key={this.key}>
            <td>
              <strong className="mr-2">{bot.name}</strong>
            </td>
            <td>
              <strong className="mr-2">{bot.description}</strong>
            </td>
            <td>
              <strong className="mr-2">{bot.oddsRanges.length}</strong>
            </td>
            {this.props.accessToUser ? (
                <td>
                  {bot.username && (
                      <Link to={url}>{bot.username}</Link>
                  )}
                  {!bot.username && (
                      <i className="text-muted">unknown</i>
                  )}
                </td>
            ) : (
                <td>
                  <div className="text-muted">{bot.username}</div>
                </td>
              )}
            <td>
              <small>
                <div>Created At: <i className="text-secondary">{ helpers.dt(bot.createdAt)}</i></div>
                {bot.updatedAt && (
                    <div>Updated At: <i className="text-info">{ helpers.dt(bot.updatedAt) }</i></div>
                )}
              </small>
            </td>
            {/*<td>*/}
            {/*  <div>{bot.oddsRanges.map((userData) => {*/}
            {/*    return <>*/}
            {/*      <span className="badge badge-secondary mr-1">outcomes: <u>${userData.leagues}</u></span>*/}
            {/*      <span className="badge badge-secondary mr-1">leagues: <u>${userData.leagues}</u></span>*/}
            {/*    </>*/}
            {/*  } )}</div>*/}
            {/*</td>*/}
            <td>
              <div className="btn-group">
                <button type="button" onClick={() => this.props.updateRange(bot)} data-toggle="modal" data-target=".modal-money-update"
                        className="btn btn-info">Update
                </button>
                <button type="button" onClick={() => this.props.copyRange(bot)} className="btn btn-warning">Copy</button>

                <Popconfirm
                  title="Delete Strategy"
                  description="Are you sure to delete this Strategy?"
                  onConfirm={() => confirm(bot)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <button type="button" className="btn btn-danger">Delete</button>
                </Popconfirm>
              </div>
            </td>
          </tr>
        </>
    );
  }
}

export default withRouter(TemplateEntity);
