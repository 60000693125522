import React from 'react';
import {Link, withRouter} from "react-router-dom";
import md5 from 'md5-hash';

import routes from "../../chunks/routes";
import backend from "../../chunks/backend";

class LeftSideComponent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            activePage: window.location.hash ? window.location.hash.substr(1) : routes.dashboard
        };

        this.goToNav  = this.goToNav.bind(this);
        this.toggleUl = this.toggleUl.bind(this);
        this.logOut   = this.logOut.bind(this);
        this.updateData   = this.updateData.bind(this);
    }

    componentDidMount() {
        setInterval(() => {
            this.updateData()
        }, 55000);
    }

    updateData() {
        backend.get(`/user/ping`).then(({ data }) => {
            // console.log('PING ', data )
        }).catch(console.log)
    }

    goToNav(e) {
        this.setState({
            activePage: e.nativeEvent.target.getAttribute('href')
        });
    }

    isA(path, name) {
        const check = this.state && this.state.activePage && this.state.activePage.startsWith('#') ? this.state.activePage.substr(1) : this.state.activePage;

        if (typeof path === "object") {
            for (let key in path) {
                if (check === path[key]) {
                    return name;
                }
            }
        }

        return check === path ? name : '';
    }

    toggleUl(e) {
        e.preventDefault();
        let element = e.nativeEvent.target;

        if (element.tagName.toLowerCase() !== 'a') {
            element = element.parentElement;
        }

        let sibling = element.nextSibling;


        if (sibling) {
            const isBlock = (sibling.style.display === 'block');
            sibling.style.display = isBlock ? 'none' : 'block';
        }
    }

    logOut(e) {
        e.preventDefault();
        this.props.onUnAuthorize()
    }

    gravatar() {
        const hash = md5(this.props.user.username).toLowerCase();
        return `https://www.gravatar.com/avatar/${hash}?d=monsterid`;
    }

    sup() {
        if (window.location.host === 'eurobet.billy-lg.com') {
            return 'eurobet';
        } else if (window.location.host === 'parimatch.billy-lg.com') {
            return 'parimatch';
        } else {
            return 'bet365';
        }
    }

    render() {
        const isProfileActive = this.isA(routes.users.profile.replace(':username', this.props.user.username), true);
        const usersActive  = isProfileActive ? true : this.isA(routes.users, true);
        const botsActive   = this.isA(routes.bots, true);
        const gamesActive  = this.isA(routes.games, true);
        const pointsActive = this.isA(routes.points, true);
        const withPoints = (() => {
            return [
                'bet365-point.billy-lg.com',
            ].indexOf(window.location.host) !== -1;
        })();

        return (
            <>
                <div className="col-md-3 left_col">
                    <div className="left_col scroll-view">
                        <div className="navbar nav_title" style={{ border: 0 }}>
                            <a href={`#${routes.bots.active}`} className="site_title">
                                {withPoints ? (
                                    <i className="fas fa-table-tennis mr-2"/>
                                ) : (
                                    <i className="fas fa-robot mr-2"/>
                                )}
                                <span>{withPoints ? 'Points' : 'ForksBot'}</span>
                                <sup className="text-success ml-1"><i className="fas fa-dollar-sign mr-2"/></sup>
                            </a>
                        </div>
                        <div className="clearfix" />
                        <div className="profile clearfix">
                            <div className="profile_pic">
                                <img src={this.gravatar()} alt={this.props.user.username} className="img-circle profile_img" />
                            </div>
                            <div className="profile_info">
                                <span>Welcome,</span>
                                <h2>{this.props.user.username}</h2>
                            </div>
                        </div>
                        <br />
                        {/*<div id="sidebar-menu" className="main_menu_side hidden-print main_menu" style={{ width: '232px' }}>*/}
                        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
                            <div className="menu_section active">
                                <ul className="nav side-menu">
                                    {this.props.user.hasAccess('dashboard') && (
                                        <li onClick={this.goToNav} className={this.isA(routes.dashboard, 'active')}>
                                            <Link to={routes.dashboard}>
                                                <i className="fa fa-laptop"/> Dashboard
                                            </Link>
                                        </li>
                                    )}
                                    <li onClick={this.goToNav} className={this.isA(routes.groups.all, 'active')}>
                                        <Link to={routes.groups.all}>
                                            <i className="fas fa-layer-group"/> Groups
                                        </Link>
                                    </li>
                                    {withPoints && this.props.user.hasAccess('strategy.write') && (
                                        <li onClick={this.goToNav} className={this.isA(routes.strategy.list, 'active')}>
                                            <Link to={routes.strategy.list}>
                                                <i className="fas fa-brain"/> Strategies
                                            </Link>
                                        </li>
                                    )}
                                    {this.props.user.hasAccess('users') && (
                                        <li className={usersActive ? 'active' : ''}>
                                            <Link to="#" onClick={this.toggleUl}>
                                                <i className="fa fa-users"/> Users
                                            </Link>
                                            <ul className="nav child_menu" style={{ display: usersActive ? 'block' : 'none' }}>
                                                {this.props.user.hasAccess('users.myself') && (
                                                    <li onClick={this.goToNav} className={isProfileActive ? 'current-page' : ''}>
                                                        <Link to={routes.users.profile.replace(':username', this.props.user.username)}>My Profile</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('users.online') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.users.online, 'current-page')}>
                                                        <Link to={routes.users.online}>Online</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('users.active') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.users.active, 'current-page')}>
                                                        <Link to={routes.users.active}>Active</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('users.banned') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.users.banned, 'current-page')}>
                                                        <Link to={routes.users.banned}>Banned</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('users.deleted') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.users.deleted, 'current-page')}>
                                                        <Link to={routes.users.deleted}>Deleted</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('users.all') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.users.all, 'current-page')}>
                                                        <Link to={routes.users.all}>All users</Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                    {this.props.user.hasAccess('bots.my_all') && (
                                        <li className={botsActive ? 'active' : ''}>
                                            <Link to="#" onClick={this.toggleUl}>
                                                <i className="fas fa-robot"/> Bots
                                            </Link>
                                            <ul className="nav child_menu" style={{ display: botsActive ? 'block' : 'none' }}>
                                                {this.props.user.hasAccess('bots.my_online') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.bots.online, 'current-page')}>
                                                        <Link to={routes.bots.online}>Online</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('bots.my_all') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.bots.active, 'current-page')}>
                                                        <Link to={routes.bots.active}>Active</Link>
                                                    </li>
                                                )}

                                                {this.props.user.hasAccess('bots.all') && (
                                                  <li onClick={this.goToNav} className={this.isA(routes.bots.archive, 'current-page')}>
                                                      <Link to={routes.bots.archive}>Archive</Link>
                                                  </li>
                                                )}
                                                {this.props.user.hasAccess('bots.all') && (
                                                  <li onClick={this.goToNav} className={this.isA(routes.bots.basket_online, 'current-page')}>
                                                      <Link to={routes.bots.basket_online}>Online Basket</Link>
                                                  </li>
                                                )}
                                                {this.props.user.hasAccess('bots.all') && (
                                                  <li onClick={this.goToNav} className={this.isA(routes.bots.basket_active, 'current-page')}>
                                                      <Link to={routes.bots.basket_active}>Active Basket</Link>
                                                  </li>
                                                )}

                                                {this.props.user.hasAccess('bots.banned') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.bots.banned, 'current-page')}>
                                                        <Link to={routes.bots.banned}>Banned</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('bots.deleted') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.bots.deleted, 'current-page')}>
                                                        <Link to={routes.bots.deleted}>Deleted</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('bots.all') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.bots.all, 'current-page')}>
                                                        <Link to={routes.bots.all}>All bots</Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                    {!withPoints && this.props.user.hasAccess('bots.all') && (
                                      <li onClick={this.goToNav} className={this.isA(routes.simbet_basketball.all, 'active')}>
                                          <Link to={routes.simbet_basketball.all}>
                                              <i className="fa fa-list"/> Basketball
                                          </Link>
                                      </li>
                                    )}
                                    {this.props.user.hasAccess('bots.add_extension') && (
                                        <li onClick={this.goToNav} className={this.isA(routes.extension.all, 'active')}>
                                            <Link to={routes.extension.all}>
                                                <i className="fa fa-puzzle-piece"/> Extensions
                                            </Link>
                                        </li>
                                    )}
                                    {!withPoints && this.props.user.hasAccess('moneys.show_ranges') && (
                                        <li onClick={this.goToNav} className={this.isA(routes.money_ranges.all, 'active')}>
                                            <Link to={routes.money_ranges.all}>
                                                <i className="fa fa-coins"/> Money Ranges
                                            </Link>
                                        </li>
                                    )}
                                    {!withPoints && this.props.user.hasAccess('strategies.create_strategy') && (
                                        <li onClick={this.goToNav} className={this.isA(routes.bet_strategies.all, 'active')}>
                                            <Link to={routes.bet_strategies.all}>
                                                <i className="fa fa-brain"/> Bet Strategies
                                            </Link>
                                        </li>
                                    )}
                                    {!withPoints && this.props.user.hasAccess('strategies.create_strategy') && (
                                      <li onClick={this.goToNav} className={this.isA(routes.flat.all, 'active')}>
                                          <Link to={routes.flat.all}>
                                              <i className="fa fa-coins"/> Flat
                                          </Link>
                                      </li>
                                    )}
                                    {!withPoints && !this.props.withForks && (this.props.user.hasAccess('games.live') || this.props.user.hasAccess('games.history')) && (
                                        <li className={gamesActive ? 'active' : ''}>
                                            <Link to="#" onClick={this.toggleUl}>
                                                <i className="fa fa-trophy"/> Games
                                            </Link>
                                            <ul className="nav child_menu" style={{ display: gamesActive ? 'block' : 'none' }}>
                                                {this.props.user.hasAccess('games.live') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.games.live, 'current-page')}>
                                                        <Link to={routes.games.live}>Live</Link>
                                                    </li>
                                                )}
                                                {this.props.user.hasAccess('games.history') && (
                                                    <li onClick={this.goToNav} className={this.isA(routes.games.archive, 'current-page')}>
                                                        <Link to={routes.games.archive}>Archive</Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                    {this.props.routeToPoints && (
                                        <li className={pointsActive ? 'active' : ''}>
                                            <Link to="#" onClick={this.toggleUl}>
                                                <i className="fas fa-table-tennis"/> Points
                                            </Link>
                                            <ul className="nav child_menu" style={{ display: botsActive ? 'block' : 'none' }}>
                                                <li onClick={this.goToNav} className={this.isA(routes.points.live, 'current-page')}>
                                                    <Link to={routes.points.live}>Live</Link>
                                                </li>
                                                <li onClick={this.goToNav} className={this.isA(routes.points.history, 'current-page')}>
                                                    <Link to={routes.points.history}>History</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    )}

                                    {(this.props.user.hasAccess('games.live') && ['@@Mike@@', '@MIKE@', '@DAVID@', 'webserver', 'khovit', 'xchaos'].includes(this.props.user.data.username)) && (
                                        <li onClick={this.goToNav} className={this.isA(routes.accounting.all, 'active')}>
                                            <Link to={routes.accounting.all}>
                                                <i className="fa fa-database"/> Accounting
                                            </Link>
                                        </li>
                                    )}

                                    {this.props.user.isAuth && (
                                        <li>
                                            <Link to="#" onClick={this.logOut}>
                                                <i className="fa fa-sign-out"/> Sign out
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(LeftSideComponent);
