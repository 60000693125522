import React from 'react';
import helpers from "../../../chunks/helpers";
import routes from "../../../chunks/routes";
import {Link, withRouter} from "react-router-dom";

class TemplateEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  render() {
    const strategy = this.props.data;
    const url = routes.users.profile.replace(':username', strategy.username);

    return (
      <>
        <tr key={this.key}>
          <td>
            <strong className="mr-2">{strategy.name}</strong>
          </td>
          <td>
            <strong className="mr-2">{strategy.description}</strong>
          </td>
          <td>
            <div>{strategy.oddsRanges.map((userData) => <span
              className="badge badge-secondary mr-1">{userData.coef}: <u>${userData.money}</u></span>)}</div>
          </td>
          {this.props.accessToUser ? (
            <td>
              {strategy.username && (
                <Link to={url}>{strategy.username}</Link>
              )}
              {!strategy.username && (
                <i className="text-muted">unknown</i>
              )}
            </td>
          ) : (
            <td>
              <div className="text-muted">{strategy.username}</div>
            </td>
          )}
          <td>
            <small>
              <div>Created At: <i className="text-secondary">{helpers.dt(strategy.createdAt)}</i></div>
              {strategy.updatedAt && (
                <div>Updated At: <i className="text-info">{helpers.dt(strategy.updatedAt)}</i></div>
              )}
            </small>
          </td>
          <td>
            <div className="btn-group">
              <button type="button" onClick={() => this.props.updateRange(strategy)} data-toggle="modal" data-target=".modal-money-update"
                      className="btn btn-info">Update
              </button>
              <button type="button" onClick={() => this.props.deleteRange(strategy)} className="btn btn-danger">Delete</button>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export default withRouter(TemplateEntity);
