import React from 'react'
import { Select } from 'antd'
import BotsEntity from './BotsEntity'
import backend from '../../../chunks/backend'
import PaginationComponent from '../../../components/PaginationComponent'
import sub from '../../../chunks/subscriber'
import AddBotModal from '../modals/AddBotModal'
import UpdateBotModal from '../modals/UpdateBotModal'
import StrategyLineRegularFootball from '../../Strategies/src/StrategyLineRegularFootball'
import StrategyLine from '../../Strategies/src/StrategyLine'
import routes from '../../../chunks/routes'

class BotsList extends React.Component {
  constructor(props) {
    super(props)

    const mirror = (() => {
      const host = window.location.host

      if (host === 'eurobet.billy-lg.com') {
        return 'https://eurobet.it'
      }
      else if (host === 'parimatch.billy-lg.com') {
        return false
      }
      else if (host === 'billy-lg.com' || host === 'bet365-point.billy-lg.com') {
        return 'https://bet365.com'
      }

      return 'https://bet365.com'
    })()

    this.state = {
      goals_status: 'available',
      subIds: [],
      bot: {},
      bots: [],
      users: [],
      filterUsers: [],
      isActive: true,
      loaded: false,
      username: {value: '', hasError: false},
      password: {value: '', hasError: false},
      description: '',
      mirror,
      global_money: '',
      oddsRanges: [
        {coef: '', money: ''},
        {coef: '', money: ''},
        {coef: '', money: ''}
      ],
      page: 1,

      filters: {
        moneyStatus: '',
        workStatus: '',
        username: ''
      },
      usernameBot: '',
      deposit1: 0,
      deposit2: 0,
      bookmakerFirst: '',
      bookmakerSecond: '',
      withdrawnMoneySum1: 0,
      withdrawnMoneySum2: 0,
      botNameMoneySum: '',
      botNameOld: '',
      botName: {value: '', hasError: false},
      usernameFirst: {value: '', hasError: false},
      passwordFirst: {value: '', hasError: false},
      usernameSecond: {value: '', hasError: false},
      passwordSecond: {value: '', hasError: false},
      updateDataInterval: 0
    }

    this.changeFilterUsername = this.changeFilterUsername.bind(this)
    this.changeFilterWorkStatus = this.changeFilterWorkStatus.bind(this)
    this.changeFilterMoneyStatus = this.changeFilterMoneyStatus.bind(this)

    this.sendFormUpdate = this.sendFormUpdate.bind(this)
    this.changeUsername = this.changeUsername.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.changeGlobalMoney = this.changeGlobalMoney.bind(this)
    this.changeDescription = this.changeDescription.bind(this)
    this.changeMirror = this.changeMirror.bind(this)
    this.updateData = this.updateData.bind(this)
    this.updateBot = this.updateBot.bind(this)
    this.banBot = this.banBot.bind(this)
    this.unBanBot = this.unBanBot.bind(this)
    this.deleteBot = this.deleteBot.bind(this)
    this.logOutOcto = this.logOutOcto.bind(this)
    this.addSimbetBasketball = this.addSimbetBasketball.bind(this)
    this.setArchive = this.setArchive.bind(this)
    this.setFinish = this.setFinish.bind(this)
    this.setSingleExpressBetType = this.setSingleExpressBetType.bind(this)
    this.handleDecrement = this.handleDecrement.bind(this)
    this.handleIncrement = this.handleIncrement.bind(this)
    this.handleAmericanCoef = this.handleAmericanCoef.bind(this)
    this.trashBot = this.trashBot.bind(this)
    this.restoreBot = this.restoreBot.bind(this)
    this.openBot = this.openBot.bind(this)
    this.showActive = this.showActive.bind(this)
    this.showDeleted = this.showDeleted.bind(this)
    this.changeCoef = this.changeCoef.bind(this)
    this.changeMoney = this.changeMoney.bind(this)
    this.addMoneyRange = this.addMoneyRange.bind(this)
    this.stopSendBot = this.stopSendBot.bind(this)
    this.disallowBot = this.disallowBot.bind(this)
    this.allowBot = this.allowBot.bind(this)
    this.showPage = this.showPage.bind(this)
    this.updateUsers = this.updateUsers.bind(this)
    this.cloneBot = this.cloneBot.bind(this)
    this.sendFormAdd = this.sendFormAdd.bind(this)
    this.sendFormWithdrawnMoneySum = this.sendFormWithdrawnMoneySum.bind(this)
    this.popUpWithdrawnMoneySum = this.popUpWithdrawnMoneySum.bind(this)
    this.popUpUpdateBot = this.popUpUpdateBot.bind(this)
    this.changeBotName = this.changeBotName.bind(this)
    this.changeUsernameSecond = this.changeUsernameSecond.bind(this)
    this.changeUsernameFirst = this.changeUsernameFirst.bind(this)
    this.changePasswordSecond = this.changePasswordSecond.bind(this)
    this.changePasswordFirst = this.changePasswordFirst.bind(this)
    this.changeDeposit1 = this.changeDeposit1.bind(this)
    this.changeDeposit2 = this.changeDeposit2.bind(this)
    this.changeWithdrawnMoneySum1 = this.changeWithdrawnMoneySum1.bind(this)
    this.changeWithdrawnMoneySum2 = this.changeWithdrawnMoneySum2.bind(this)
    this.onClickStopBots = this.onClickStopBots.bind(this)
    this.onClickStartBots = this.onClickStartBots.bind(this)
    this.downloadBetfairExtension = this.downloadBetfairExtension.bind(this)
    this.downloadSuperbetPlExtension = this.downloadSuperbetPlExtension.bind(this)
    this.downloadExtension1 = this.downloadExtension1.bind(this)
    this.downloadExtension2 = this.downloadExtension2.bind(this)
  }

  componentDidMount() {
    const ids = []

    for (let event of ['bots.ban', 'bots.unban', 'bots.create', 'bots.update', 'bots.restore', 'bots.trash']) {
      const id = sub.subscribe(event, (data) => {
        this.updateData(true)
      })

      ids.push(id)
    }

    this.setState({subIds: ids})
    this.updateData()
    this.updateUsers()
    this.loadData()

    this.setState({
      updateDataInterval: setInterval(() => {
        this.updateData(true)
      }, 20000)
    })
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id)
    }

    clearInterval(this.state.updateDataInterval)
  }

  updateUsers() {
    backend
      .get('/user/active?limit=99999999')
      .then(({data}) => {
        const {users} = data
        this.setState({users})
      })
      .catch(console.log)
  }

  updateData(loaded) {
    if (!loaded) {
      loaded = false
    }

    if (this.props.user && this.props.user.data.username) {
      backend.get(`/user/info/${this.props.user.data.username}`).then((res) => {
        if (res.data.success) {
          const filterUsers = ('filterUsers' in res.data.user) ? res.data.user.filterUsers : []
          this.setState({filterUsers})
        }
      }).catch(console.log).finally(() => {
        // this.setState({
        //     loaded: true
        // })
      })
    }

    this.setState(
      {
        loaded
      },
      () => {
        const f = this.state.filters
        let url = `/bot/${this.props.type}?page=${this.state.page}&f_ms=${f.moneyStatus}&f_ws=${f.workStatus}`

        if (this.props.user.hasAccess('bots.global_list')) {
          url += `&f_un=${f.username}`
        }

        if (!this.props.user.hasAccess('bots.global_list')) {
          url = `/bot/${this.props.type}/${this.props.user.username}?page=${this.state.page}`
        }

        backend
          .get(url)
          .then(({data}) => {
            if (data.success) {
              const bots = data.bots
              this.setState({bots})
            }
          })
          .catch(console.log)
          .finally(() => {
            this.setState({
              loaded: true
            })
          })
      }
    )
  }

  showPage(page) {
    this.setState({page}, () => {
      this.updateData()
    })
  }

  changeUsername(e) {
    this.setState({
      username: {
        value: e.target.value,
        hasError: false
      }
    })
  }

  changeDeposit1(e) {
    // const deposit1 = Number(e.target.value.trim());
    //
    // if (!isNaN(deposit1)) {
    //     this.setState({deposit1});
    // }

    const deposit1 = e.target.value.trim()
    this.setState({deposit1})
  }

  changeDeposit2(e) {
    // const deposit2 = Number(e.target.value.trim());
    //
    // if (!isNaN(deposit2)) {
    //     this.setState({deposit2});
    // }

    const deposit2 = e.target.value.trim()
    this.setState({deposit2})
  }

  changeWithdrawnMoneySum1(e) {
    // const withdrawnMoneySum1 = Number(e.target.value.trim());
    //
    // if (!isNaN(withdrawnMoneySum1)) {
    //     this.setState({withdrawnMoneySum1});
    // }

    const withdrawnMoneySum1 = e.target.value.trim()
    this.setState({withdrawnMoneySum1})
  }

  changeWithdrawnMoneySum2(e) {
    // const withdrawnMoneySum2 = Number(e.target.value.trim());
    //
    // if (!isNaN(withdrawnMoneySum2)) {
    //     this.setState({withdrawnMoneySum2});
    // }

    const withdrawnMoneySum2 = e.target.value.trim()
    this.setState({withdrawnMoneySum2})
  }

  changePassword(e) {
    this.setState({
      password: {
        value: e.target.value,
        hasError: false
      }
    })
  }

  changeGlobalMoney(e) {
    this.setState({global_money: e.target.value})
  }

  changeDescription(e) {
    this.setState({description: e.target.value})
  }

  changeMirror(e) {
    this.setState({mirror: e.target.value})
  }

  changeCoef(e) {
    const ind = `${e.target.id}`.replace('coef-', '')
    const coef = e.target.value
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].coef = coef
    this.setState({oddsRanges: oddsRanges})
  }

  changeMoney(e) {
    const ind = `${e.target.id}`.replace('money-', '')
    const money = e.target.value
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].money = money
    this.setState({oddsRanges: oddsRanges})
  }

  allowBot(e) {
    if ('restricted' in e) {
      backend
        .put(`/bot/allow/${e.name}`)
        .then(({data}) => {
          if (data.success) {
            this.updateData()
          }
        })
        .catch(console.log)
    }
  }

  disallowBot(e) {
    if ('restricted' in e) {
      backend
        .put(`/bot/disallow/${e.name}`)
        .then(({data}) => {
          if (data.success) {
            this.updateData()
          }
        })
        .catch(console.log)
    }
  }

  stopSendBot(e) {
    backend.put(`/bot/update/${e.name}`, {
      isSendToBot: !e.isSendToBot
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  addSimbetBasketball(e) {
    backend.put(`/bot/update/${e.name}`, {
      isSimbetBasketball: !e.isSimbetBasketball
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  setArchive(e) {
    backend.put(`/bot/update/${e.name}`, {
      isArchive: !e.isArchive
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  setFinish(e) {
    backend.put(`/bot/update/${e.name}`, {
      isFinish: !e.isFinish
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  setSingleExpressBetType(bot, betType) {
    // console.log("bot:", bot)
    // console.log("betType:", betType)
    backend.put(`/bot/update/${bot.name}`, {
      singleExpressBetType: betType
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  handleDecrement(e) {
    const minPercentInitForkRF = Number(e.minPercentInitForkRF) - 0.25
    backend.put(`/bot/update/${e.name}`, {
      minPercentInitForkRF: minPercentInitForkRF
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  handleIncrement(e) {
    const minPercentInitForkRF = Number(e.minPercentInitForkRF) + 0.25
    backend.put(`/bot/update/${e.name}`, {
      minPercentInitForkRF: minPercentInitForkRF
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  handleAmericanCoef(name, isAmericanCoef) {
    // console.log('NAME  :', name)
    // console.log('isAmericanCoef:', isAmericanCoef)

    backend.put(`/bot/update/${name}`, {
      isAmericanCoef: isAmericanCoef
    })
      .then((res) => {
        if (res.data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  loadData() {
    backend.get('/system/info').then(({data}) => {
      const {goals_status} = data
      this.setState({goals_status})
    }).catch(console.log)
  }

  onClickStopBots() {
    backend.put(`/system/goals-status/restricted`, {}).then(() => {
      this.loadData()
    }).catch(console.log)
  }

  onClickStartBots() {
    backend.put(`/system/goals-status/available`, {}).then(() => {
      this.loadData()
    }).catch(console.log)
  }

  downloadBetfairExtension(e) {
    const fileName = `${e.name}.zip`
    backend.download(routes.extension.getbetfair.replace(':name', e.name), fileName)
  }

  downloadExtension1(e) {
    const book = e.bookmakerFirst.replace('stake', 'pinnacle_2').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('tonybet', 'tipsport')

    const fileName = `${e.name}_${book}.zip`
    backend.download(routes.extension.get.replace(':name', e.name).replace(':bookmaker', e.bookmakerFirst), fileName)
  }

  downloadExtension2(e) {
    const book = e.bookmakerSecond.replace('stake', 'pinnacle_2').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('tonybet', 'tipsport')

    const fileName = `${e.name}_${book}.zip`
    backend.download(routes.extension.get.replace(':name', e.name).replace(':bookmaker', e.bookmakerSecond), fileName)
  }

  downloadSuperbetPlExtension(e) {
    const fileName = `${e.name}.zip`
    backend.download(routes.extension.getsuperbetpl.replace(':name', e.name), fileName)
  }

  updateBot(e) {
    this.setState({
      username: {
        value: e.name,
        hasError: false
      },
      password: {
        value: e.password,
        hasError: false
      },
      description: e.description,
      mirror: e.mirror,
      global_money: e.global_money,
      oddsRanges: e.oddsRanges
    })
  }

  deleteBot(e) {
    backend
      .delete(`/bot/remove/${e.name}`)
      .then(({data}) => {
        if (data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  trashBot(e) {
    backend
      .delete(`/bot/trash/${e.name}`)
      .then(({data}) => {
        if (data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  logOutOcto(e) {
    backend
      .put(`/bot/log-out-profiles/${e.name}`)
      .then(({data}) => {
        if (data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  restoreBot(e) {
    backend
      .put(`/bot/restore/${e.name}`)
      .then(({data}) => {
        if (data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  banBot(e) {
    backend
      .put(`/bot/ban/${e.name}`)
      .then(({data}) => {
        if (data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  unBanBot(e) {
    backend
      .put(`/bot/unban/${e.name}`)
      .then(({data}) => {
        if (data.success) {
          this.updateData()
        }
      })
      .catch(console.log)
  }

  openBot(e) {
    // window.location.assign('/search/'+this.state.query+'/some-action');
  }

  showActive() {
    this.setState({
      isActive: true,
      loaded: false
    })

    this.updateData()
  }

  showDeleted() {
    this.setState({
      isActive: false,
      loaded: false
    })

    this.updateData()
  }

  addMoneyRange() {
    const oddsRanges = this.state.oddsRanges
    oddsRanges.push({coef: '', money: ''})
    this.setState({oddsRanges})
  }

  sendFormUpdate(e) {
    e.preventDefault()

    const {password} = this.state
    let formWithErrors = false

    if (password.value === '') {
      password.hasError = true
      formWithErrors = true
    }

    if (formWithErrors) {
      this.setState({
        password
      })

      return
    }

    const oddsRanges = this.state.oddsRanges.filter((el) => {
      return `${el.coef}`.trim() !== '' && `${el.money}`.trim() !== ''
    })

    backend
      .put(`/bot/update/${this.state.username.value}`, {
        password: `${this.state.password.value}`.trim(),
        global_money: `${this.state.global_money}`.trim(),
        description: `${this.state.description}`.trim(),
        mirror: `${this.state.mirror}`.trim(),
        oddsRanges: oddsRanges
      })
      .then((res) => {
        if (res.data.success) {
          console.log('Update bot success, Bot: ', this.state.username.value)
          this.updateData()
        }
      })
      .catch(console.log)
      .finally(() => {
        document.querySelector('#modalBotUpdate').querySelector('.close').click()
      })
  }

  changeFilterMoneyStatus(e) {
    const {filters} = this.state
    filters.moneyStatus = e.target.value

    this.setState({filters})
    this.updateData(false)
  }

  changeFilterWorkStatus(e) {
    const {filters} = this.state
    filters.workStatus = e.target.value

    this.setState({filters})
    this.updateData(false)
  }

  changeFilterUsername(value) {
    this.setState({filterUsers: value})

    backend.put(`/user/update/${this.props.user.data.username}`, {
      filterUsers: value || []
    }).then(this.updateData).catch(console.log)
  }

  changeFilterUsername_OLD(e) {
    const {filters} = this.state
    filters.username = e.target.value

    this.setState({filters})
    this.updateData(false)
  }

  cloneBot(bot) {
    this.setState({
      usernameBot: bot.username,
      botNameOld: bot.name,
      bookmakerFirst: bot.bookmakerFirst,
      bookmakerSecond: bot.bookmakerSecond,
      deposit1: 0,
      deposit2: 0,
      botName: {
        value: '',
        hasError: false
      },
      usernameFirst: {
        value: bot.usernameFirst || '',
        hasError: false
      },
      usernameSecond: {
        value: bot.usernameSecond || '',
        hasError: false
      },
      passwordFirst: {
        value: bot.passwordFirst || '',
        hasError: false
      },
      passwordSecond: {
        value: bot.passwordSecond || '',
        hasError: false
      }
    }, () => {
      $('#modalCloneBot').modal({show: true})
    })
  }

  changeBotName(e) {
    this.setState({
      botName: {
        value: e.target.value,
        hasError: false
      }
    })
  }

  changeUsernameFirst(e) {
    this.setState({
      usernameFirst: {
        value: e.target.value,
        hasError: false
      }
    })
  }

  changeUsernameSecond(e) {
    this.setState({
      usernameSecond: {
        value: e.target.value,
        hasError: false
      }
    })
  }

  changePasswordFirst(e) {
    this.setState({
      passwordFirst: {
        value: e.target.value,
        hasError: false
      }
    })
  }

  changePasswordSecond(e) {
    this.setState({
      passwordSecond: {
        value: e.target.value,
        hasError: false
      }
    })
  }

  sendFormAdd(e) {
    e.preventDefault()

    const {usernameFirst, passwordFirst, usernameSecond, passwordSecond, botName, usernameBot, botNameOld, deposit1, deposit2} = this.state

    let formWithErrors = false

    if (`${botName.value}`.trim() === '') {
      botName.hasError = true
      formWithErrors = true
    }

    if (`${usernameFirst.value}`.trim() === '') {
      usernameFirst.hasError = true
      formWithErrors = true
    }

    if (`${usernameSecond.value}`.trim() === '') {
      usernameSecond.hasError = true
      formWithErrors = true
    }

    if (`${passwordFirst.value}`.trim() === '') {
      passwordFirst.hasError = true
      formWithErrors = true
    }

    if (`${passwordSecond.value}`.trim() === '') {
      passwordSecond.hasError = true
      formWithErrors = true
    }

    if (formWithErrors) {
      this.setState({
        botName,
        usernameFirst,
        usernameSecond,
        passwordFirst,
        passwordSecond
      })

      return
    }

    backend
      .post(`/bot/clone/${usernameBot}`, {
        botNameOld: botNameOld,
        botName: `${botName.value}`.trim(),
        deposit1: Number(deposit1),
        deposit2: Number(deposit2),
        usernameFirst: `${usernameFirst.value}`.trim(),
        usernameSecond: `${usernameSecond.value}`.trim(),
        passwordFirst: `${passwordFirst.value}`.trim(),
        passwordSecond: `${passwordSecond.value}`.trim()
      })
      .then(({data}) => {
        if (data.err) {
          console.log(data.err)
        }
        else {
          $('#modalCloneBot').modal('toggle')
          window.location.reload()
        }
      })
      .catch(console.log)
  }

  popUpWithdrawnMoneySum(bot) {
    this.setState({
      usernameBot: bot.username,
      botNameMoneySum: bot.name,
      bookmakerFirst: bot.bookmakerFirst,
      bookmakerSecond: bot.bookmakerSecond,
      withdrawnMoneySum1: 0,
      withdrawnMoneySum2: 0
    }, () => {
      $('#modalWithdrawnMoneySum').modal({show: true})
    })
  }

  popUpUpdateBot(bot) {
    this.setState({
      bot: bot
    }, () => {
      $('#modalUpdateBot').modal({show: true})
    })
  }

  sendFormWithdrawnMoneySum(e) {
    e.preventDefault()

    const {withdrawnMoneySum1, withdrawnMoneySum2, usernameBot, botNameMoneySum} = this.state

    backend
      .post(`/bot/withdrawn-money/${usernameBot}`, {
        usernameBot: usernameBot,
        botName: botNameMoneySum,
        withdrawnMoneySum1: Number(withdrawnMoneySum1),
        withdrawnMoneySum2: Number(withdrawnMoneySum2)
      })
      .then(({data}) => {
        if (data.err) {
          console.log(data.err)
          $('#modalWithdrawnMoneySum').modal('toggle')
        }
        else {
          $('#modalWithdrawnMoneySum').modal('toggle')
          window.location.reload()
        }
      })
      .catch(console.log)
  }

  usersOptions() {
    const options = this.state.users.map(user => (
      {
        label: `${user.username}`,
        value: `${user.username}`
      }
    ))
    return options
  }

  isEmptyObject(obj) {
    return obj === null || obj === undefined || Object.keys(obj).length === 0
  }


  render() {
    const userAccess = this.props.user.hasAccess('bots.global_list')
    const filterClass = userAccess ? 'col-md-3 col-sm-3' : 'col-md-6 col-sm-6'

    const botNameClass = `form-control${this.state.botName.hasError ? ' is-invalid' : ''}`
    const usernameFirstClass = `form-control${this.state.usernameFirst.hasError ? ' is-invalid' : ''}`
    const usernameSecondClass = `form-control${this.state.usernameSecond.hasError ? ' is-invalid' : ''}`
    const passwordFirstClass = `form-control${this.state.passwordFirst.hasError ? ' is-invalid' : ''}`
    const passwordSecondClass = `form-control${this.state.passwordSecond.hasError ? ' is-invalid' : ''}`

    const book1 = this.state.bookmakerFirst.replace('loot', 'nitrobetting').replace('gorilla', 'newgioco').replace('stake', 'pinnacle_2').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('ligastavok', 'lottoland').replace('efbet', 'ifortuna').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('sbobet', 'simbet').replace('virginbet', 'pin880').replace('olimp', 'max24bet').replace('unikrn', 'rabet777').replace('omg', '212bet').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('betflag', 'inbet365')
    const book2 = this.state.bookmakerSecond.replace('loot', 'nitrobetting').replace('gorilla', 'newgioco').replace('stake', 'pinnacle_2').replace('vbet', 'winnbet').replace('dafabet_df', 'winbet_bg').replace('ladbrokes', 'ladbrokes_be').replace('bwin_it', 'superbet_ro').replace('bwin', 'superbet_pl').replace('1win', 'cloudbet_fifa').replace('ligastavok', 'lottoland').replace('efbet', 'ifortuna').replace('tonybet', 'tipsport').replace('zenit', 'chance').replace('sbobet', 'simbet').replace('virginbet', 'pin880').replace('olimp', 'max24bet').replace('unikrn', 'rabet777').replace('omg', '212bet').replace('mostbet', 'ps3838').replace('unibet', 'winbet88').replace('pinup', 'betbetter').replace('betflag', 'inbet365')

    return (
      <>
        <div className="row mb-2">
          <div className="col-md-3 col-sm-3">
            <AddBotModal updateData={this.updateData} username={this.props.user.username} user={this.props.user}/>

            {!this.isEmptyObject(this.state.bot) ? (<UpdateBotModal key={this.state.bot.name} user={this.props.user} data={this.state.bot} name={this.state.bot.name}
                                                    updateData={this.updateData}/>) : <></>}

            <button
              type="button"
              className="btn btn-sm btn-primary mr-2"
              data-toggle="modal"
              data-target=".bs-example-modal-lg"
            >
              Add Bot
            </button>

            {this.props.user.hasAccess('bots.all') && (
              <>
                {this.state.goals_status === 'available' && (
                  <button className="btn btn-sm btn-dark" onClick={this.onClickStopBots}>Боты запущены</button>
                )}
                {this.state.goals_status !== 'available' && (
                  <button className="btn btn-sm btn-danger" onClick={this.onClickStartBots}>Боты остановлены</button>
                )}
              </>
            )}

          </div>
          <div className="col-md-6 col-sm-6">
            <div className="row">
              {/*<div className={filterClass}>*/}
              {/*    <select*/}
              {/*      onChange={this.changeFilterMoneyStatus}*/}
              {/*      className="form-control form-control-sm"*/}
              {/*    >*/}
              {/*        <option value="">- money status -</option>*/}
              {/*        <option value="payed">Payed</option>*/}
              {/*        <option value="unpaid">Unpaid</option>*/}
              {/*    </select>*/}
              {/*</div>*/}
              {/*<div className={filterClass}>*/}
              {/*    <select onChange={this.changeFilterWorkStatus} className="form-control form-control-sm">*/}
              {/*        <option value="">- work status -</option>*/}
              {/*        <option value="on work">working</option>*/}
              {/*        <option value="restricted">restricted</option>*/}
              {/*    </select>*/}
              {/*</div>*/}
              {userAccess && (
                <>
                  {/*<div className="col-md-4 col-sm-4">*/}
                  {/*    <select*/}
                  {/*      onChange={this.changeFilterUsername}*/}
                  {/*      className="form-control form-control-sm"*/}
                  {/*    >*/}
                  {/*        <option value="">- all users -</option>*/}
                  {/*        {this.state.users.map((user, i) => (*/}
                  {/*          <option key={i} value={user.username}>*/}
                  {/*              {user.username}*/}
                  {/*          </option>*/}
                  {/*        ))}*/}
                  {/*    </select>*/}
                  {/*</div>*/}

                  <div className="col-md-12 col-sm-12">
                    <Select
                      mode="multiple"
                      allowClear
                      // size={"large"}
                      style={{
                        width: '100%'
                      }}
                      // listHeight={128}
                      // maxTagCount={5}
                      placeholder="- all users -"
                      value={this.state.filterUsers}
                      defaultValue={this.state.filterUsers}
                      // onChange={this.props.changeLeague}
                      onChange={(value) => this.changeFilterUsername(value)}
                      options={this.usersOptions()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <PaginationComponent showPage={this.showPage} page={this.state.page}/>
          </div>
        </div>
        <div className="row">
          {this.state.loaded &&
            (this.state.bots.length > 0 ? (
              this.state.bots.map((botData) => (
                <BotsEntity
                  key={`${botData._id}`}
                  data={botData}
                  cloneBot={this.cloneBot}
                  updateBot={this.updateBot}
                  deleteBot={this.deleteBot}
                  logOutOcto={this.logOutOcto}
                  addSimbetBasketball={this.addSimbetBasketball}
                  setArchive={this.setArchive}
                  setFinish={this.setFinish}
                  setSingleExpressBetType={this.setSingleExpressBetType}
                  handleDecrement={this.handleDecrement}
                  handleIncrement={this.handleIncrement}
                  handleAmericanCoef={this.handleAmericanCoef}
                  trashBot={this.trashBot}
                  banBot={this.banBot}
                  unBanBot={this.unBanBot}
                  restoreBot={this.restoreBot}
                  allowBot={this.allowBot}
                  disallowBot={this.disallowBot}
                  stopSendBot={this.stopSendBot}
                  downloadExtension1={this.downloadExtension1}
                  downloadExtension2={this.downloadExtension2}
                  downloadBetfairExtension={this.downloadBetfairExtension}
                  downloadSuperbetPlExtension={this.downloadSuperbetPlExtension}
                  popUpWithdrawnMoneySum={this.popUpWithdrawnMoneySum}
                  popUpUpdateBot={this.popUpUpdateBot}
                  userAccess={userAccess}
                />
              ))
            ) : (
              <div>List is empty</div>
            ))}
        </div>

        <div className="modal fade bs-clone-modal-lg modal-clone-bot" id="modalCloneBot" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalCloneLabel">Clone Bot</h4>
                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                </button>
              </div>

              {/* Form add start here */}

              <form className="form-label-left input_mask" onSubmit={this.sendFormAdd}>
                <div className="modal-body">

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Bot Name</label>
                      <input
                        value={this.state.botName.value}
                        onChange={this.changeBotName}
                        className={botNameClass}
                        type="text"
                      />
                      {this.state.botName.hasError && (
                        <small className="text-danger">Invalid bot name</small>
                      )}
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Deposit <strong>{book1}</strong></label>
                      <input
                        value={this.state.deposit1}
                        onChange={this.changeDeposit1}
                        type="text"
                        className="form-control"
                        required=""
                      />
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Deposit <strong>{book2}</strong></label>
                      <input
                        value={this.state.deposit2}
                        onChange={this.changeDeposit2}
                        type="text"
                        className="form-control"
                        required=""
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>User Name <strong>{book1}</strong></label>
                      <input
                        value={this.state.usernameFirst.value}
                        onChange={this.changeUsernameFirst}
                        className={usernameFirstClass}
                        type="text"
                      />
                      {this.state.usernameFirst.hasError && (
                        <small className="text-danger">Invalid user name</small>
                      )}
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                      <label>User Name <strong>{book2}</strong></label>
                      <input
                        value={this.state.usernameSecond.value}
                        onChange={this.changeUsernameSecond}
                        className={usernameSecondClass}
                        type="text"
                      />
                      {this.state.usernameSecond.hasError && (
                        <small className="text-danger">Invalid user name</small>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Password <strong>{book1}</strong></label>
                      <input
                        value={this.state.passwordFirst.value}
                        onChange={this.changePasswordFirst}
                        className={passwordFirstClass}
                        type="text"
                      />
                      {this.state.passwordFirst.hasError && (
                        <small className="text-danger">Invalid password</small>
                      )}
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Password <strong>{book2}</strong></label>
                      <input
                        value={this.state.passwordSecond.value}
                        onChange={this.changePasswordSecond}
                        className={passwordSecondClass}
                        type="text"
                      />
                      {this.state.passwordSecond.hasError && (
                        <small className="text-danger">Invalid password</small>
                      )}
                    </div>
                  </div>

                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-success">Save</button>
                </div>
              </form>

              {/* Form add end here */}

            </div>
          </div>
        </div>

        <div className="modal fade bs-withdrawal-modal-lg modal-clone-bot" id="modalWithdrawnMoneySum" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalCloneLabel">Вывел средства с аккаунта</h4>
                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                </button>
              </div>

              {/* Form add start here */}

              <form className="form-label-left input_mask" onSubmit={this.sendFormWithdrawnMoneySum}>
                <div className="modal-body">

                  {/*<div className="form-row">*/}
                  {/*    <div className="form-group col-md-12 col-sm-12">*/}
                  {/*        <label>Включить "Flat" (при включении перестает работать "Money Ranges" & "Global Money Range")</label>*/}
                  {/*        <div className="custom-control custom-switch form-control control-no-border">*/}
                  {/*            /!*<label className="custom-control-label" htmlFor="isFlat">Stop Loss & Take Profit</label>*!/*/}
                  {/*            <input*/}
                  {/*              checked={this.state.isFlat}*/}
                  {/*              // defaultChecked={this.state.isFlat}*/}
                  {/*              onChange={this.changeIsFlat}*/}
                  {/*              type="checkbox"*/}
                  {/*              className="custom-control-input input-pointer"*/}
                  {/*              id="isFlat"*/}
                  {/*            />*/}
                  {/*            <label className="custom-control-label input-pointer" htmlFor="isFlat"><span*/}
                  {/*              className={this.state.isFlat ? "badge badge-success" : "badge badge-danger"}>{this.state.isFlat ? "YES" : "NO"}</span></label>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}

                  {/*{this.state.isFlat ? (*/}
                  {/*  <></>*/}
                  {/*) : (*/}
                  {/*  <></>*/}
                  {/*)}*/}

                  <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Сумма вывода с <strong>{book1}</strong></label>
                      <input
                        value={this.state.withdrawnMoneySum1}
                        onChange={this.changeWithdrawnMoneySum1}
                        type="text"
                        className="form-control"
                        required=""
                      />
                    </div>
                    <div className="form-group col-md-6 col-sm-6">
                      <label>Сумма вывода с <strong>{book2}</strong></label>
                      <input
                        value={this.state.withdrawnMoneySum2}
                        onChange={this.changeWithdrawnMoneySum2}
                        type="text"
                        className="form-control"
                        required=""
                      />
                    </div>
                  </div>

                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-success">Save</button>
                </div>
              </form>

              {/* Form add end here */}

            </div>
          </div>
        </div>

      </>
    )
  }
}

export default BotsList
