import React from 'react';

// Components
import BeforeComponent from "./components/BeforeComponent";
import LeftSideComponent from "./components/LeftSideComponent";
import TopBarComponent from "./components/TopBarComponent";
import RightSideComponent from "./components/RightSideComponent/inedx";

// Pages
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import ExtensionPage from "./pages/Extension";
import AccountingPage from "./pages/Accounting";
import MoneyRangesPage from "./pages/MoneyRanges";
import SimbetBasketball from "./pages/SimbetBasketball";
import Strategies from "./pages/Strategies";
import Flat from "./pages/Flat";
import GroupsPage from "./pages/Groups";
import GamesLive from "./pages/Games/Live";
import GamesArchive from "./pages/Games/Archive";
import GameDetailed from "./pages/Games/Detailed";
import Points from "./pages/Points";
import PointGame from "./pages/Points/src/PointGame";
import PointStrategy from "./pages/PointStrategy";

import UsersAll from "./pages/Users/UsersAll";
import UsersOnline from "./pages/Users/UsersOnline";
import UsersActive from "./pages/Users/UsersActive";
import UsersBanned from "./pages/Users/UsersBanned";
import UsersDeleted from "./pages/Users/UsersDeleted";
import UserProfile from "./pages/Users/Profile";

import BotsActive from "./pages/Bots/BotsActive";
import BotsAll from "./pages/Bots/BotsAll";
import BotsBasketballOnline from "./pages/Bots/BotsBasketballOnline";
import BotsArchive from "./pages/Bots/BotsArchive";
import BotsBasketActive from "./pages/Bots/BotsBasketActive";
import BotsBanned from "./pages/Bots/BotsBanned";
import BotsDeleted from "./pages/Bots/BotsDeleted";
import BotsOnline from "./pages/Bots/BotsOnline";
import BotDetailed from "./pages/Bots/BotDetailed";

// Chunks
import routes from "./chunks/routes";
import pointsAvailable from "./chunks/pointsAvailable";

// Providers
import UserProvider from "./providers/UserProvider/UserProvider";

import backend from "./chunks/backend";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loaded:      false,
        authData:    false,
        forksAvailable: false,
        permissions: {}
    };

    this.onAuthorize = this.onAuthorize.bind(this);
    this.onUnAuthorize = this.onUnAuthorize.bind(this);
  }

  onAuthorize(data) {
      this.setState({
          authData: new UserProvider(data)
      })
  }

  onUnAuthorize() {
      backend.clearToken();
      this.getAuthData();
  }

  resizeWindow() {
      const leftCol  = document.body.querySelector('.left_col');
      const rightCol = document.querySelector('.right_col');

      if (leftCol && rightCol) {
          rightCol.style.minHeight = leftCol.offsetHeight+'px';
      }
  }

  componentDidMount() {
      setTimeout(this.resizeWindow, 50);
      window.onresize = this.resizeWindow;

      this.getAuthData();
      this.getForksAvailable();
  }

  getForksAvailable() {
      backend.get('/forks/available').then(({ data }) => {
          this.setState({
              forksAvailable: data.success && data.available
          });
      }).catch(console.log);
  }

  getAuthData() {
      backend.get('/user/auth-data').then((res) => {
          this.setState({
              authData: new UserProvider(res.data.user),
              loaded:   true
          });
      }).catch(console.log);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      this.resizeWindow();
  }

  render() {
      const routeToPoints = pointsAvailable(this.state.authData.username || '');

      return (
          <>
              <Router>
                {this.state.loaded && (
                    !this.state.authData.isAuth ? (
                        <LoginPage onAuthorize={this.onAuthorize} />
                    ) : (
                        <div className="container body">
                          <div className="main_container">
                            <LeftSideComponent
                                withForks={this.state.forksAvailable}
                                onUnAuthorize={this.onUnAuthorize}
                                user={this.state.authData}
                                routeToPoints={routeToPoints}
                            />
                            <TopBarComponent user={this.state.authData} />
                            <RightSideComponent>
                                <Switch>
                                    <Route exact={true} path={routes.dashboard}>
                                        <DashboardPage user={this.state.authData} withForks={this.state.forksAvailable} />
                                    </Route>

                                    <Route exact={true} path={routes.extension.all}>
                                        <ExtensionPage user={this.state.authData} />
                                    </Route>

                                    <Route exact={true} path={routes.strategy.list}>
                                        <PointStrategy user={this.state.authData} />
                                    </Route>

                                    <Route exact={true} path={routes.money_ranges.all}>
                                        <MoneyRangesPage user={this.state.authData} />
                                    </Route>
                                  <Route exact={true} path={routes.simbet_basketball.all}>
                                    <SimbetBasketball user={this.state.authData} />
                                  </Route>

                                  <Route exact={true} path={routes.bet_strategies.all}>
                                    <Strategies user={this.state.authData} />
                                  </Route>

                                  <Route exact={true} path={routes.flat.all}>
                                    <Flat user={this.state.authData} />
                                  </Route>

                                    <Route exact={true} path={routes.groups.all}>
                                        <GroupsPage user={this.state.authData} />
                                    </Route>

                                    <Route exact={true} path={routes.users.all}>
                                        <UsersAll user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.users.online}>
                                        <UsersOnline user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.users.active}>
                                        <UsersActive user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.users.banned}>
                                        <UsersBanned user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.users.deleted}>
                                        <UsersDeleted user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.users.profile}>
                                        <UserProfile user={this.state.authData} />
                                    </Route>

                                    <Route exact={true} path={routes.bots.all}>
                                        <BotsAll user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.bots.online}>
                                        <BotsOnline user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.bots.active}>
                                        <BotsActive user={this.state.authData} />
                                    </Route>

                                  <Route exact={true} path={routes.bots.archive}>
                                    <BotsArchive user={this.state.authData} />
                                  </Route>
                                  <Route exact={true} path={routes.bots.basket_online}>
                                    <BotsBasketballOnline user={this.state.authData} />
                                  </Route>
                                  <Route exact={true} path={routes.bots.basket_active}>
                                    <BotsBasketActive user={this.state.authData} />
                                  </Route>

                                    <Route exact={true} path={routes.bots.banned}>
                                        <BotsBanned user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.bots.deleted}>
                                        <BotsDeleted user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.bots.detailed}>
                                        <BotDetailed user={this.state.authData} />
                                    </Route>

                                    <Route exact={true} path={routes.points.live}>
                                        <Points type="live" user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.points.history}>
                                        <Points type="history" user={this.state.authData} />
                                    </Route>
                                    <Route exact={true} path={routes.points.detailed}>
                                        <PointGame user={this.state.authData} />
                                    </Route>

                                    <Route exact={true} path={routes.accounting.all}>
                                      <AccountingPage user={this.state.authData} />
                                    </Route>

                                    {!this.state.forksAvailable && (
                                        <>
                                            <Route exact={true} path={routes.games.live}>
                                                <GamesLive user={this.state.authData} />
                                            </Route>
                                            <Route exact={true} path={routes.games.archive}>
                                                <GamesArchive user={this.state.authData} />
                                            </Route>
                                            <Route exact={true} path={routes.games.detailed}>
                                                <GameDetailed user={this.state.authData} detailed={true} />
                                            </Route>
                                            <Route exact={true} path={routes.games.history}>
                                                <GameDetailed user={this.state.authData} detailed={false} />
                                            </Route>
                                        </>
                                    )}
                                </Switch>
                            </RightSideComponent>
                          </div>
                        </div>
                    )
                )}
                {!this.state.loaded && (
                    <BeforeComponent />
                )}
              </Router>
          </>
      );
  }
}

export default App;
