import React from 'react';
import {Collapse} from 'antd';
import TemplateEntity from "./TemplateEntity";
import backend from "../../../chunks/backend";
import PaginationComponent from "../../../components/PaginationComponent";
import StrategyLine from "./StrategyLine";
import StrategyLineRegularFootball from "./StrategyLineRegularFootball";
import sub from "../../../chunks/subscriber";

const {Panel} = Collapse;

class TemplateList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      subIds: [],
      strategies: [],
      users: [],
      loaded: false,
      name: {value: '', hasError: false},
      description: '',
      oddsRanges: [{
        bookmaker: 'simbet',
        isRegularFootball: true,
        isReverse: false,
        isLeague: false,
        leagues: [],
        isOutcome: false,
        outcome: '',
        isTime: false,
        times: ['00:00', '00:00'],
        time1: 0,
        time2: 0,
        isScores: false,
        firstTeamScore: 0,
        isFirstTeamScore: false,
        secondTeamScore: 0,
        isSecondTeamScore: false,
        intervalOverUnder: '',
        isMoney: false,
        moneyFirstBet: 1,
        isOdds: false,
        minOdds: 1,
        maxOdds: 1,
        isMargin: false,
        minMargin: -10,
        maxMargin: 0,
      }],
      isPlaceBet: true,
      page: 1,
      username: this.props.user.username
    };

    this.sendFormAdd = this.sendFormAdd.bind(this);
    this.sendFormUpdate = this.sendFormUpdate.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateRange = this.updateRange.bind(this);
    this.deleteRange = this.deleteRange.bind(this);
    this.copyRange = this.copyRange.bind(this);
    this.addCyberFootball = this.addCyberFootball.bind(this);
    this.addRegularFootball = this.addRegularFootball.bind(this);
    this.clearRangeState = this.clearRangeState.bind(this);
    this.changeIsPlaceBet = this.changeIsPlaceBet.bind(this);
    this.changeIsLeague = this.changeIsLeague.bind(this);
    this.changeBookmaker = this.changeBookmaker.bind(this);
    this.changeLeague = this.changeLeague.bind(this);
    this.changeIsOutcome = this.changeIsOutcome.bind(this);
    this.changeIsReverse = this.changeIsReverse.bind(this);
    this.changeOutcome = this.changeOutcome.bind(this);
    this.changeIsTime = this.changeIsTime.bind(this);
    this.changeTime = this.changeTime.bind(this);
    this.changeIsMargin = this.changeIsMargin.bind(this);
    this.changeIsMoney = this.changeIsMoney.bind(this);
    this.changeMoneyFirstBet = this.changeMoneyFirstBet.bind(this);
    this.changeIsOdds = this.changeIsOdds.bind(this);
    this.changeIsScores = this.changeIsScores.bind(this);
    this.changeIntervalOverUnder = this.changeIntervalOverUnder.bind(this);
    this.changeTime1 = this.changeTime1.bind(this);
    this.changeTime2 = this.changeTime2.bind(this);
    this.changeFirstTeamScore = this.changeFirstTeamScore.bind(this);
    this.changeIsFirstTeamScore = this.changeIsFirstTeamScore.bind(this);
    this.changeSecondTeamScore = this.changeSecondTeamScore.bind(this);
    this.changeIsSecondTeamScore = this.changeIsSecondTeamScore.bind(this);
    this.changeMinOdds = this.changeMinOdds.bind(this);
    this.changeMaxOdds = this.changeMaxOdds.bind(this);
    this.changeMinMargin = this.changeMinMargin.bind(this);
    this.changeMaxMargin = this.changeMaxMargin.bind(this);

    this.showPage = this.showPage.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.removeMoneyLinePosition = this.removeMoneyLinePosition.bind(this);
    this.cloneMoneyLinePosition = this.cloneMoneyLinePosition.bind(this);
  }

  loadUsers() {
    backend.get('/user/active').then(({data}) => {
      const {users} = data;
      this.setState({users});
    }).catch(console.log);
  }

  componentDidMount() {
    const ids = [];
    for (let event of ['strategies.create_strategy', 'strategies.update_strategy']) {
      const id = sub.subscribe(event, (data) => {
        // console.log('StrategiesList', data.event);
        this.updateData();
      });

      ids.push(id);
    }

    this.setState({subIds: ids});
    this.updateData();

    if (
        this.props.user.hasAccess('moneys.assign_user')
    ) {
      this.loadUsers();
    }
  }

  componentWillUnmount() {
    for (let id of this.state.subIds) {
      sub.unsubscribe(id);
    }
  }

  updateData() {
    backend.get(`/bet-strategies?page=${this.state.page}`).then(({data}) => {
      if (data.success) {
        const strategies = data.strategies
        this.setState({strategies: strategies});
      }
    }).catch(console.log).finally(() => {
      this.setState({
        loaded: true
      })
    })
  }

  showPage(page) {
    this.setState({page, loaded: false}, () => {
      this.updateData();
    });
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  changeName(e) {
    this.setState({
      name: {
        value: e.target.value,
        hasError: false
      }
    });
  }

  changeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  changeIsLeague(e) {
    const ind = Number(`${e.target.id}`.replace('isLeague-', ''))
    const isLeague = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isLeague = isLeague
    this.setState({oddsRanges: oddsRanges});
  }

  changeLeague(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].leagues = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeBookmaker(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].bookmaker = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsReverse(e) {
    const ind = Number(`${e.target.id}`.replace('isReverse-', ''))
    const isReverse = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isReverse = isReverse
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsOutcome(e) {
    const ind = Number(`${e.target.id}`.replace('isOutcome-', ''))
    const isOutcome = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isOutcome = isOutcome
    this.setState({oddsRanges: oddsRanges});
  }

  changeOutcome(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].outcome = value || ''
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsTime(e) {
    const ind = Number(`${e.target.id}`.replace('isTime-', ''))
    const isTime = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isTime = isTime
    this.setState({oddsRanges: oddsRanges});
  }

  changeTime(ind, value) {
    const times = value[0] ? value : ['00:00', '00:00']
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].times = times
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsMargin(e) {
    const ind = Number(`${e.target.id}`.replace('isMargin-', ''))
    const isMargin = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isMargin = isMargin
    this.setState({oddsRanges: oddsRanges});
  }

  changeMinMargin(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].minMargin = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeMaxMargin(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].maxMargin = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsMoney(e) {
    const ind = Number(`${e.target.id}`.replace('isMoney-', ''))
    const isMoney = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isMoney = isMoney
    this.setState({oddsRanges: oddsRanges});
  }

  changeMoneyFirstBet(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].moneyFirstBet = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsOdds(e) {
    const ind = Number(`${e.target.id}`.replace('isOdds-', ''))
    const isOdds = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isOdds = isOdds
    this.setState({oddsRanges: oddsRanges});
  }

  changeMinOdds(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].minOdds = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeMaxOdds(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].maxOdds = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsScores(e) {
    const ind = Number(`${e.target.id}`.replace('isScores-', ''))
    const isScores = e.target.checked
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isScores = isScores
    this.setState({oddsRanges: oddsRanges});
  }

  // changeIsPlaceBet(ind, value) {
  //   const isPlaceBet = value === 'place_bet'
  //   const oddsRanges = this.state.oddsRanges
  //   oddsRanges[ind].isPlaceBet = isPlaceBet
  //   this.setState({oddsRanges: oddsRanges});
  // }

  changeIsPlaceBet(value) {
    const isPlaceBet = value === 'place_bet'
    this.setState({
      isPlaceBet: isPlaceBet,
    });
  }

  changeIntervalOverUnder(ind, e) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].intervalOverUnder = e.target.value || ''
    this.setState({oddsRanges: oddsRanges});
  }

  changeTime1(ind, value) {
    console.log("ind:", ind)
    console.log("value:", value)
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].time1 = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeTime2(ind, value) {
    console.log("ind:", ind)
    console.log("value:", value)
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].time2 = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeFirstTeamScore(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].firstTeamScore = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsFirstTeamScore(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isFirstTeamScore = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeSecondTeamScore(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].secondTeamScore = value
    this.setState({oddsRanges: oddsRanges});
  }

  changeIsSecondTeamScore(ind, value) {
    const oddsRanges = this.state.oddsRanges
    oddsRanges[ind].isSecondTeamScore = value
    this.setState({oddsRanges: oddsRanges});
  }

  updateRange(e) {
    this.setState({
      name: {
        value: e.name,
        hasError: false
      },
      description: e.description || '',
      isPlaceBet: e.isPlaceBet,
      username: e.username || '',
      oddsRanges: e.oddsRanges
    });
  }

  deleteRange(e) {
    backend.delete(`/bet-strategies/${e.name}`).then(({data}) => {
      if (data.success) {
        console.log('Remove success, Bot: ', e.name)
        this.updateData()
      }
    }).catch(console.log)
  }

  copyRange(e) {
    // console.log('COPY: ', e)
    document.querySelector('[data-target=".modal-money-add"]').click()
    this.setState({
      name: {
        value: `${e.name}-COPY`,
        hasError: false
      },
      description: e.description || '',
      isPlaceBet: e.isPlaceBet,
      username: e.username || '',
      oddsRanges: e.oddsRanges
    });
  }

  addCyberFootball() {
    const oddsRanges = this.state.oddsRanges;
    oddsRanges.push({
      bookmaker: 'simbet',
      isRegularFootball: false,
      isReverse: false,
      isLeague: false,
      leagues: [],
      isOutcome: false,
      outcome: '',
      isTime: false,
      times: ['00:00', '00:00'],
      time1: 0,
      time2: 0,
      isScores: false,
      firstTeamScore: 0,
      isFirstTeamScore: false,
      secondTeamScore: 0,
      isSecondTeamScore: false,
      intervalOverUnder: '',
      isMoney: false,
      moneyFirstBet: 1,
      isOdds: false,
      minOdds: 1,
      maxOdds: 1,
      isMargin: false,
      minMargin: -10,
      maxMargin: 0,
    })
    this.setState({oddsRanges});
  }

  addRegularFootball() {
    const oddsRanges = this.state.oddsRanges;
    oddsRanges.push({
      bookmaker: 'simbet',
      isRegularFootball: true,
      isReverse: false,
      isLeague: false,
      leagues: [],
      isOutcome: false,
      outcome: '',
      isTime: false,
      times: ['00:00', '00:00'],
      time1: 0,
      time2: 0,
      isScores: false,
      firstTeamScore: 0,
      isFirstTeamScore: false,
      secondTeamScore: 0,
      isSecondTeamScore: false,
      intervalOverUnder: '',
      isMoney: false,
      moneyFirstBet: 1,
      isOdds: false,
      minOdds: 1,
      maxOdds: 1,
      isMargin: false,
      minMargin: -10,
      maxMargin: 0,
    })
    this.setState({oddsRanges});
  }

  clearRangeState() {
    this.setState({
      username: this.props.user.username || '',
      name: {value: '', hasError: false},
      description: '',
      isPlaceBet: true,
      oddsRanges: [{
        bookmaker: 'simbet',
        isRegularFootball: true,
        isReverse: false,
        isLeague: false,
        leagues: [],
        isOutcome: false,
        outcome: '',
        isTime: false,
        times: ['00:00', '00:00'],
        time1: 0,
        time2: 0,
        isScores: false,
        firstTeamScore: 0,
        isFirstTeamScore: false,
        secondTeamScore: 0,
        isSecondTeamScore: false,
        intervalOverUnder: '',
        isMoney: false,
        moneyFirstBet: 1,
        isOdds: false,
        minOdds: 1,
        maxOdds: 1,
        isMargin: false,
        minMargin: -10,
        maxMargin: 0,
      }]
    });
  }

  sendFormUpdate(e) {
    e.preventDefault();

    const oddsRanges = this.state.oddsRanges.filter((el) => {
      return (el.isLeague || el.isOutcome || el.isTime || el.isScores || el.isOdds || el.isMoney || el.isMargin);
    });

    if (oddsRanges.length === 0) {
      return;
    }

    backend.put(`/bet-strategies/${this.state.name.value.trim()}`, {
      oddsRanges: oddsRanges,
      description: `${this.state.description}`.trim(),
      username: this.state.username,
      isPlaceBet: this.state.isPlaceBet,
    }).catch(console.log).finally(() => {
      document.querySelector('#modalMoneyUpdate').querySelector('.close').click()
      this.updateData()
    })
  }

  sendFormAdd(e) {
    e.preventDefault();

    const {name} = this.state;
    let formWithErrors = false;

    if (`${name.value}`.trim() === '') {
      name.hasError = true;
      formWithErrors = true;
    }

    if (formWithErrors) {
      this.setState({
        name
      });

      return;
    }

    const oddsRanges = this.state.oddsRanges.filter((el) => {
      return (el.isLeague || el.isOutcome || el.isTime || el.isScores || el.isOdds || el.isMoney || el.isMargin);
    });

    if (oddsRanges.length === 0) {
      return;
    }

    backend.post(`/bet-strategies`, {
      name: `${this.state.name.value}`.trim(),
      description: `${this.state.description}`.trim(),
      username: this.state.username,
      isPlaceBet: this.state.isPlaceBet,
      oddsRanges: oddsRanges,
    }).catch(console.log).finally(() => {
      document.querySelector('#modalMoneyAdd').querySelector('.close').click()
      this.updateData()
    })
  }

  removeMoneyLinePosition(e) {
    const {oddsRanges} = this.state;
    const i = e.target.getAttribute('data-index');
    oddsRanges.splice(i, 1);
    this.setState({oddsRanges});
  }

  cloneMoneyLinePosition(e) {
    const {oddsRanges} = this.state;
    const i = e.target.getAttribute('data-index');
    const rangeNew = JSON.parse(JSON.stringify(oddsRanges[i]))
    const oddsRangesNew = JSON.parse(JSON.stringify(oddsRanges))
    oddsRangesNew.push(rangeNew)
    this.setState({oddsRanges: oddsRangesNew});
  }

  genExtra(isReverse) {
    return <span>Реверс: <span className={isReverse ? "badge badge-success" : "badge badge-danger"}>{isReverse ? "YES" : "NO"}</span></span>
  }


  render() {
    const nameClass = `form-control${this.state.name.hasError ? ' is-invalid' : ''}`;

    return (
        <>
          <div className="page-title">
            <div className="_title_left">
              <div className="x_content">
                <button type="button" onClick={this.clearRangeState} className="btn btn-primary" data-toggle="modal" data-target=".modal-money-add">Add Strategy</button>
                <PaginationComponent showPage={this.showPage} page={this.state.page}/>
                <div className="modal fade bs-example-modal-lg modal-money-add" id="modalMoneyAdd" tabIndex="-1" role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">Add Bet Strategy</h4>
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                        </button>
                      </div>

                      {/* Form add start here */}
                      <form className="form-label-left input_mask" onSubmit={this.sendFormAdd}>
                        <div className="modal-body">

                          <div className="form-row">
                            <div className="form-group col-md-12 col-sm-12">
                              <label>Название</label>
                              <input
                                  value={this.state.name.value}
                                  onChange={this.changeName}
                                  className={nameClass}
                                  type="text"
                              />
                              {this.state.name.hasError && (
                                  <small className="text-danger">Invalid Name</small>
                              )}
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12 col-sm-12">
                              <label>Описание</label>
                              <input
                                  value={this.state.description}
                                  onChange={this.changeDescription}
                                  className="form-control"
                                  type="text"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12 col-sm-12">
                              <label>"Ставить" - ставка СТАВИТСЯ при выполнении всей цепочки параметров хотябы в одном условии.</label>
                              <label>"Не ставить" - ставка НЕ СТАВИТСЯ при выполнении хотябы одного параметра в любом из условий.</label>
                              <div className="btn-group btn-group-toggle col-md-12 col-sm-12">
                                <label className={`btn input-pointer btn-secondary${this.state.isPlaceBet === true ? ' active' : ''}`}>
                                  <input
                                      value="place_bet"
                                      // id={`place_bet-${this.props.ind}`}
                                      checked={this.state.isPlaceBet === true}
                                      // defaultChecked={this.props.data.isPlaceBet === true}
                                      onChange={(e) => this.changeIsPlaceBet(e.target.value)}
                                      name="isPlaceBet"
                                      type="radio"
                                      autoComplete="off"
                                  />
                                  Ставить
                                </label>
                                <label className={`btn input-pointer btn-secondary${this.state.isPlaceBet === false ? ' active' : ''}`}>
                                  <input
                                      value="no_place_bet"
                                      // id={`no_place_bet-${this.props.ind}`}
                                      checked={this.state.isPlaceBet === false}
                                      // defaultChecked={this.props.data.isPlaceBet === false}
                                      onChange={(e) => this.changeIsPlaceBet(e.target.value)}
                                      name="isPlaceBet"
                                      type="radio"
                                      autoComplete="off"
                                  />
                                  Не ставить
                                </label>
                              </div>
                            </div>
                          </div>

                          {/*<div className="form-row">*/}
                          {/*  <div className="form-group col-md-12 col-sm-12">*/}
                          {/*    <label>Все условия этой стратегии будут выполняться, чтобы ставку "Не ставить" | "Ставить"</label>*/}
                          {/*    <div className="btn-group btn-group-toggle col-md-12 col-sm-12">*/}
                          {/*      <label className={`btn input-pointer btn-secondary${this.state.isNotBet === true ? ' active' : ''}`}>*/}
                          {/*        <input*/}
                          {/*            value="do_not_bet"*/}
                          {/*            id="do_not_bet"*/}
                          {/*            checked={this.state.isNotBet === true}*/}
                          {/*            // defaultChecked={this.state.botType === "waiting_forks"}*/}
                          {/*            onChange={this.changeIsNotBet}*/}
                          {/*            name="isNotBet"*/}
                          {/*            type="radio"*/}
                          {/*            autoComplete="off"*/}
                          {/*        />*/}
                          {/*        Не ставить*/}
                          {/*      </label>*/}
                          {/*      <label className={`btn input-pointer btn-secondary${this.state.isNotBet === false ? ' active' : ''}`}>*/}
                          {/*        <input*/}
                          {/*            value="place_bet"*/}
                          {/*            id="place_bet"*/}
                          {/*            checked={this.state.isNotBet === false}*/}
                          {/*            // defaultChecked={this.state.botType === "regular_forks"}*/}
                          {/*            onChange={this.changeIsNotBet}*/}
                          {/*            name="isNotBet"*/}
                          {/*            type="radio"*/}
                          {/*            autoComplete="off"*/}
                          {/*        />*/}
                          {/*        Ставить*/}
                          {/*      </label>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*</div>*/}

                          {/*{this.props.user.hasAccess('moneys.assign_user') && (*/}
                          {/*    <div className="form-group row has-feedback">*/}
                          {/*      <label className="col-form-label col-md-3 col-sm-3 ">Assigned user</label>*/}
                          {/*      <div className="col-md-9 col-sm-9 ">*/}
                          {/*        <select onChange={this.onChangeUsername} className="form-control" defaultValue={this.state.username}>*/}
                          {/*          {this.state.users.map((user, i) => (*/}
                          {/*              <option key={i} value={user.username}>{user.username}</option>*/}
                          {/*          ))}*/}
                          {/*        </select>*/}
                          {/*      </div>*/}
                          {/*    </div>*/}
                          {/*)}*/}

                          <div className="form-group row">
                            {/*<label className="col-form-label col-md-3 col-sm-3 ">Добавить новое условие</label>*/}
                            <div className="col-md-12 col-sm-12 ">
                              <button type="button" onClick={this.addRegularFootball} className="btn btn-info btn-sm mt-1">
                                <i className="fa fa-plus"/> {" "} Добавить условие(футбол)
                              </button>
                              <button type="button" onClick={this.addCyberFootball} className="btn btn-primary btn-sm mt-1">
                                <i className="fa fa-plus"/> {" "} Добавить условие(киберфутбол)
                              </button>
                            </div>
                          </div>

                          <Collapse accordion>
                            {this.state.oddsRanges.map((oddsData, i) =>
                                <Panel extra={this.genExtra(oddsData.isReverse)}
                                       header={`Условие: ${i + 1}${oddsData.isScores ? ` |  Счет: ${oddsData.firstTeamScore}:${oddsData.secondTeamScore}` : ''}${oddsData.isTime ? ` | Время: ${oddsData.time1}-${oddsData.time2}` : ''}${oddsData.isOutcome ? ` | ${oddsData.outcome}${oddsData.intervalOverUnder ? ` - ${oddsData.intervalOverUnder}` : ''}` : ''}`}
                                       key={i}>
                                  {oddsData.isRegularFootball ?
                                      (<>
                                        <StrategyLineRegularFootball
                                            key={i}
                                            ind={i}
                                            data={oddsData}
                                            hasRemoveBtn={true}
                                            removeMoneyLinePosition={this.removeMoneyLinePosition}
                                            cloneMoneyLinePosition={this.cloneMoneyLinePosition}
                                            isPlaceBet={this.state.isPlaceBet}
                                            changeIsReverse={this.changeIsReverse}
                                            changeIsLeague={this.changeIsLeague}
                                            changeBookmaker={this.changeBookmaker}
                                            changeLeague={this.changeLeague}
                                            changeIsOutcome={this.changeIsOutcome}
                                            changeOutcome={this.changeOutcome}
                                            changeIsTime={this.changeIsTime}
                                            changeTime={this.changeTime}
                                            changeIsMargin={this.changeIsMargin}
                                            changeMinMargin={this.changeMinMargin}
                                            changeMaxMargin={this.changeMaxMargin}
                                            changeIsMoney={this.changeIsMoney}
                                            changeMoneyFirstBet={this.changeMoneyFirstBet}
                                            changeIsOdds={this.changeIsOdds}
                                            changeMinOdds={this.changeMinOdds}
                                            changeMaxOdds={this.changeMaxOdds}
                                            changeIsScores={this.changeIsScores}
                                            // changeIsPlaceBet={this.changeIsPlaceBet}
                                            changeIntervalOverUnder={this.changeIntervalOverUnder}
                                            changeTime1={this.changeTime1}
                                            changeTime2={this.changeTime2}
                                            changeFirstTeamScore={this.changeFirstTeamScore}
                                            changeIsFirstTeamScore={this.changeIsFirstTeamScore}
                                            changeSecondTeamScore={this.changeSecondTeamScore}
                                            changeIsSecondTeamScore={this.changeIsSecondTeamScore}
                                        />
                                      </>) :
                                      (<>
                                        <StrategyLine
                                            key={i}
                                            ind={i}
                                            data={oddsData}
                                            hasRemoveBtn={true}
                                            removeMoneyLinePosition={this.removeMoneyLinePosition}
                                            cloneMoneyLinePosition={this.cloneMoneyLinePosition}
                                            isPlaceBet={this.state.isPlaceBet}
                                            changeIsReverse={this.changeIsReverse}
                                            changeIsLeague={this.changeIsLeague}
                                            changeBookmaker={this.changeBookmaker}
                                            changeLeague={this.changeLeague}
                                            changeIsOutcome={this.changeIsOutcome}
                                            changeOutcome={this.changeOutcome}
                                            changeIsTime={this.changeIsTime}
                                            changeTime={this.changeTime}
                                            changeIsMargin={this.changeIsMargin}
                                            changeMinMargin={this.changeMinMargin}
                                            changeMaxMargin={this.changeMaxMargin}
                                            changeIsMoney={this.changeIsMoney}
                                            changeMoneyFirstBet={this.changeMoneyFirstBet}
                                            changeIsOdds={this.changeIsOdds}
                                            changeMinOdds={this.changeMinOdds}
                                            changeMaxOdds={this.changeMaxOdds}
                                            changeIsScores={this.changeIsScores}
                                            // changeIsPlaceBet={this.changeIsPlaceBet}
                                            changeIntervalOverUnder={this.changeIntervalOverUnder}
                                            changeTime1={this.changeTime1}
                                            changeTime2={this.changeTime2}
                                            changeFirstTeamScore={this.changeFirstTeamScore}
                                            changeIsFirstTeamScore={this.changeIsFirstTeamScore}
                                            changeSecondTeamScore={this.changeSecondTeamScore}
                                            changeIsSecondTeamScore={this.changeIsSecondTeamScore}
                                        />
                                      </>)
                                  }
                                </Panel>
                            )
                            }
                          </Collapse>

                        </div>

                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="submit" className="btn btn-success">Save</button>
                        </div>
                      </form>
                      {/* Form add end here */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"/>
          <div className="table-responsive">
            <table className="table table-hover bulk_action jambo_table table-striped">
              <thead>
              <tr className="headings">
                <th className="column-title">Название</th>
                <th className="column-title">Описание</th>
                <th className="column-title">Кол. Условий</th>
                <th className="column-title">Пользователь создал</th>
                <th className="column-title">Timestamps</th>
                <th className="column-title">Actions</th>
              </tr>
              </thead>
              <tbody>
              {this.state.loaded && (
                  this.state.strategies.length > 0 ? (
                      this.state.strategies.map((botData) =>
                          <TemplateEntity
                              key={botData._id.toString()}
                              data={botData}
                              updateRange={this.updateRange}
                              deleteRange={this.deleteRange}
                              copyRange={this.copyRange}
                              accessToUser={this.props.user.hasAccess('moneys.assign_user')}
                          />)
                  ) : (
                      <tr>
                        <td colSpan="6">List is empty</td>
                      </tr>
                  )
              )}
              {!this.state.loaded && (
                  <tr>
                    <td colSpan="6"><i className="text-muted">Loading...</i></td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
          <br/>
          <div className="modal fade bs-example-modal-lg modal-profile modal-money-update" id="modalMoneyUpdate" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="myModalLabel">Update Bet Strategy</h4>
                  <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span>
                  </button>
                </div>

                <form className="form-label-left input_mask" onSubmit={this.sendFormUpdate}>

                  <div className="modal-body">

                    <div className="form-row">
                      <div className="form-group col-md-12 col-sm-12">
                        <label>Название</label>
                        <input
                            value={this.state.name.value}
                            className="form-control"
                            disabled="disabled"
                            type="text"
                        />
                        {this.state.name.hasError && (
                            <small className="text-danger">Invalid Name</small>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12 col-sm-12">
                        <label>Описание</label>
                        <input
                            value={this.state.description}
                            onChange={this.changeDescription}
                            className="form-control"
                            type="text"
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12 col-sm-12">
                        <label>"Ставить" - ставка СТАВИТСЯ при выполнении всей цепочки параметров хотябы в одном условии.</label>
                        <label>"Не ставить" - ставка НЕ СТАВИТСЯ при выполнении хотябы одного параметра в любом из условий.</label>
                        <div className="btn-group btn-group-toggle col-md-12 col-sm-12">
                          <label className={`btn input-pointer btn-secondary${this.state.isPlaceBet === true ? ' active' : ''}`}>
                            <input
                                value="place_bet"
                                // id={`place_bet-${this.props.ind}`}
                                checked={this.state.isPlaceBet === true}
                                // defaultChecked={this.props.data.isPlaceBet === true}
                                onChange={(e) => this.changeIsPlaceBet(e.target.value)}
                                name="isPlaceBet"
                                type="radio"
                                autoComplete="off"
                            />
                            Ставить
                          </label>
                          <label className={`btn input-pointer btn-secondary${this.state.isPlaceBet === false ? ' active' : ''}`}>
                            <input
                                value="no_place_bet"
                                // id={`no_place_bet-${this.props.ind}`}
                                checked={this.state.isPlaceBet === false}
                                // defaultChecked={this.props.data.isPlaceBet === false}
                                onChange={(e) => this.changeIsPlaceBet(e.target.value)}
                                name="isPlaceBet"
                                type="radio"
                                autoComplete="off"
                            />
                            Не ставить
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      {/*<label className="col-form-label col-md-3 col-sm-3 ">Добавить новое условие</label>*/}
                      <div className="col-md-12 col-sm-12 ">
                        <button type="button" onClick={this.addRegularFootball} className="btn btn-info btn-sm mt-1">
                          <i className="fa fa-plus"/> {" "} Добавить условие(футбол)
                        </button>
                        <button type="button" onClick={this.addCyberFootball} className="btn btn-primary btn-sm mt-1">
                          <i className="fa fa-plus"/> {" "} Добавить условие(киберфутбол)
                        </button>
                      </div>
                    </div>

                    <Collapse accordion>
                      {this.state.oddsRanges.map((oddsData, i) =>
                          <Panel extra={this.genExtra(oddsData.isReverse)}
                                 header={`Условие: ${i + 1}${oddsData.isScores ? ` |  Счет: ${oddsData.firstTeamScore}:${oddsData.secondTeamScore}` : ''}${oddsData.isTime ? ` | Время: ${oddsData.time1}-${oddsData.time2}` : ''}${oddsData.isOutcome ? ` | ${oddsData.outcome}${oddsData.intervalOverUnder ? ` - ${oddsData.intervalOverUnder}` : ''}` : ''}`}
                                 key={i}>

                            {oddsData.isRegularFootball ?
                                (<>
                                  <StrategyLineRegularFootball
                                      key={i}
                                      ind={i}
                                      data={oddsData}
                                      hasRemoveBtn={true}
                                      removeMoneyLinePosition={this.removeMoneyLinePosition}
                                      cloneMoneyLinePosition={this.cloneMoneyLinePosition}
                                      isPlaceBet={this.state.isPlaceBet}
                                      changeIsReverse={this.changeIsReverse}
                                      changeIsLeague={this.changeIsLeague}
                                      changeBookmaker={this.changeBookmaker}
                                      changeLeague={this.changeLeague}
                                      changeIsOutcome={this.changeIsOutcome}
                                      changeOutcome={this.changeOutcome}
                                      changeIsTime={this.changeIsTime}
                                      changeTime={this.changeTime}
                                      changeIsMargin={this.changeIsMargin}
                                      changeMinMargin={this.changeMinMargin}
                                      changeMaxMargin={this.changeMaxMargin}
                                      changeIsMoney={this.changeIsMoney}
                                      changeMoneyFirstBet={this.changeMoneyFirstBet}
                                      changeIsOdds={this.changeIsOdds}
                                      changeMinOdds={this.changeMinOdds}
                                      changeMaxOdds={this.changeMaxOdds}
                                      changeIsScores={this.changeIsScores}
                                      // changeIsPlaceBet={this.changeIsPlaceBet}
                                      changeIntervalOverUnder={this.changeIntervalOverUnder}
                                      changeTime1={this.changeTime1}
                                      changeTime2={this.changeTime2}
                                      changeFirstTeamScore={this.changeFirstTeamScore}
                                      changeIsFirstTeamScore={this.changeIsFirstTeamScore}
                                      changeSecondTeamScore={this.changeSecondTeamScore}
                                      changeIsSecondTeamScore={this.changeIsSecondTeamScore}
                                  />
                                </>) :
                                (<>
                                  <StrategyLine
                                      key={i}
                                      ind={i}
                                      data={oddsData}
                                      hasRemoveBtn={true}
                                      removeMoneyLinePosition={this.removeMoneyLinePosition}
                                      cloneMoneyLinePosition={this.cloneMoneyLinePosition}
                                      isPlaceBet={this.state.isPlaceBet}
                                      changeIsReverse={this.changeIsReverse}
                                      changeIsLeague={this.changeIsLeague}
                                      changeBookmaker={this.changeBookmaker}
                                      changeLeague={this.changeLeague}
                                      changeIsOutcome={this.changeIsOutcome}
                                      changeOutcome={this.changeOutcome}
                                      changeIsTime={this.changeIsTime}
                                      changeTime={this.changeTime}
                                      changeIsMargin={this.changeIsMargin}
                                      changeMinMargin={this.changeMinMargin}
                                      changeMaxMargin={this.changeMaxMargin}
                                      changeIsMoney={this.changeIsMoney}
                                      changeMoneyFirstBet={this.changeMoneyFirstBet}
                                      changeIsOdds={this.changeIsOdds}
                                      changeMinOdds={this.changeMinOdds}
                                      changeMaxOdds={this.changeMaxOdds}
                                      changeIsScores={this.changeIsScores}
                                      // changeIsPlaceBet={this.changeIsPlaceBet}
                                      changeIntervalOverUnder={this.changeIntervalOverUnder}
                                      changeTime1={this.changeTime1}
                                      changeTime2={this.changeTime2}
                                      changeFirstTeamScore={this.changeFirstTeamScore}
                                      changeIsFirstTeamScore={this.changeIsFirstTeamScore}
                                      changeSecondTeamScore={this.changeSecondTeamScore}
                                      changeIsSecondTeamScore={this.changeIsSecondTeamScore}
                                  />
                                </>)
                            }
                          </Panel>
                      )
                      }
                    </Collapse>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-success">Save</button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </>
    );
  }
}

export default TemplateList;
